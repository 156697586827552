import * as React from "react";
import {Component} from "react";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList} from "../../Navigator";
import {RouteProp} from "@react-navigation/native";
import {toastRef} from "../../common/views/ToastView";
import {Service} from "../../Service";
import NotFoundScreen from "../NotFoundScreen";
import ErrorScreen from "../ErrorScreen";
import LoadingScreen from "../LoadingScreen";

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>
    route: RouteProp<HomeNavigatorParamList, 'Redirect'>
}

interface State {
    notFound: boolean
    error: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            notFound: false,
            error: false
        };
    }

    componentDidMount(): void {
        if (!this.props.route.params?.paymentId) {
            this.setState({notFound: true})
        } else {
            Service.createOrder(this.props.route.params.paymentId)
                .then(e => {
                    this.props.navigation.replace("Order", {id: e.getId()});
                    Service.initCart(null);
                })
                .catch(e => {
                    if (e.toString() == "ERROR_PAYMENT_DOES_NOT_EXIST") {
                        this.setState({notFound: true})
                    } else {
                        toastRef.current!.show(e.toString());
                        toastRef.current!.show("Oops something went wrong!");
                        this.setState({error: true})
                    }
                });
        }
    }

    render() {
        if (this.state.notFound) {
            return <NotFoundScreen navigation={this.props.navigation}/>
        }
        if (this.state.error) {
            return <ErrorScreen navigation={this.props.navigation}/>
        }
        return <LoadingScreen/>
    }
};
