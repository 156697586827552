import {Text, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {Component, default as React} from "react";
import {Service} from "../../Service";
import {
    DeleteVariationLinkRequest,
    ListVariationLinksRequest,
    ListVariationLinksResponse
} from "@emreat/proto/backend/v1/variation_links_pb";
import {VariationLinkService} from "@emreat/proto/backend/v1/variation_links_pb_service";

interface Props {
    variationId: string
    modifierGroup: ModifierGroup
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteVariationLink(this.props.variationId, this.props.modifierGroup.getId());
            await Service.initMerchant();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteVariationLink = async (variationId: string, modifierGroupId: string) => {
        let listReq = new ListVariationLinksRequest();
        listReq.addModifierGroupIds(modifierGroupId);
        listReq.addVariationIds(variationId);
        listReq.setLimit(1);
        let resp: ListVariationLinksResponse = await GrpcClient.invokeWithAuth(VariationLinkService.ListVariationLinks, listReq);

        let req = new DeleteVariationLinkRequest();
        req.setId(resp.getVariationLinksList()[0].getId());
        await GrpcClient.invokeWithAuth(VariationLinkService.DeleteVariationLink, req);
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}>
                <View style={[
                    containerStyles.paddingRowMedium, !this.props.last ? containerStyles.borderBottom : null,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                        {this.props.modifierGroup.getTitle()}
                    </Text>
                </View>
            </SwipableButton>
        )
    }
};