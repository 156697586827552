import * as React from "react";
import {Platform, SafeAreaView, StyleSheet, View} from "react-native";
import {colorStyles, containerStyles} from "../../Styles";
import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {ScreenSize} from "../../reducers/common";
import {StackNavigationProp} from "@react-navigation/stack";
import Constants from "expo-constants";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
});

interface Props {
    navigation: StackNavigationProp<any>
    screenSize: ScreenSize
    children: any
    disableBanner?: boolean
    light?: boolean
}

export default connect(mapStateToProps)((props: React.PropsWithChildren<Props>) => {
    if (Platform.OS != "web") {
        return (
            <SafeAreaView style={{
                flex: 1,
                backgroundColor: !props.light ? colorStyles.secondaryBackground : colorStyles.primaryBackground,
            }}>
                {props.children}
            </SafeAreaView>
        )
    }

    return (
        <View style={{minHeight: '100%', flex: 1, backgroundColor: colorStyles.secondaryBackground}}>
            <View style={styles.headerContainer}>
                <View style={containerStyles.page}>
                    <HeaderView navigation={props.navigation}/>
                </View>
                {
                    (props.disableBanner && props.screenSize != "LARGE") || !Constants.manifest.extra.showCategory
                        ? null : (
                            <View style={[
                                containerStyles.borderBottom,
                                containerStyles.primaryShadow,
                                {
                                    backgroundColor: colorStyles.primaryBackground,
                                    position: "absolute",
                                    width: "100%",
                                    height: 300,
                                    bottom: -142,
                                    left: 0,
                                    zIndex: -1,
                                    transform: [{skewY: "-6deg"}],
                                },
                            ]}>
                            </View>
                        )
                }
            </View>
            <View style={styles.contentContainer}>
                {props.children}
            </View>
            <View style={[styles.footerContainer, containerStyles.borderTop]}>
                <View style={containerStyles.page}>
                    <FooterView/>
                </View>
            </View>
        </View>
    );
});

const styles = StyleSheet.create({
    headerContainer: {
        alignItems: 'center',
        backgroundColor: colorStyles.primaryBackground
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
    },
    footerContainer: {
        alignItems: 'center',
        backgroundColor: '#e2e6e9',
        zIndex: -1,
    },
});
