import * as React from "react";
import {Component, RefObject} from "react";
import {Platform, StyleSheet, Text, TextInput, View} from "react-native";
import {containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import SubmitButton from "../../common/buttons/SubmitButton";
import {getStore, RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {User, UserUpdateMask, UserUpdateMaskMap} from "@emreat/proto/backend/v1/users_pb";
import HeaderButton from "../../common/buttons/HeaderButton";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import {UpdateUserRequest} from "@emreat/proto/backend/v1/users_pb";
import {GrpcClient} from "../../GrpcClient";
import {UserService} from "@emreat/proto/backend/v1/users_pb_service";
import ScrollView from "../../common/views/ScrollView";
import NameField from "../../common/fields/Name";
import PhoneField from "../../common/fields/Phone";
import FieldErrors from "../../common/fields/Errors";
import SingleLayout from "../../common/layouts/SingleLayout";
import {Divider} from "react-native-paper";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'User'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'User'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }

    return <Screen navigation={props.navigation} user={props.user}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>

    user: User
}

interface State {
    firstName: string
    lastName: string
    phoneNumber: string

    firstNameError: string
    lastNameError: string
    phoneNumberError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    firstNameRef: RefObject<TextInput>;
    lastNameRef: RefObject<TextInput>;
    phoneRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            firstName: props.user.getFirstName(),
            lastName: props.user.getLastName(),
            phoneNumber: props.user.getPhoneNumber(),

            firstNameError: "",
            lastNameError: "",
            phoneNumberError: "",

            submitting: false,
        };
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.phoneRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Edit Account',
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        })
    };

    isValid = (): boolean => {
        if (ValidationsUtils.name(this.state.firstName) != "") {
            return false
        }
        if (ValidationsUtils.name(this.state.lastName) != "") {
            return false
        }
        if (ValidationsUtils.phoneNumber(this.state.phoneNumber) != "") {
            return false
        }
        return this.state.firstName != this.props.user.getFirstName() || this.state.lastName != this.props.user.getLastName() || this.state.phoneNumber != this.props.user.getPhoneNumber();
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.updateUser();
            if (Platform.OS == 'web') {
                this.props.navigation.navigate('Info', {})
            } else {
                this.props.navigation.pop();
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    updateUser = async () => {
        let updateMasks: Array<UserUpdateMaskMap[keyof UserUpdateMaskMap]> = [];
        let user = new User();
        user.setId(this.props.user.getId());
        if (this.state.firstName != this.props.user.getFirstName()) {
            user.setFirstName(this.state.firstName);
            updateMasks.push(UserUpdateMask.USER_UPDATE_MASK_FIRST_NAME)
        }
        if (this.state.lastName != this.props.user.getLastName()) {
            user.setLastName(this.state.lastName);
            updateMasks.push(UserUpdateMask.USER_UPDATE_MASK_LAST_NAME)
        }
        if (this.state.phoneNumber != this.props.user.getPhoneNumber()) {
            user.setPhoneNumber(this.state.phoneNumber);
            updateMasks.push(UserUpdateMask.USER_UPDATE_MASK_PHONE_NUMBER)
        }

        let req = new UpdateUserRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setUser(user);
        req.setUpdateMasksList(updateMasks);
        let resp: User = await GrpcClient.invokeWithAuth(UserService.UpdateUser, req);
        getStore.dispatch({type: "SET_USER", user: resp})
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} title="Edit Account" subTitle="" dark>
                {this.renderForm()}
                {
                    Platform.OS == 'web'
                        ? (
                            <View style={containerStyles.paddingRowMedium}>
                                <SubmitButton
                                    text='Save'
                                    onPress={() => this.onSubmit()}
                                    submitting={this.state.submitting}
                                    disabled={!this.isValid()}
                                    inActive={!this.isValid() || this.state.submitting}/>
                            </View>
                        ) : null
                }
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Edit your contact name and phone number to use with your account.
                </Text>

                <Divider/>

                <NameField
                    forwardRef={this.firstNameRef}
                    placeholder="First name"
                    value={this.state.firstName}
                    label="First name"
                    error={Boolean(this.state.firstNameError)}
                    onChangeText={e => this.setState({firstName: e, firstNameError: ValidationsUtils.name(e)})}
                    onSubmitEditing={() => this.lastNameRef.current!.focus()}/>

                <NameField
                    forwardRef={this.lastNameRef}
                    placeholder="Last name"
                    value={this.state.lastName}
                    label="Last name"
                    error={Boolean(this.state.lastNameError)}
                    onChangeText={e => this.setState({lastName: e, lastNameError: ValidationsUtils.name(e)})}
                    onSubmitEditing={() => this.phoneRef.current!.focus()}/>

                <PhoneField
                    forwardRef={this.phoneRef}
                    value={this.state.phoneNumber}
                    returnKeyType="done"
                    error={Boolean(this.state.phoneNumberError)}
                    onChangeText={e => this.setState({
                        phoneNumber: e,
                        phoneNumberError: ValidationsUtils.phoneNumber(e)
                    })}
                    onSubmitEditing={() => this.phoneRef.current!.blur()}/>

                <FieldErrors errors={[
                    this.state.firstNameError,
                    this.state.lastNameError,
                    this.state.phoneNumberError,
                ]}/>
            </ScrollView>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});