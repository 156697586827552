import {Component, default as React} from "react";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {toastRef} from "../../common/views/ToastView";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Service} from "../../Service";
import {FlatList, Platform, RefreshControl, ScrollView, Text, View} from "react-native";
import AllergyView from "../../common/components/AllergyView";
import MoneyView from "../../common/views/MoneyView";
import MessageView from "../../common/views/MessageView";
import CartItemRow from "./CartItemRow";

interface Props {
    cart: Cart | null
}

interface State {
    refreshing: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
        };
    }

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await Service.getCart(this.props.cart!.getId())
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    render() {
        return (
            <View style={{flex: 1}}>
                <View style={{flex: 1}}>
                    {this.renderCartItems()}
                </View>
                {this.renderFooter()}
            </View>
        )
    };

    renderCartItems = () => {
        if (!this.props.cart || !this.props.cart!.getItemsList().length) {
            return this.renderNoCart()
        } else if (Platform.OS == 'web') {
            return (
                <View style={{flex: 1, overflow: 'scroll'}}>
                    <AllergyView/>
                    {this.props.cart?.getItemsList().map((e, i) => <CartItemRow
                        key={e.getId()}
                        cartItem={e}
                        last={this.props.cart!.getItemsList().length - 1 == i}/>)}
                </View>
            )
        } else {
            return (
                <FlatList
                    showsVerticalScrollIndicator={false}
                    data={this.props.cart!.getItemsList()}
                    style={{backgroundColor: colorStyles.primaryBackground}}
                    ListHeaderComponent={<AllergyView/>}
                    keyExtractor={item => item.getId()}
                    renderItem={item => <CartItemRow
                        cartItem={item.item}
                        last={this.props.cart!.getItemsList().length - 1 == item.index}/>}
                    refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
            )
        }
    };

    renderFooter = () => {
        return (
            <View style={containerStyles.borderTop}>
                {this.renderTotal("Subtotal", this.props.cart?.getSubtotal())}
                {
                    this.props.cart?.getDiscountsList().map((e, i) => (
                        <View key={i}>
                            {this.renderFee(e.getTitle() + " discount", e.getAmount(), true)}
                        </View>))
                }
                {
                    this.props.cart?.getServiceFeesList().map((e, i) => (
                        <View key={i}>
                            {this.renderFee(e.getTitle() + " fee", e.getAmount())}
                        </View>))
                }
                {this.renderFee("Tips", this.props.cart ? this.props.cart.getTips() * this.props.cart.getSubtotal() / 100 : 0)}
                {this.renderTotal("Total", this.props.cart?.getTotal())}
            </View>
        )
    };

    renderFee = (label: string, amount?: number, negative: boolean = false) => {
        if (!amount) {
            return null
        }
        return (
            <View style={[{flexDirection: "row"}, containerStyles.paddingRowSmallTop]}>
                <Text style={[textStyles.primarySubTitle, {flex: 1}]}>{label}:</Text>
                <Text style={textStyles.primarySubTitle}>{negative ? "- " : ""}<MoneyView value={amount}/></Text>
            </View>
        )
    };

    renderTotal = (label: string, amount?: number) => {
        return (
            <View style={[{flexDirection: "row"}, containerStyles.paddingRowSmallTop]}>
                <Text style={[textStyles.secondaryTitle, {flex: 1}]}>{label}:</Text>
                <Text style={textStyles.secondaryTitle}><MoneyView value={amount ? amount : 0}/></Text>
            </View>
        )
    };

    renderNoCart = () => {
        return (
            <ScrollView
                contentContainerStyle={{flex: 1}}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                <AllergyView/>
                    <MessageView title="Your cart is empty!" subTitle="Add an item to continue" icon="md-cart"/>
            </ScrollView>
        )
    }
}