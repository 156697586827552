import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {Platform, View} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {RootState} from "../../reducers/reducers";
import {Location} from "@emreat/proto/backend/v1/locations_pb";
import HeaderButton from "../../common/buttons/HeaderButton";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import MapView from "../../common/views/MapView";
import {containerStyles} from "../../Styles";
import SubmitButton from "../../common/buttons/SubmitButton";
import DoubleLayout from "../../common/layouts/DoubleLayout";
import LocationsView from "../../components/account/LocationsView";
import SingleLayout from "../../common/layouts/SingleLayout";
import {toastRef} from "../../common/views/ToastView";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    locations: state.customer.locations,
    stores: state.merchant.stores,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Locations'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Locations'>

    user: User | null
    locations: Array<Location>
    stores: Array<Store>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }
    return <Screen navigation={props.navigation} locations={props.locations} stores={props.stores}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>
    locations: Array<Location>
    stores: Array<Store>
}

class Screen extends Component<Props> {

    mapRef: RefObject<MapView>;

    constructor(props: Props) {
        super(props);
        this.mapRef = createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Address book',
            headerRight: () => (
                <HeaderButton onPress={() => this.props.navigation.navigate("Location", {})} icon="md-add"/>
            ),
        })
    };

    onPressLocation = async (id: string) => {
        this.mapRef.current!.focusMarker(id);
    };

    render() {
        return (
            <DoubleLayout
                navigation={this.props.navigation}
                title="Address book"
                subTitle="Manage your delivery addresses.">
                <View style={{flex: 5}}>
                    {this.renderMap()}
                </View>
                <View style={[{flex: 4}, containerStyles.paddingContainer]}>
                    <LocationsView
                        toastRef={toastRef}
                        onPressLocation={this.onPressLocation}
                        onPressEditLocation={id => this.props.navigation.navigate('Location', {id})}/>
                    {
                        Platform.OS == 'web'
                            ? (
                                <View style={containerStyles.paddingRowMedium}>
                                    <SubmitButton
                                        text='New Address'
                                        onPress={() => this.props.navigation.navigate("Location", {})}
                                        submitting={false}
                                        disabled={false}
                                        inActive={false}/>
                                </View>
                            ) : null
                    }
                </View>
            </DoubleLayout>
        )
    };

    renderMap = () => {
        return <MapView
            ref={this.mapRef}
            markers={[
                ...this.props.stores.map(e => {
                    return {
                        customer: false,
                        id: e.getId(),
                        latitude: e.getLocationLatitude(),
                        longitude: e.getLocationLongitude(),
                        title: e.getLocationLineOne(),
                        description: [e.getLocationLineTwo(), e.getLocationCity(), e.getLocationPostcode()].join(", "),
                    };
                }),
                ...this.props.locations.map(e => {
                    return {
                        customer: true,
                        id: e.getId(),
                        latitude: e.getLatitude(),
                        longitude: e.getLongitude(),
                        title: e.getLineOne(),
                        description: e.getLineTwo() ? [e.getLineTwo(), e.getCity(), e.getPostcode()].join(", ") : [e.getCity(), e.getPostcode()].join(", "),
                    };
                }),
            ]}/>
    };
}