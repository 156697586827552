import * as React from "react";
import {Component} from "react";
import {Platform, RefreshControl, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList} from "../../Navigator";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {DayOfTheWeek, DayOfTheWeekMap} from "@emreat/proto/backend/v1/hours_pb";
import {Service} from "../../Service";
import * as Linking from "expo-linking";
import {Ionicons} from "@expo/vector-icons";
import moment from "moment";
import {dayOfTheWeekProtoToString, dayOfTheWeekToProto} from "../../Constants";
import {toastRef} from "../../common/views/ToastView";
import MapView from "../../common/views/MapView";
import ScrollView from "../../common/views/ScrollView";
import DoubleLayout from "../../common/layouts/DoubleLayout";
import {ScreenSize} from "../../reducers/common";


let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    stores: state.merchant.stores,
});

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList, 'Information'>
    route: RouteProp<HomeNavigatorParamList, 'Information'>

    screenSize: ScreenSize
    stores: Array<Store>
}

interface State {
    refreshing: boolean
}

export default connect(mapStateToProps)(class InformationScreen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
        };
    }

    refresh = async () => {
        this.setState({refreshing: true});
        try {
            await Service.initMerchant()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Information',
        })
    };

    storeOpenNow = (dayOfTheWeek: DayOfTheWeekMap[keyof DayOfTheWeekMap]): boolean | null => {
        if (dayOfTheWeek != dayOfTheWeekToProto[new Date().getDay()]) {
            return null
        }
        return this.props.stores[0].getHoursList()
            .filter(e => e.getDaysOfTheWeekList().includes(dayOfTheWeek))
            .map(e => {
                let openTime = moment(`${e.getFromHour()}:${e.getFromMinute()}`, "HH:mm");
                let closeTime = moment(`${e.getToHour()}:${e.getToMinute()}`, "HH:mm");
                if (e.getFromHour() > e.getToHour()) {
                    closeTime.add(1, "day")
                }
                return moment().isBetween(openTime, closeTime)
            }).some(e => e);
    };

    render() {
        return (
            <DoubleLayout
                navigation={this.props.navigation}
                title="Information"
                subTitle="">
                {
                    Platform.OS == "web" && this.props.screenSize != "SMALL"
                        ? (
                            <View style={{flex: 5}}>
                                {this.renderMap()}
                            </View>
                        ) : null
                }
                <View style={[{flex: 4}, containerStyles.paddingContainer]}>
                    <ScrollView
                        refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                        {this.renderContactDetails()}
                        {this.renderLocation()}
                        {this.renderHours()}
                    </ScrollView>
                </View>
            </DoubleLayout>
        );
    };

    renderMap = () => {
        return (
            <View style={[containerStyles.border, {flex: 1}]}>
                <MapView markers={this.props.stores.map(e => {
                    return {
                        customer: false,
                        id: e.getId(),
                        latitude: e.getLocationLatitude(),
                        longitude: e.getLocationLongitude(),
                        title: e.getLocationLineOne(),
                        description: e.getLocationLineTwo()
                            ? [
                                e.getLocationLineTwo(),
                                e.getLocationCity(),
                                e.getLocationPostcode(),
                            ].join(", ")
                            : [
                                e.getLocationCity(),
                                e.getLocationPostcode(),
                            ].join(", "),
                    };
                })}/>
            </View>
        )
    };

    renderLocation = () => {
        return (
            <View style={[containerStyles.paddingRowMedium, containerStyles.borderBottom]}>
                <Text style={[textStyles.primaryTitle, {marginBottom: 15}]}>Where to find us</Text>
                {
                    Platform.OS != 'web' || this.props.screenSize == "SMALL"
                        ? <View style={{height: 300, marginBottom: 15}}>{this.renderMap()}</View>
                        : null
                }
                <Text style={textStyles.primarySubTitle}>{this.props.stores[0].getLocationLineOne()}</Text>
                {
                    this.props.stores[0].getLocationLineTwo()
                        ? <Text style={textStyles.secondarySubTitle}>{this.props.stores[0].getLocationLineTwo()}</Text>
                        : null
                }
                <Text style={textStyles.secondarySubTitle}>{this.props.stores[0].getLocationCity()}</Text>
                <Text style={textStyles.secondarySubTitle}>{this.props.stores[0].getLocationPostcode()}</Text>
            </View>
        )
    };

    renderContactDetails = () => {
        return (
            <TouchableOpacity
                style={[containerStyles.borderBottom, containerStyles.paddingRowMedium]}
                onPress={() => Linking.openURL(`tel:${this.props.stores[0].getPhoneNumber()}`)}>
                <View style={[styles.contactContainer, containerStyles.border]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons name="md-call" size={28} style={{color: colorStyles.BLUE, marginRight: 10}}/>
                        <Text style={[textStyles.secondaryHeadline, {color: colorStyles.BLUE, paddingBottom: 3}]}>
                            Need help?
                        </Text>
                    </View>
                    <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                        Call us on {this.props.stores[0].getPhoneNumber()}
                    </Text>
                </View>
            </TouchableOpacity>
        )
    };

    renderHours = () => {
        return (
            <View style={[containerStyles.paddingRowMedium]}>
                <Text style={[textStyles.primaryTitle, {marginBottom: 5}]}>Opening times</Text>
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_SUNDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_MONDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_TUESDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_WEDNESDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_THURSDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_FRIDAY)}
                {this.renderDay(DayOfTheWeek.DAY_OF_WEEK_SATURDAY)}
            </View>
        )
    };

    renderDay = (dayOfTheWeek: DayOfTheWeekMap[keyof DayOfTheWeekMap]) => {
        return (
            <View style={[
                containerStyles.paddingRowSmall, containerStyles.borderBottom,
                {paddingLeft: 0, paddingRight: 0, flexDirection: 'row', alignItems: 'center'},
            ]}>
                <Text style={[
                    textStyles.primarySubTitle, {flex: 1},
                    this.storeOpenNow(dayOfTheWeek) != null ? this.storeOpenNow(dayOfTheWeek) ? styles.openText : styles.closedText : null,
                ]}>
                    {dayOfTheWeekProtoToString[dayOfTheWeek]}
                </Text>
                <View>
                    {this.renderHoursForDay(dayOfTheWeek)}
                </View>
            </View>
        )
    };

    renderHoursForDay = (dayOfTheWeek: DayOfTheWeekMap[keyof DayOfTheWeekMap]) => {
        let hours = this.props.stores[0].getHoursList().filter(e => e.getDaysOfTheWeekList().includes(dayOfTheWeek));
        let today = dayOfTheWeek == dayOfTheWeekToProto[new Date().getDay()];

        if (!hours.length) {
            return <Text style={[textStyles.primarySubTitle, today ? styles.closedText : {}]}>Closed</Text>
        } else {
            return hours.map(h => {
                let openTime = moment(`${h.getFromHour()}:${h.getFromMinute()}`, "HH:mm");
                let closeTime = moment(`${h.getToHour()}:${h.getToMinute()}`, "HH:mm");
                if (h.getFromHour() > h.getToHour()) {
                    closeTime.add(1, "day")
                }

                let style = {};
                if (moment().isBetween(openTime, closeTime) && dayOfTheWeek == dayOfTheWeekToProto[new Date().getDay()]) {
                    style = styles.openText
                } else if (dayOfTheWeek == dayOfTheWeekToProto[new Date().getDay()]) {
                    style = styles.closedText
                }
                return (
                    <View style={{flexDirection: "row", alignItems: "center"}} key={h.getId()}>
                        <Text style={[textStyles.primarySubTitle, style]}>
                            {openTime.format("HH:mm")} - {closeTime.format("HH:mm")}
                        </Text>
                    </View>
                )
            })
        }
    };
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorStyles.secondaryBackground,
    },
    screen: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        maxWidth: 1000,
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        flexWrap: 'wrap',
        backgroundColor: colorStyles.secondaryBackground,
    },
    contactContainer: {
        backgroundColor: colorStyles.LIGHT_BLUE,
        marginBottom: 15,
        marginTop: 15,
        padding: 15,
        alignItems: 'center',
        textAlign: 'center',
    },
    openText: {
        color: colorStyles.GREEN,
        fontWeight: '600',
    },
    closedText: {
        color: colorStyles.RED,
        fontWeight: '600',
    }
});
