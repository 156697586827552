import React, {Component} from "react";
import {Text, View} from "react-native";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {CartItem, CartModifier} from "@emreat/proto/backend/v1/cart_items_pb";
import {containerStyles, textStyles} from "../../Styles";
import MoneyView from "../../common/views/MoneyView";

interface props {
    cart: Cart
}

export default class CartSummaryComponent extends Component<props> {
    render() {
        return (
            <View style={{paddingLeft: 10, paddingRight: 10}}>
                {this.props.cart.getItemsList().map(e => <View key={e.getId()}>{this.renderCartItem(e)}</View>)}
                {this.renderTotal("Subtotal", this.props.cart.getSubtotal())}
                {
                    this.props.cart.getDiscountsList().map((e, i) => (
                        <View key={i}>
                            {this.renderFee(e.getTitle() + " discount", e.getAmount(), true)}
                        </View>))
                }
                {
                    this.props.cart.getServiceFeesList().map((e, i) => (
                        <View key={i}>
                            {this.renderFee(e.getTitle() + " fee", e.getAmount())}
                        </View>))
                }
                {this.renderFee("Tips", this.props.cart.getTips() * this.props.cart.getSubtotal() / 100)}
                {this.renderTotal("Total", this.props.cart.getTotal())}
            </View>
        )
    };

    renderFee = (label: string, amount: number, negative: boolean = false) => {
        if (!amount) {
            return null
        }
        return (
            <View style={{flexDirection: "row", paddingTop: 10}}>
                <Text style={[textStyles.secondarySubTitle, {flex: 1}]}>{label}:</Text>
                <Text style={textStyles.secondarySubTitle}>{negative ? "- " : ""}
                    <MoneyView value={amount}/>
                </Text>
            </View>
        )
    };

    renderTotal = (label: string, amount: number) => {
        if (!amount) {
            return null
        }
        return (
            <View style={{flexDirection: "row", paddingTop: 10}}>
                <Text style={[textStyles.primarySubTitle, {flex: 1}]}>{label}:</Text>
                <Text style={textStyles.primarySubTitle}>
                    <MoneyView value={amount}/>
                </Text>
            </View>
        )
    };

    renderCartItem = (e: CartItem) => {
        return (
            <View style={[{paddingTop: 10, paddingBottom: 10}, containerStyles.borderBottom]}>
                <View style={{flexDirection: "row"}}>
                    <View style={{flex: 1, flexDirection: "row"}}>
                        <Text style={textStyles.primarySubTitle}>
                            {e.getQuantity() > 1 ? e.getQuantity() + "x " : ""}
                            <Text style={{textTransform: 'capitalize'}}>
                                {e.getProductTitle()}
                                {e.getVariationTitle() != "ONE_SIZE" ? ', ' + e.getVariationTitle() : ''}
                            </Text>
                        </Text>
                    </View>
                    <Text style={textStyles.primarySubTitle}><MoneyView value={e.getUnitPrice()}/></Text>
                </View>
                {e.getCartModifiersList().map(this.renderCartModifier)}
            </View>
        )
    };

    renderCartModifier = (e: CartModifier) => {
        return (
            <View style={{flexDirection: "row", paddingTop: 5, paddingLeft: 10}} key={e.getModifierId()}>
                <View style={{flex: 1}}>
                    <Text style={[textStyles.secondarySubTitle]}>
                        {'+ '}
                        {e.getQuantity() > 1 ? `+ ${e.getQuantity()}x ` : ''}
                        <Text style={{textTransform: 'capitalize'}}>{e.getModifierTitle()}</Text>
                    </Text>
                </View>
                {e.getTotalPrice() != 0 ? <Text><MoneyView value={e.getTotalPrice()}/></Text> : null}
            </View>
        )
    }
}