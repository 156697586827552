import {Dimensions, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import React, {Component} from "react";
import Modal from "react-native-modal";
import {HeaderHeightContext} from "@react-navigation/stack";
import {colorStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";

interface Props {
}

interface State {
    open: boolean
    render: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            render: false,
        };
    }

    open = () => {
        this.setState({open: true, render: true})
    };

    close = () => {
        this.setState({open: false})
    };

    isOpen = (): boolean => {
        return this.state.open
    };

    render() {
        if (!this.state.render) {
            return null
        }
        return (
            <Modal
                isVisible={this.state.open}
                propagateSwipe={true}
                animationInTiming={600}
                animationOutTiming={600}
                onModalHide={() => this.setState({render: false})}
                onBackdropPress={this.close}
                onSwipeComplete={this.close}
                swipeDirection={["down"]}
                style={styles.modal}>
                <TouchableOpacity onPress={this.close}>
                    <Ionicons name="md-close" size={34} style={styles.closeButton}/>
                </TouchableOpacity>
                <HeaderHeightContext.Consumer>
                    {(headerHeight: any) => {
                        return (
                            <View style={{
                                backgroundColor: colorStyles.WHITE,
                                maxHeight: Platform.OS == 'web' ? "90%" : Dimensions.get("screen").height - 2*headerHeight,
                            }}>
                                {this.props.children}
                            </View>
                        )
                    }}
                </HeaderHeightContext.Consumer>
            </Modal>
        )
    };
};

const styles = StyleSheet.create({
    modal: {
        flex: 1,
        justifyContent: 'flex-end',
        margin: 0,
    },
    closeButton: {
        zIndex: 1,
        position: "relative",
        top: 0,
        right: 20,
        alignSelf: 'flex-end',
        color: colorStyles.WHITE,
    }
});