import {default as React} from "react";
import {Text, View} from "react-native";
import {connect} from "react-redux";
import {getStore, RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import EditableCart from "./EditableCart";
import SubmitButton from "../../common/buttons/SubmitButton";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    cart: state.customer.cart,
});

interface Props {
    screenSize: ScreenSize
    cart: Cart | null

    button: string
    onPress: () => void
}

export default connect(mapStateToProps)((props: Props) => {
    if (props.screenSize == 'SMALL') {
        return null
    }

    return (
        <View style={{flex: 5, marginLeft: 25}}>
            {/*@ts-ignore*/}
            <View style={{top: 15, maxHeight: 'calc(100vh - 30px)', minHeight: 200, position: 'sticky'}}>
                <View style={[
                    {flex: 1, backgroundColor: colorStyles.primaryBackground},
                    containerStyles.paddingContainer, containerStyles.border,
                ]}>
                    <View style={[containerStyles.borderBottom, containerStyles.paddingRowMedium]}>
                        <Text style={textStyles.primaryTitle}>Your order</Text>
                    </View>
                    <EditableCart cart={props.cart}/>
                    {
                        props.button
                            ? (
                                <View style={containerStyles.paddingRowMedium}>
                                    <SubmitButton
                                        text={props.button}
                                        onPress={props.onPress}
                                        submitting={false}
                                        disabled={!props.cart || !props.cart!.getItemsList().length || !getStore.getState().merchant.stores[0].getActive()}
                                        inActive={!props.cart || !props.cart!.getItemsList().length || !getStore.getState().merchant.stores[0].getActive()}/>
                                </View>
                            ) : <View style={containerStyles.paddingRowMediumBottom}/>
                    }
                </View>
            </View>
        </View>
    )
})