import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {Variation} from "@emreat/proto/backend/v1/variations_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {getStore} from "../../reducers/reducers";
import {DeleteVariationRequest} from "@emreat/proto/backend/v1/variations_pb";
import MoneyView from "../../common/views/MoneyView";
import {VariationService} from "@emreat/proto/backend/v1/variations_pb_service";

interface Props {
    variation: Variation
    onPressEdit: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteVariation(this.props.variation.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteVariation = async (id: string) => {
        let req = new DeleteVariationRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(VariationService.DeleteVariation, req);
        let product = getStore.getState().merchant.products
            .filter(e => e.getId() == this.props.variation.getProductId())[0];
        product.setVariationsList(product.getVariationsList().filter(e => e.getId() != this.props.variation.getId()));
        getStore.dispatch({type: "SET_PRODUCTS", products: [product]});
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}
                onPressUpdate={this.props.onPressEdit}>
                <View style={{backgroundColor: colorStyles.primaryBackground}}>
                    {this.renderButton()}
                </View>
            </SwipableButton>
        )
    }

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPressEdit()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.variation.getTitle()}
                </Text>
            </View>
        )
    };

    renderRightColumn = () => {
        return (
            <Text style={[textStyles.secondarySubTitle, {color: colorStyles.RED}]}>
                <MoneyView value={this.props.variation.getBasePrice()}/>
            </Text>
        )
    };
};