import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {Dimensions} from "react-native";

export type ScreenSize = 'LARGE' | 'MEDIUM' | 'SMALL';

export function getScreenSize(): ScreenSize {
    if (Dimensions.get('window').width >= 1150) {
        return 'LARGE'
    }
    if (Dimensions.get('window').width >= 768) {
        return 'MEDIUM'
    }
    return 'SMALL'
}

interface Resource {
    getId(): string;
    getCreateTimestamp(): Timestamp | undefined;
    hasCreateTimestamp(): boolean;
}

export function mergeArrays<T extends Resource>(arrayOne: Array<T>, ArrayTwo: Array<T> = new Array<T>(), reverse?: boolean): Array<T> {
    return Object.values([...arrayOne].concat(ArrayTwo)
        .reduce((r: { [key: string]: T }, o) => {
            r[o.getId()] = o;
            return r;
        }, {})).sort((a, b) => {
        if (a.hasCreateTimestamp() && b.hasCreateTimestamp()) {
            if (reverse) {
                return a.getCreateTimestamp()!.toDate().getTime() - b.getCreateTimestamp()!.toDate().getTime()
            } else {
                return b.getCreateTimestamp()!.toDate().getTime() - a.getCreateTimestamp()!.toDate().getTime()
            }
        }
        return 0
    });
}

export function removeFromArray<T extends Resource>(arrayOne: Array<T>, ArrayTwo: Array<T>): Array<T> {
    return arrayOne.filter(e => !ArrayTwo.map(e => e.getId()).includes(e.getId()));
}