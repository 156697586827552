import {ActivityIndicator, Text, TouchableOpacity, TouchableOpacityProps} from "react-native";
import React, {PropsWithChildren} from "react";
import {colorStyles, textStyles} from "../../Styles";

interface Props extends TouchableOpacityProps {
    text: string
    submitting?: boolean
    inActive?: boolean
}

export default (props: PropsWithChildren<Props>) => {
    return (
        <TouchableOpacity
            {...props}
            style={[
                {borderRadius: 2, minHeight: 45, padding: 10, justifyContent: 'center'},
                {backgroundColor: props.inActive ? colorStyles.ALPHA_RED : colorStyles.RED},
            ]}>
            {
                props.submitting
                    ? <ActivityIndicator color={colorStyles.WHITE} size={22}/>
                    : (
                        <Text style={[textStyles.secondaryHeadline, {color: colorStyles.WHITE, textAlign: 'center'}]}>
                            {props.text}
                        </Text>
                    )

            }
        </TouchableOpacity>
    )
};