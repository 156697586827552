import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {getStore, RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {
    CreateHourRequest,
    DayOfTheWeekMap,
    Hour,
    HourUpdateMask,
    HourUpdateMaskMap,
    UpdateHourRequest
} from "@emreat/proto/backend/v1/hours_pb";
import Constants from "expo-constants";
import {HourService} from "@emreat/proto/backend/v1/hours_pb_service";
import {Service} from "../../Service";
import Picker from "react-native-picker-select";
import SelectorField from "../../common/fields/SelectorField";
import {dayOfTheWeekProtoToString} from "../../Constants";
import {FontAwesome} from "@expo/vector-icons";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    hours: state.merchant.stores[0].getHoursList()
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'Hour'>
    route: RouteProp<DashboardStackParamList, 'Hour'>

    hours: Array<Hour>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let hour = props.hours.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !hour) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} hour={hour}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    hour: Hour | null
}

interface State {
    daysOfTheWeek: Array<DayOfTheWeekMap[keyof DayOfTheWeekMap]>
    from: string
    to: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    toRef: RefObject<Picker>;
    fromRef: RefObject<Picker>;

    constructor(props: Props) {
        super(props);
        this.state = {
            daysOfTheWeek: this.props.hour ? [...this.props.hour.getDaysOfTheWeekList()] : [],
            from: this.props.hour ? `${this.props.hour.getFromHour().toString().padStart(2, '0')}:${this.props.hour.getFromMinute().toString().padStart(2, '0')}` : "00:00",
            to: this.props.hour ? `${this.props.hour.getToHour().toString().padStart(2, '0')}:${this.props.hour.getToMinute().toString().padStart(2, '0')}` : "00:00",

            submitting: false,
        };
        this.toRef = createRef();
        this.fromRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.hour) {
            this.props.navigation.setOptions({
                title: 'Edit Hour',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Hour',
            })
        }
    };

    isValid = (): boolean => {
        if (this.state.daysOfTheWeek.length == 0) {
            return false
        }
        if (this.props.hour) {
            return JSON.stringify(this.state.daysOfTheWeek) != JSON.stringify(this.props.hour!.getDaysOfTheWeekList()) ||
                this.state.from != `${this.props.hour.getFromHour().toString().padStart(2, '0')}:${this.props.hour.getFromMinute().toString().padStart(2, '0')}` ||
                this.state.to != `${this.props.hour.getToHour().toString().padStart(2, '0')}:${this.props.hour.getToMinute().toString().padStart(2, '0')}`;
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.hour) {
                await this.updateHour()
            } else {
                await this.createHour()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createHour = async () => {
        let hour = new Hour();
        hour.setDaysOfTheWeekList(this.state.daysOfTheWeek);
        hour.setToHour(parseInt(this.state.to.split(":")[0]));
        hour.setToMinute(parseInt(this.state.to.split(":")[1]));
        hour.setFromHour(parseInt(this.state.from.split(":")[0]));
        hour.setFromMinute(parseInt(this.state.from.split(":")[1]));
        hour.setMerchantId(Constants.manifest.extra.merchantId);
        hour.setStoreId(getStore.getState().merchant.stores[0]!.getId());

        let req = new CreateHourRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setHour(hour);
        await GrpcClient.invokeWithAuth(HourService.CreateHour, req);
    };

    updateHour = async () => {
        let updateMasks: Array<HourUpdateMaskMap[keyof HourUpdateMaskMap]> = [];
        let hour = new Hour();
        hour.setId(this.props.hour!.getId());

        if (JSON.stringify(this.state.daysOfTheWeek)!= JSON.stringify(this.props.hour!.getDaysOfTheWeekList())) {
            hour.setDaysOfTheWeekList(this.state.daysOfTheWeek);
            updateMasks.push(HourUpdateMask.HOUR_UPDATE_MASK_DAYS_OF_THE_WEEK)
        }
        if (this.state.to != `${this.props.hour!.getToHour().toString().padStart(2, '0')}:${this.props.hour!.getToMinute().toString().padStart(2, '0')}`) {
            hour.setToHour(parseInt(this.state.to.split(":")[0]));
            hour.setToMinute(parseInt(this.state.to.split(":")[1]));
            updateMasks.push(HourUpdateMask.HOUR_UPDATE_MASK_TO_HOUR)
            updateMasks.push(HourUpdateMask.HOUR_UPDATE_MASK_TO_MINUTE)
        }
        if (this.state.from != `${this.props.hour!.getFromHour().toString().padStart(2, '0')}:${this.props.hour!.getFromMinute().toString().padStart(2, '0')}`) {
            hour.setFromHour(parseInt(this.state.from.split(":")[0]));
            hour.setFromMinute(parseInt(this.state.from.split(":")[1]));
            updateMasks.push(HourUpdateMask.HOUR_UPDATE_MASK_FROM_HOUR)
            updateMasks.push(HourUpdateMask.HOUR_UPDATE_MASK_FROM_MINUTE)
        }

        let req = new UpdateHourRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setHour(hour);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(HourService.UpdateHour, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        let times = []
        for (var i = 0; i < 24; i++) {
            for (var j = 0; j < 60; j += 45) {
                times.push(`${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`)
            }
        }
        times.push("24:00")
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the hour information.
                </Text>

                <DividerView/>

                <SelectorField
                    forwardRef={this.fromRef}
                    value={this.state.from}
                    label="From"
                    doneText={"Next"}
                    items={times.map(e => {
                        return {value: e, label: e}
                    })}
                    error={false}
                    onChange={e => this.setState({from: e})}
                    onUp={() => this.fromRef.current!.togglePicker()}
                    onDown={() => this.toRef.current!.togglePicker()}/>

                <SelectorField
                    forwardRef={this.toRef}
                    value={this.state.to}
                    label="To"
                    doneText={"Done"}
                    items={times.map(e => {
                        return {value: e, label: e}
                    })}
                    error={false}
                    onChange={e => this.setState({to: e})}
                    onUp={() => this.fromRef.current!.togglePicker()}
                    onDown={() => null}/>

                <DividerView/>
                {
                    ([1, 2, 3, 4, 5, 6, 7] as Array<DayOfTheWeekMap[keyof DayOfTheWeekMap]>).map((e, i) => {
                        return (
                            <TouchableOpacity onPress={() => {
                                let daysOfTheWeek = this.state.daysOfTheWeek
                                if (daysOfTheWeek.includes(e)) {
                                    daysOfTheWeek.splice(daysOfTheWeek.indexOf(e), 1)
                                } else {
                                    daysOfTheWeek.push(e)
                                }
                                this.setState({
                                    daysOfTheWeek: daysOfTheWeek
                                })
                            }}>
                                <View style={[
                                    containerStyles.paddingRowMedium, i != 6 ? containerStyles.borderBottom : null,
                                    {
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        backgroundColor: colorStyles.primaryBackground
                                    },
                                ]}>
                                    <View style={{flex: 1}}>
                                        <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                                            {dayOfTheWeekProtoToString[e]}
                                        </Text>
                                    </View>
                                    {
                                        this.state.daysOfTheWeek.includes(e) ?
                                            <FontAwesome name="circle" size={12}
                                                         style={{color: colorStyles.GREEN, marginRight: 10}}/>
                                            : null
                                    }
                                </View>
                            </TouchableOpacity>
                        )
                    })
                }

            </ScrollView>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: "center",
    },
});