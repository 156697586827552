import {CartItem} from "@emreat/proto/backend/v1/cart_items_pb";
import {Component, default as React, RefObject} from "react";
import {DeleteCartItemRequest} from "@emreat/proto/backend/v1/cart_items_pb";
import {GrpcClient} from "../../GrpcClient";
import {CartItemService} from "@emreat/proto/backend/v1/cart_items_pb_service";
import {ActivityIndicator, Platform, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import {Service} from "../../Service";
import CenterModal from "../../common/modals/CenterModal";
import {toastRef} from "../../common/views/ToastView";
import MoneyView from "../../common/views/MoneyView";
import SwipableButton from "../../common/buttons/SwipableButton";
import BottomModal from "../../common/modals/BottomModal";
import CartItemModal from "../cart_item/CartItemModal";

let mapStateToProps = (state: RootState) => ({
    products: state.merchant.products,
});

interface Props {
    products: Array<Product>
    cartItem: CartItem
    last?: boolean
}

interface State {
    deleting: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    modalRef: RefObject<CenterModal | BottomModal>;

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false,
        };
        this.modalRef = React.createRef();
    }

    onDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.delete()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    delete = async () => {
        let req = new DeleteCartItemRequest();
        req.setId(this.props.cartItem.getId());
        await GrpcClient.invokeWithAuth(CartItemService.DeleteCartItem, req);
        await Service.getCart(this.props.cartItem.getCartId());
    };

    render() {
        if (Platform.OS == 'web') {
            return (
                <View style={!this.props.last ? containerStyles.borderBottom : null}>
                    <TouchableOpacity
                        onPress={() => this.modalRef.current!.open()}
                        style={{backgroundColor: colorStyles.primaryBackground}}>
                        {this.renderDetails()}
                    </TouchableOpacity>
                </View>
            )
        } else {
            return (
                <View style={!this.props.last ? containerStyles.borderBottom : null}>
                    <SwipableButton
                        deleting={this.state.deleting}
                        onPressDelete={this.onDelete}
                        onPressUpdate={() => this.modalRef.current!.open()}>
                        <View style={{backgroundColor: colorStyles.primaryBackground}}>
                            <TouchableOpacity onPress={() => this.modalRef.current!.open()}>
                                {this.renderDetails()}
                            </TouchableOpacity>
                        </View>
                    </SwipableButton>
                </View>
            )
        }
    };

    renderDetails = () => {
        return (
            <View style={[
                containerStyles.paddingRowMedium,
                {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
            ]}>
                {this.renderLeftColumn()}
                {this.renderRightColumn()}
                <CartItemModal
                    forwardRef={this.modalRef}
                    cartItem={this.props.cartItem}
                    product={this.props.products.filter(e => e.getId() == this.props.cartItem.getProductId())[0]}
                    productGroupId=''/>
            </View>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                {this.renderTitle()}
                {this.renderSubTitle()}
            </View>
        )
    };

    renderTitle = () => {
        return (
            <Text style={textStyles.secondaryTitle}>
                {this.props.cartItem.getQuantity() > 1 ? this.props.cartItem.getQuantity() + "x " : ""}
                <Text style={{textTransform: 'capitalize'}}>
                    {this.props.cartItem.getProductTitle()}
                    {this.props.cartItem.getVariationTitle() != "ONE_SIZE" ? ', ' + this.props.cartItem.getVariationTitle() : ''}
                </Text>
            </Text>
        )
    };

    renderSubTitle = () => {
        return this.props.cartItem.getCartModifiersList().map(e => (
            <Text key={e.getModifierId()} style={[textStyles.secondarySubTitle, {marginLeft: 10, marginTop: 5}]}>
                {'+ '}
                {e.getQuantity() > 1 ? `${e.getQuantity()}x ` : ''}
                <Text style={{textTransform: 'capitalize'}}>{e.getModifierTitle()}</Text>
                {e.getTotalPrice() != 0 ? <Text>{', '} <MoneyView value={e.getTotalPrice()}/></Text> : ''}
            </Text>
        ))
    };

    renderRightColumn = () => {
        return (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {this.renderTotal()}
                {this.renderAction()}
            </View>
        )
    };

    renderTotal = () => {
        return (
            <Text style={[textStyles.secondarySubTitle, {color: colorStyles.RED}]}>
                <MoneyView value={this.props.cartItem.getTotalPrice()}/>
            </Text>
        )
    };

    renderAction = () => {
        if (Platform.OS != 'web') {
            return null
        } else if (this.state.deleting) {
            return (
                <View style={{padding: 10, paddingRight: 0}}>
                    <ActivityIndicator color={colorStyles.RED} size="small"/>
                </View>
            )
        } else {
            return (
                <TouchableOpacity onPress={this.onDelete}>
                    <Ionicons
                        name="md-trash"
                        size={18}
                        color={colorStyles.RED}
                        style={{padding: 10, paddingRight: 0}}/>
                </TouchableOpacity>
            )
        }
    };
})

