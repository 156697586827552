import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {
    ActivityIndicator, FlatList,
    Keyboard, RefreshControl,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {getStore, RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {ValidationsUtils} from "../../common/fields/Validations";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {CreateProductRequest, UpdateProductRequest} from "@emreat/proto/backend/v1/products_pb";
import {Product, ProductUpdateMask, ProductUpdateMaskMap} from "@emreat/proto/backend/v1/products_pb";
import Constants from "expo-constants";
import NameField from "../../common/fields/Name";
import TextField from "../../common/fields/TextField";
import {ProductService} from "@emreat/proto/backend/v1/products_pb_service";
import {Service} from "../../Service";
import VariationRow from "../../components/dashboard/VariationRow";
import {Ionicons} from "@expo/vector-icons";
import Picker from "react-native-picker-select";
import SelectorField from "../../common/fields/SelectorField";
import {ProductLink} from "@emreat/proto/backend/v1/product_links_pb";
import {CreateProductLinkRequest} from "@emreat/proto/backend/v1/product_links_pb";
import {ProductLinkService} from "@emreat/proto/backend/v1/product_links_pb_service";
import ProductLinkRow from "../../components/dashboard/ProductLinkRow";
import {Image} from "@emreat/proto/backend/v1/images_pb";
import BottomModal from "../../common/modals/BottomModal";
import CartItemForm from "../../components/cart_item/CartItemForm";
import CenterModal from "../../common/modals/CenterModal";
import {ImageView} from "../../common/views/ImageView";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    products: state.merchant.products,
    images: state.dashboard.images,
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'Product'>
    route: RouteProp<DashboardStackParamList, 'Product'>

    products: Array<Product>
    images: Array<Image>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let product = props.products.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !product) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} product={product} images={props.images}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    product: Product | null
    images: Array<Image>
}

interface State {
    image: null | Image
    title: string
    subTitle: string

    titleError: string
    subTitleError: string

    submitting: boolean
    selectedProductGroupId: string
    addingProductGroup: boolean
}

class Screen extends Component<Props, State> {

    titleRef: RefObject<TextInput>;
    subTitleRef: RefObject<TextInput>;
    productGroupRef: RefObject<Picker>;
    modalRef: RefObject<CenterModal | BottomModal>;

    constructor(props: Props) {
        super(props);
        this.state = {
            image: null,

            title: this.props.product ? this.props.product.getTitle() : "",
            subTitle: this.props.product ? this.props.product.getSubTitle() : "",

            titleError: '',
            subTitleError: '',

            submitting: false,
            selectedProductGroupId: "",
            addingProductGroup: false,
        };
        this.titleRef = createRef();
        this.subTitleRef = createRef();
        this.productGroupRef = React.createRef();
        this.modalRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.product) {
            this.props.navigation.setOptions({
                title: 'Edit Product',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Product',
            })
        }
    };

    onAddProductGroup = async () => {
        this.setState({addingProductGroup: true});
        try {
            await this.createProductLink(this.props.product!.getId(), this.state.selectedProductGroupId)
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({addingProductGroup: false});
    };

    createProductLink = async (productId: string, productGroupId: string): Promise<ProductLink> => {
        let productLink = new ProductLink();
        productLink.setProductId(productId);
        productLink.setProductGroupId(productGroupId);
        productLink.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateProductLinkRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProductLink(productLink);
        let resp: ProductLink = await GrpcClient.invokeWithAuth(ProductLinkService.CreateProductLink, req);
        await Service.initMerchant();
        return resp
    };

    isValid = (): boolean => {
        if (ValidationsUtils.title(this.state.title) != "") {
            return false
        }
        if (ValidationsUtils.subTitle(this.state.subTitle) != "") {
            return false
        }

        if (this.props.product) {
            return this.state.title != this.props.product.getTitle() ||
                this.state.subTitle != this.props.product.getSubTitle() ||
                (this.state.image != null && this.state.image?.getId() != this.props.product.getImageId());
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.product) {
                await this.updateProduct()
            } else {
                await this.createProduct()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createProduct = async () => {
        let product = new Product();
        product.setTitle(this.state.title);
        product.setSubTitle(this.state.subTitle);
        if (this.state.image) {
            product.setImageId(this.state.image.getId());
        }
        product.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateProductRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProduct(product);
        await GrpcClient.invokeWithAuth(ProductService.CreateProduct, req);
    };

    updateProduct = async () => {
        let updateMasks: Array<ProductUpdateMaskMap[keyof ProductUpdateMaskMap]> = [];
        let product = new Product();
        product.setId(this.props.product!.getId());
        if (this.state.title != this.props.product!.getTitle()) {
            product.setTitle(this.state.title);
            updateMasks.push(ProductUpdateMask.PRODUCT_UPDATE_MASK_TITLE)
        }
        if (this.state.subTitle != this.props.product!.getSubTitle()) {
            product.setSubTitle(this.state.subTitle);
            updateMasks.push(ProductUpdateMask.PRODUCT_UPDATE_MASK_SUB_TITLE)
        }
        if (this.state.image?.getId() != this.props.product!.getImageId()) {
            product.setImageId(this.state.image!.getId());
            updateMasks.push(ProductUpdateMask.PRODUCT_UPDATE_MASK_IMAGE_ID)
        }

        let req = new UpdateProductRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProduct(product);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(ProductService.UpdateProduct, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
                {this.renderImageModal()}
            </SingleLayout>
        )
    };

    renderImageModal = () => {
        return (
            <BottomModal ref={this.modalRef as RefObject<BottomModal>}>
                <FlatList
                    bounces={false}
                    showsVerticalScrollIndicator={true}
                    data={this.props.images}
                    keyExtractor={item => item.getId()}
                    initialNumToRender={10}
                    numColumns={3}
                    renderItem={item => (
                        <TouchableOpacity
                            style={{flex: 1, height: 150, borderColor: colorStyles.primaryBorder, borderWidth: 1}}
                            onPress={() => {
                                this.setState({image: item.item})
                                this.modalRef.current!.close();
                            }}>
                            <ImageView imageUrl={item.item.getUrl()} noBorder={true}/>
                        </TouchableOpacity>
                    )}/>
            </BottomModal>
        )
    }

    renderForm = () => {
        return (
            <ScrollView>
                {
                    this.props.product?.getImageId() || this.state.image
                        ? (
                            <TouchableOpacity onPress={() => this.modalRef.current!.open()}>
                                <ImageView
                                    imageUrl={this.state.image ? this.state.image.getUrl() : this.props.product ? this.props.product.getImageUrl() : ""}
                                    style={{height: 225, width: '100%'}}/>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity
                                onPress={() => this.modalRef.current!.open()}
                                style={[{height: 225, width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center'}, containerStyles.border]}>
                                    <Ionicons name="ios-image" color={colorStyles.secondaryDarkText} size={65}/>
                            </TouchableOpacity>
                        )
                }

                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the product information.
                </Text>

                <DividerView/>

                <NameField
                    forwardRef={this.titleRef}
                    error={Boolean(this.state.titleError)}
                    label="Title"
                    placeholder="Title"
                    value={this.state.title}
                    onChangeText={e => this.setState({
                        title: e,
                        titleError: ValidationsUtils.title(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <TextField
                    forwardRef={this.subTitleRef}
                    error={Boolean(this.state.subTitleError)}
                    label="Sub title"
                    placeholder="Sub title"
                    value={this.state.subTitle}
                    numberOfLines={2}
                    onChangeText={e => this.setState({
                        subTitle: e,
                        subTitleError: ValidationsUtils.subTitle(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <FieldErrors errors={[this.state.titleError, this.state.subTitleError]}/>

                {this.renderProducts()}

                {this.renderProductGroups()}
            </ScrollView>
        )
    };

    renderProducts = () => {
        if (!this.props.product) {
            return null
        } else {
            return (
                <View>
                    <View style={[styles.infoContainer, {marginTop: 0}]}>
                        <View style={{flex: 1, justifyContent: "center"}}>
                            <Text style={[textStyles.secondarySubTitle, {textAlign: "center"}]}>
                                Add, remove or edit variations.
                            </Text>
                        </View>
                        <TouchableOpacity
                            onPress={() => this.props.navigation.navigate("Variation", {productId: this.props.product!.getId()})}>
                            <Ionicons name="md-add" size={28} color={colorStyles.BLUE}/>
                        </TouchableOpacity>
                    </View>
                    {this.props.product?.getVariationsList().map(variation => {
                        return <VariationRow
                            key={variation.getId()}
                            variation={variation}
                            onPressEdit={() => this.props.navigation.navigate("Variation", {id: variation.getId()})}/>
                    })}
                </View>
            )
        }
    };

    renderProductGroups = () => {
        if (!this.props.product) {
            return null
        } else {
            return (
                <View>
                    <View style={{height: 0, overflow: "hidden"}}>
                        <SelectorField
                            forwardRef={this.productGroupRef}
                            value={this.state.selectedProductGroupId}
                            label="Max Choice"
                            doneText={"Done"}
                            items={getStore.getState().merchant.productGroups
                                .filter(e => !e.getProductIdsList().includes(this.props.product!.getId()))
                                .map(e => ({value: e.getId(), label: e.getTitle()}))}
                            error={false}
                            onChange={e => this.setState({selectedProductGroupId: e})}
                            onUp={Keyboard.dismiss}
                            onDown={this.onAddProductGroup}/>
                    </View>
                    <View style={styles.infoContainer}>
                        <View style={{flex: 1, justifyContent: "center"}}>
                            <Text style={[textStyles.secondarySubTitle, {textAlign: "center"}]}>
                                Add or remove from a product groups.
                            </Text>
                        </View>
                        <View style={{width: 30}}>
                            {
                                this.state.addingProductGroup
                                    ? <ActivityIndicator color={colorStyles.BLUE} size={28}/>
                                    : (
                                        <TouchableOpacity onPress={() => this.productGroupRef.current!.togglePicker()}>
                                            <Ionicons name="md-add" size={28} color={colorStyles.BLUE}/>
                                        </TouchableOpacity>
                                    )
                            }
                        </View>
                    </View>
                    {getStore.getState().merchant.productGroups
                        .filter(e => e.getProductIdsList().includes(this.props.product!.getId()))
                        .map(productGroup => {
                            return <ProductLinkRow
                                key={productGroup.getId()}
                                productId={this.props.product!.getId()}
                                productGroup={productGroup}/>
                        })}
                </View>
            )
        }
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: "center",
        flexDirection: "row",
    },
});