import {DayOfTheWeek, DayOfTheWeekMap} from "@emreat/proto/backend/v1/hours_pb";
import {CartDispatchMethodMap} from "@emreat/proto/backend/v1/carts_pb";
import {PaymentGatewayMap} from "@emreat/proto/backend/v1/payments_pb";
import {OrderStatusMap} from "@emreat/proto/backend/v1/orders_pb";

export let dayOfTheWeekProtoToString: { [key in DayOfTheWeekMap[keyof DayOfTheWeekMap]]: string } = {
    0: "Unknown",
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
};

export let dayOfTheWeekToProto: { [key in number]: DayOfTheWeekMap[keyof DayOfTheWeekMap] } = {
    0: DayOfTheWeek.DAY_OF_WEEK_SUNDAY,
    1: DayOfTheWeek.DAY_OF_WEEK_MONDAY,
    2: DayOfTheWeek.DAY_OF_WEEK_TUESDAY,
    3: DayOfTheWeek.DAY_OF_WEEK_WEDNESDAY,
    4: DayOfTheWeek.DAY_OF_WEEK_THURSDAY,
    5: DayOfTheWeek.DAY_OF_WEEK_FRIDAY,
    6: DayOfTheWeek.DAY_OF_WEEK_SATURDAY,
};

export let gatewayToName: { [key in PaymentGatewayMap[keyof PaymentGatewayMap]]: string } = {
    0: "Unknown",
    1: "Cash",
    2: "Card",
    3: "Paypal",
};

export let gatewayToIcon: { [key in PaymentGatewayMap[keyof PaymentGatewayMap]]: string } = {
    0: "Unknown",
    1: "money",
    2: "credit-card",
    3: "paypal",
};

export let dispatchMethodToName: { [key in CartDispatchMethodMap[keyof CartDispatchMethodMap]]: string } = {
    0: "Unknown",
    1: "Collection",
    2: "Delivery",
    3: "Eat in",
};

export let orderStatusToName: { [key in OrderStatusMap[keyof OrderStatusMap]]: string } = {
    0: "Unknown",
    2: "Received",
    3: "Confirmed",
    4: "Cancelled",
};
export let orderStatusToDescription: { [key in OrderStatusMap[keyof OrderStatusMap]]: string } = {
    0: "",
    2: "Your order has been sent to the restaurant and awaiting confirmation.",
    3: "Your order has been accepted and is being prepared.",
    4: "Your order has been cancelled and is being refunded.",
};