import React, {Component} from "react";
import {Text, View} from "react-native";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import moment from "moment";
import {Cart, CartDispatchMethod, CartDispatchMethodMap} from "@emreat/proto/backend/v1/carts_pb";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Payment, PaymentGatewayMap} from "@emreat/proto/backend/v1/payments_pb";
import CartSummaryComponent from "./CartSummary";

export let dispatchMethodToTimeDescription: { [key in CartDispatchMethodMap[keyof CartDispatchMethodMap]]: string } = {
    0: "Unknown",
    1: "Collection time",
    2: "Delivery time",
    3: "Eat in time",
};

export let paymentDescription: { [key in CartDispatchMethodMap[keyof CartDispatchMethodMap]]: { [key in PaymentGatewayMap[keyof PaymentGatewayMap]]: string } } = {
    0: {
        0: "unknown",
        1: "unknown",
        2: "unknown",
        3: "unknown",
    },
    1: {
        0: "unknown",
        1: "Cash on collection",
        2: "Paid by card",
        3: "Paid by Paypal",
    },
    2: {
        0: "unknown",
        1: "Cash on delivery",
        2: "Paid by card",
        3: "Paid by Paypal",
    },
    3: {
        0: "unknown",
        1: "Cash on eat in",
        2: "Paid by card",
        3: "Paid by Paypal",
    },
};

interface props {
    order: Order
}

export default class OrderReceiptComponent extends Component<props> {

    cart = (): Cart => {
        return this.props.order.getCart()!
    };

    payment = (): Payment => {
        return this.props.order.getPayment()!
    };

    render() {
        return (
            <View>
                {this.renderDispatchTime()}
                {this.renderDispatchLocation()}
                {this.renderTableNumber()}
                {this.renderPaymentDetails()}
                {this.renderContactDetails()}
                {this.renderAdditionalNotes()}
                {this.renderOrderItems()}
            </View>
        )
    };

    renderSection = (label: string, value: string, logo: string) => {
        return (
            <View style={[containerStyles.paddingRowMediumTop, containerStyles.borderBottom]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Ionicons name={logo} size={22} style={{color: colorStyles.DARK_TEXT, marginRight: 10}}/>
                    <Text style={textStyles.primaryTitle}>{label}</Text>
                </View>
                <Text style={[
                    textStyles.primarySubTitle,
                    {marginTop: 5, marginBottom: 10, paddingLeft: 10, paddingRight: 10},]}>
                    {value}
                </Text>
            </View>
        )
    };

    renderDispatchTime = () => {
        let date = new Date(this.props.order.getCart()!.getDispatchTimestamp()!.toDate()!);

        return this.renderSection(
            dispatchMethodToTimeDescription[this.cart().getDispatchMethod()],
            moment(date).calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'}),
            "md-clock",
        )
    };

    renderDispatchLocation = () => {
        if (this.cart().getDispatchMethod() != CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY) {
            return null
        }
        return this.renderSection(
            "Delivering to.",
            this.cart().getDispatchFormattedLocation().split(", ").join("\n"),
            "md-compass",
        )
    };

    renderTableNumber = () => {
        if (this.cart().getDispatchMethod() != CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN) {
            return null
        }
        return this.renderSection("Table no.", this.cart().getDispatchTableNumber(), "md-compass")
    };

    renderPaymentDetails = () => {
        return this.renderSection(
            "Payment method",
            paymentDescription[this.cart().getDispatchMethod()][this.payment().getGateway()],
            "md-card",
        )
    };

    renderContactDetails = () => {
        return this.renderSection(
            "Contact details",
            `${this.cart().getContactName()}\n${this.cart().getContactPhoneNumber()}`,
            "md-call",
        )
    };

    renderAdditionalNotes = () => {
        if (!this.cart().getAdditionalNotes()) {
            return null
        }
        return this.renderSection("Additional notes", this.cart().getAdditionalNotes(), "md-text")
    };

    renderOrderItems = () => {
        return (
            <View style={containerStyles.paddingRowMedium}>
                <View style={{flexDirection: 'row'}}>
                    <Ionicons name="md-cart" size={22} style={{color: colorStyles.DARK_TEXT, marginRight: 10}}/>
                    <Text style={textStyles.primaryTitle}>Order summary</Text>
                </View>
                <CartSummaryComponent cart={this.cart()}/>
            </View>
        )
    };
}