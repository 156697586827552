import * as React from "react";
import {Component, RefObject} from "react";
import {Platform, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import ScrollView from "../../common/views/ScrollView";
import NameField from "../../common/fields/Name";
import {ValidationsUtils} from "../../common/fields/Validations";
import PhoneField from "../../common/fields/Phone";
import EmailField from "../../common/fields/Email";
import PasswordField from "../../common/fields/Password";
import FieldErrors from "../../common/fields/Errors";
import SubmitButton from "../../common/buttons/SubmitButton";
import {toastRef} from "../../common/views/ToastView";
import {LoginRequest, LoginResponse} from "@emreat/proto/backend/v1/authentication_pb";
import Constants from "expo-constants";
import {Service} from "../../Service";
import {GrpcClient} from "../../GrpcClient";
import {AuthenticationService} from "@emreat/proto/backend/v1/authentication_pb_service";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {CreateUserRequest} from "@emreat/proto/backend/v1/users_pb";
import {UserService} from "@emreat/proto/backend/v1/users_pb_service";
import {Password} from "@emreat/proto/backend/v1/passwords_pb";
import {CreatePasswordRequest} from "@emreat/proto/backend/v1/passwords_pb";
import {PasswordService} from "@emreat/proto/backend/v1/passwords_pb_service";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import DividerView from "../../common/views/DividerView";
import SingleLayout from "../../common/layouts/SingleLayout";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList, 'Register'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<AuthNavigatorParamList, 'Register'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    return <Screen navigation={props.navigation}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList>, StackNavigationProp<RootNavigatorParamList>>
}

interface State {
    firstName: string
    lastName: string
    emailAddress: string
    secret: string
    phoneNumber: string

    firstNameError: string
    lastNameError: string
    emailAddressError: string
    secretError: string
    phoneNumberError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    firstNameRef: RefObject<TextInput>;
    lastNameRef: RefObject<TextInput>;
    phoneNumberRef: RefObject<TextInput>;
    emailAddressRef: RefObject<TextInput>;
    passwordRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            emailAddress: "",
            secret: "",
            phoneNumber: "",

            firstNameError: "",
            lastNameError: "",
            emailAddressError: "",
            secretError: "",
            phoneNumberError: "",

            submitting: false,
        };
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.phoneNumberRef = React.createRef();
        this.emailAddressRef = React.createRef();
        this.passwordRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Let's get started",
        })
    };

    isValid = (): boolean => {
        if (ValidationsUtils.name(this.state.firstName) != "") {
            return false
        }
        if (ValidationsUtils.name(this.state.lastName) != "") {
            return false
        }
        if (ValidationsUtils.phoneNumber(this.state.phoneNumber) != "") {
            return false
        }
        if (ValidationsUtils.emailAddress(this.state.emailAddress) != "") {
            return false
        }
        if (ValidationsUtils.password(this.state.secret) != "") {
            return false
        }
        return this.state.secret != "";
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.register();
            this.setState({submitting: false});
            this.props.navigation.navigate('Home', {})
        } catch (e) {
            if (e.toString() == "ERROR_USER_ALREADY_EXISTS") {
                toastRef.current!.show("User with email address already exists.");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    register = async () => {
        let password = await this.createPassword();
        let user = await this.createUser(password.getId());
        await this.login();
    };

    login = async () => {
        let req = new LoginRequest();
        req.setEmailAddress(this.state.emailAddress.toLowerCase());
        req.setPasswordSecret(this.state.secret);
        req.setMerchantId(Constants.manifest.extra.merchantId);
        req.setPushNotificationToken(await Service.getPushNotificationTokens());
        let resp: LoginResponse = await GrpcClient.invoke(AuthenticationService.Login, req);
        await Service.initToken(resp.getTokenSecret());
    };

    createUser = async (passwordId: string): Promise<User> => {
        let user = new User();
        user.setFirstName(this.state.firstName);
        user.setLastName(this.state.lastName);
        user.setEmailAddress(this.state.emailAddress.toLowerCase());
        user.setPhoneNumber(this.state.phoneNumber);
        user.setPasswordId(passwordId);
        user.setMerchantId(Constants.manifest.extra.merchantId);

        let createUserReq = new CreateUserRequest();
        createUserReq.setRequestId(GrpcClient.newUuidV4());
        createUserReq.setUser(user);
        return await GrpcClient.invoke(UserService.CreateUser, createUserReq)
    };

    createPassword = async (): Promise<Password> => {
        let password = new Password();
        password.setSecret(this.state.secret);

        let req = new CreatePasswordRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setPassword(password);
        return await GrpcClient.invoke(PasswordService.CreatePassword, req);
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Let's get started"
                subTitle="Sign up with your email address."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <View style={{flex: 1}}>
                <ScrollView>
                    <TouchableOpacity onPress={() => this.props.navigation.navigate('Login', {})}>
                        <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                            Already registered? <Text style={{color: colorStyles.RED}}>Log in</Text>
                        </Text>
                    </TouchableOpacity>

                    <DividerView/>

                    <NameField
                        forwardRef={this.firstNameRef}
                        placeholder="First name"
                        label={"First name"}
                        value={this.state.firstName}
                        error={Boolean(this.state.firstNameError)}
                        onChangeText={e => this.setState({firstName: e, firstNameError: ValidationsUtils.name(e)})}
                        onSubmitEditing={() => this.lastNameRef.current!.focus()}/>

                    <NameField
                        forwardRef={this.lastNameRef}
                        placeholder="Last name"
                        label={"Last name"}
                        value={this.state.lastName}

                        error={Boolean(this.state.lastNameError)}
                        onChangeText={e => this.setState({lastName: e, lastNameError: ValidationsUtils.name(e)})}
                        onSubmitEditing={() => this.phoneNumberRef.current!.focus()}/>

                    <PhoneField
                        forwardRef={this.phoneNumberRef}
                        value={this.state.phoneNumber}
                        error={Boolean(this.state.phoneNumberError)}
                        onChangeText={e => this.setState({
                            phoneNumber: e,
                            phoneNumberError: ValidationsUtils.phoneNumber(e)
                        })}
                        onSubmitEditing={() => this.emailAddressRef.current!.focus()}/>

                    <EmailField
                        forwardRef={this.emailAddressRef}
                        value={this.state.emailAddress}
                        error={Boolean(this.state.emailAddressError)}
                        onChangeText={e => this.setState({
                            emailAddress: e,
                            emailAddressError: ValidationsUtils.emailAddress(e)
                        })}
                        onSubmitEditing={() => this.passwordRef.current!.focus()}/>

                    <PasswordField
                        forwardRef={this.passwordRef}
                        returnKeyType="done"
                        error={Boolean(this.state.secretError)}
                        value={this.state.secret}
                        onChangeText={e => this.setState({secret: e, secretError: ValidationsUtils.password(e)})}
                        onSubmitEditing={() => this.passwordRef.current!.blur()}/>

                    <FieldErrors errors={[
                        this.state.firstNameError, this.state.lastNameError, this.state.phoneNumberError,
                        this.state.emailAddressError, this.state.secretError
                    ]}/>
                </ScrollView>

                <TouchableOpacity onPress={() => this.props.navigation.navigate("TermsOfUse", {})}>
                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer, {marginBottom: 0}]}>
                        By creating an account you agree to our <Text style={{color: colorStyles.RED}}>terms of
                        use.</Text>
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.navigation.navigate("PrivacyPolicy", {})}>
                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer, {marginTop: 0}]}>
                        Please also check out our <Text style={{color: colorStyles.RED}}>privacy policy.</Text>
                    </Text>
                </TouchableOpacity>

                <View style={containerStyles.paddingRowMediumBottom}>
                    <SubmitButton
                        text="Create account"
                        inActive={!this.isValid() || this.state.submitting}
                        submitting={this.state.submitting}
                        onPress={this.onSubmit}
                        disabled={!this.isValid()}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});
