import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef?: RefObject<TextInput>
    error?: boolean
    label?: string
}

export default React.memo(function PasswordField(props: Props) {
    let onChange = (value: string) => {
        value = value.replace(/\s/g, '');
        props.onChangeText!(value);
    };

    return (
        <Field
            maxLength={32}
            clearTextOnFocus={true}
            placeholder="Password"
            keyboardType="default"
            autoCapitalize="none"
            autoCorrect={false}
            autoCompleteType="password"
            multiline={false}
            {...props}

            onChangeText={onChange}
            label={props.label ? props.label : "Password"}
            error={props.error}
            showSecureTextEntry={true}/>
    )
});