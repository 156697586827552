import * as React from "react";
import {Component} from "react";
import {Text, View} from "react-native";
import {colorStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList} from "../../Navigator";
import ScrollView from "../../common/views/ScrollView";
import SingleLayout from "../../common/layouts/SingleLayout";

interface Props {
    navigation: StackNavigationProp<AuthNavigatorParamList>
}

export default class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Terms of use',
        })
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Terms of use"
                subTitle="The legal terms regarding the access and use of our website"
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <View style={{paddingLeft: 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15}}>
                    <View>
                        <Text style={textStyles.primaryTitle}>Last Updated 5th December 2020</Text>
                    </View>
                    <View style={{paddingTop: 10, borderBottomColor: colorStyles.LIGHT_TEXT, borderBottomWidth: 0.5}}/>
                    <View style={{paddingTop: 15}}>
                        <Text style={textStyles.primaryTitle}>IMPORTANT LEGAL NOTICE</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            This page sets out the terms and conditions ("Website Terms") on which we, EMREAT Ltd
                            ("we", "our" or "EMREAT Ltd"), provide access to our website https://www.emreat.com and
                            any EMREAT mobile application through which you order products (together, "the Website").
                        </Text>
                    </View>
                    <View style={{paddingTop: 15}}>
                        <Text style={textStyles.primaryTitle}>I. TERMS AND CONDITIONS OF USE AND SALE</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>1. INTRODUCTION AND OUR ROLE</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            1.1. Company details: EMREAT is a company registered in England and Wales with
                            registered company number 12893098, whose registered office is at 116 Penrose House,
                            Penrose street, London, SE17 3EA.
                            {'\n'} {'\n'}
                            1.2. Product Orders: We provide a way for you to communicate your orders
                            (“Order” or "Orders") for products (“Product” or "Products") to delivery or
                            takeaway restaurants in the UK (“Restaurant” or "Restaurants") displayed on the Website.
                            The legal contract for the supply and purchase of Products is between you and the
                            Restaurant that you place your Order with and we will conclude the sale of Products on
                            behalf of, and as commercial agent for, the Restaurants in all cases.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>2. WEBSITE ACCESS AND TERMS</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            2.1. Website access: You may access some areas of the Website without making an Order
                            or registering your details with us. Most areas of the Website are open to everyone.
                            {'\n'} {'\n'}
                            2.2. Acceptance of terms: By accessing any part of the Website, you indicate that you
                            accept these Website Terms. If you do not accept these Website Terms, you should leave
                            the Website immediately, and you will not be able to order any Products through the Website.
                            {'\n'} {'\n'}
                            2.3. Revision of terms: We may revise these Website Terms at any time. You should check
                            the Website regularly to review the current Website Terms, because they are binding on you.
                            You will be subject to the policies and terms and conditions in force at the time
                            that you place an Order through us.
                            {'\n'} {'\n'}
                            2.4. Responsibility: You are responsible for making all arrangements necessary for you to
                            have access to the Website. You are also responsible for ensuring that all persons who
                            access the Website through your Internet connection are aware of these Website Terms and
                            that they comply with them.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>3. YOUR STATUS</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            3.1. Capacity and age: By placing an Order through the Website, you warrant that:
                            {'\n'}
                            3.1.1. You are legally capable of entering into binding contracts with Restaurant; and
                            {'\n'}
                            3.1.2. You are at least 18 years old.
                            {'\n'} {'\n'}
                            3.2. You acknowledge and agree that if you have a specific food allergy or intolerance,
                            you will contact the Restaurant directly to check that the food is suitable for you,
                            before placing your order directly with them.
                            {'\n'} {'\n'}
                            3.3. Alcohol, cigarettes and other smoking products:
                            {'\n'}
                            3.3.1. You acknowledge and agree that: it is an offence for any person under the age
                            of 18 to buy, or attempt to buy, alcohol in the UK, or for any person to buy, or attempt
                            to buy, alcohol, tobacco or other smoking products in the UK on behalf of any person
                            who is under the age of 18;
                            {'\n'}
                            3.3.2. If your Order includes any alcohol, cigarettes or other smoking products, you
                            will be asked to provide proof of your age on collection or delivery of your Order.
                            If you are unable to provide proof that you are aged 18 or over to the satisfaction of
                            your chosen Restaurant, or if the Restaurant reasonably believes that the alcohol,
                            cigarettes or other smoking products you have ordered have been bought by you on behalf
                            of someone under the age of 18, the Restaurant reserves the right not to complete the
                            delivery of the alcohol, cigarettes or other smoking products to you.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>4. HOW TO MAKE AN ORDER AND HOW IT IS PROCESSED</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            4.1. Compiling your Order: Once you have selected the Products you wish to order from
                            the menu of your chosen Restaurant and provided the other required information,
                            you will be given the opportunity to submit your Order by clicking or selecting the
                            "proceed", "place my order" or similar button. It is important that you check all the
                            information that you enter and correct any errors before clicking or selecting this
                            button; once you do so you will be entering into a contract with the Restaurant and
                            errors cannot be corrected (subject to paragraph 4.2. below).
                            {'\n'}{'\n'}
                            4.2. Amending or cancelling your Order: Once you have submitted your Order and your
                            payment has been authorised, you will not be entitled to change or cancel your Order,
                            nor will you be entitled to a refund. If you wish to change or cancel your Order,
                            you may contact the restaurant. However, there is no guarantee that the restaurant will
                            agree to your requests as they may have already started processing your Order.
                            {'\n'}{'\n'}
                            4.3. Payment authorisation: Where any payment you make is not authorised, your
                            Order will not be processed or communicated to the relevant Restaurant.
                            {'\n'}{'\n'}
                            4.4. Processing your Order and Restaurant rejections: On receipt of your Order,
                            we will send it to the relevant Restaurant and will notify you by email that your
                            Order has been received and is being processed. However, Restaurants have the ability to
                            reject Orders at any time because they are too busy, due to weather conditions or
                            for any other reason. In doing so, Restaurants will be in breach of their agreement with
                            you and any payment made in respect of the order will be returned to you.
                            {'\n'}{'\n'}
                            4.5. Delivery of your Order: Estimated times for deliveries and collections are provided
                            by the Restaurants and are only estimates. Neither we nor the Restaurants guarantee
                            that Orders will be delivered or will be available for collection within the estimated
                            times.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>5. PRICE AND PAYMENT</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            5.1. VAT and delivery costs: Prices will be as quoted on the Website.
                            These prices include VAT but may exclude delivery costs and any administration or
                            service charge imposed by the Restaurant. These will be added to the total amount
                            due where applicable.
                            {'\n'}{'\n'}
                            5.2. Payment methods: Payment for Orders must be made by an accepted credit or debit card
                            through the Website or in cash to the Restaurant at the point of delivery, collection.
                            5.3. Rejected Orders: Because of standard banking procedures, once you have submitted an
                            Order that you are paying for by credit or debit card and your payment has been authorised,
                            your bank or card issuer will "ring-fence" the full amount of your Order.
                            If your Order is subsequently rejected by the Restaurant or cancelled for any other reason,
                            your bank or card issuer will not transfer the funds for the Order to us, and will instead
                            release the relevant amount back into your available balance. However, this may take between
                            3 to 5 working days. You acknowledge and agree that neither we nor the relevant
                            Restaurant will be responsible or liable to you in relation to this delay by your bank
                            or card issuer in the release of funds back into your account.
                        </Text>
                    </View>
                </View>
            </ScrollView>
        )
    }
}
