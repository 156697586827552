import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef?: RefObject<TextInput>
    error?: boolean
}

export default React.memo(function EmailField(props: Props) {
    let onChange = (value: string) => {
        value = value.replace(/\s/g, '');
        props.onChangeText!(value);
    };

    return (
        <Field
            placeholder="name@example.com"
            keyboardType="email-address"
            autoCapitalize="none"
            autoCompleteType="email"
            autoCorrect={false}
            {...props}

            onChangeText={onChange}
            label="Email"
            error={props.error}
            showSecureTextEntry={false}/>
    )
});
