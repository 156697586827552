import * as React from "react";
import {ActivityIndicator, View} from "react-native";
import {colorStyles} from "../Styles";

export default () => {
    return (
        <View style={{flex: 1, justifyContent: 'center', backgroundColor: colorStyles.merchantPrimaryColor}}>
            <ActivityIndicator color={colorStyles.merchantPrimaryColor == "#FFFFFF" ? colorStyles.primaryDarkText : colorStyles.primaryLightText} size="large"/>
        </View>
    )
};
