import * as React from 'react';
import {Ionicons} from "@expo/vector-icons";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {createStackNavigator} from "@react-navigation/stack";
import OrdersScreen from "./screens/dashboard/OrdersScreen";
import PostingsScreen from "./screens/dashboard/PostingsScreen";
import {stackNavigationOption, tabNavigationOptions} from "./Styles";
import OrderScreen from "./screens/dashboard/OrderScreen";
import SettingsScreen from "./screens/dashboard/SettingsScreen";
import ProductsScreen from "./screens/dashboard/ProductsScreen";
import ProductGroupsScreen from "./screens/dashboard/ProductGroupsScreen";
import ModifierGroupsScreen from "./screens/dashboard/ModifierGroupsScreen";
import ProductGroupScreen from "./screens/dashboard/ProductGroupScreen";
import ProductScreen from "./screens/dashboard/ProductScreen";
import ModifierGroupScreen from "./screens/dashboard/ModifierGroupScreen";
import ModifierScreen from "./screens/dashboard/ModifierScreen";
import VariationScreen from "./screens/dashboard/VariationScreen";
import ImagesScreen from "./screens/dashboard/ImagesScreen";
import HoursScreen from "./screens/dashboard/HoursScreen";
import HourScreen from "./screens/dashboard/HourScreen";

let Stack = createStackNavigator();
let NativeTab = createBottomTabNavigator();
let Tab1 = createStackNavigator();
let Tab2 = createStackNavigator();
let Tab3 = createStackNavigator();

export type DashboardStackParamList = {
    Home: {}
    Order: { id: string }
    ProductGroups: {}
    ProductGroup: { id?: string }
    Products: {}
    Product: { id?: string }
    ModifierGroups: {}
    ModifierGroup: { id?: string }
    Modifier: { id?: string, modifierGroupId?: string }
    Variation: { id?: string, productId?: string }
    Images: {}
    Hours: {}
    Hour: { id?: string }
}

export default () => {
    return (
        <Stack.Navigator screenOptions={stackNavigationOption()}>
            <Stack.Screen
                name="Home"
                component={NativeTabNavigator}
                options={{headerShown: false, gestureEnabled: false}}/>
            <Stack.Screen name="Order" component={OrderScreen}/>
            <Stack.Screen name="ProductGroups" component={ProductGroupsScreen}/>
            <Stack.Screen name="ProductGroup" component={ProductGroupScreen}/>
            <Stack.Screen name="Products" component={ProductsScreen}/>
            <Stack.Screen name="Product" component={ProductScreen}/>
            <Stack.Screen name="ModifierGroups" component={ModifierGroupsScreen}/>
            <Stack.Screen name="ModifierGroup" component={ModifierGroupScreen}/>
            <Stack.Screen name="Modifier" component={ModifierScreen}/>
            <Stack.Screen name="Variation" component={VariationScreen}/>
            <Stack.Screen name="Images" component={ImagesScreen}/>
            <Stack.Screen name="Hours" component={HoursScreen}/>
            <Stack.Screen name="Hour" component={HourScreen}/>
        </Stack.Navigator>
    )
}

class NativeTabNavigator extends React.PureComponent {
    render() {
        return (
            <NativeTab.Navigator initialRouteName="MerchantSettings" tabBarOptions={tabNavigationOptions()}>

                <NativeTab.Screen
                    name="MerchantOrders"
                    component={this.renderTab1}
                    options={{
                        title: "Orders",
                        tabBarIcon: (props) => (<Ionicons name="md-list-box" color={props.color} size={props.size}/>)
                    }}/>

                <NativeTab.Screen
                    name="MerchantPostings"
                    component={this.renderTab2}
                    options={{
                        title: "Transactions",
                        tabBarIcon: (props) => (<Ionicons name="md-card" color={props.color} size={props.size}/>)
                    }}/>

                <NativeTab.Screen
                    name="MerchantSettings"
                    component={this.renderTab3}
                    options={{
                        title: "Settings",
                        tabBarIcon: (props) => (<Ionicons name="md-settings" color={props.color} size={props.size}/>)
                    }}/>
            </NativeTab.Navigator>
        );
    };

    renderTab1 = () => {
        return (
            <Tab1.Navigator screenOptions={stackNavigationOption()}>
                <Tab1.Screen name="MerchantOrders" component={OrdersScreen}/>
            </Tab1.Navigator>
        );
    };

    renderTab2 = () => {
        return (
            <Tab2.Navigator screenOptions={stackNavigationOption()}>
                <Tab2.Screen name="MerchantPostings" component={PostingsScreen}/>
            </Tab2.Navigator>
        );
    };

    renderTab3 = () => {
        return (
            <Tab3.Navigator screenOptions={stackNavigationOption()}>
                <Tab3.Screen name="MerchantSettings" component={SettingsScreen}/>
            </Tab3.Navigator>
        );
    };
}