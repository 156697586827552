import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {Platform, StyleSheet, Text, TextInput, View} from "react-native";
import {containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {getStore, RootState} from "../../reducers/reducers";
import {
    Location,
    LocationUpdateMask,
    LocationUpdateMaskMap
} from "@emreat/proto/backend/v1/locations_pb";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import AddressField from "../../common/fields/Address";
import {ValidationsUtils} from "../../common/fields/Validations";
import PostcodeField from "../../common/fields/Postcode";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {
    CreateLocationRequest,
    UpdateLocationRequest
} from "@emreat/proto/backend/v1/locations_pb";
import {GrpcClient} from "../../GrpcClient";
import {LocationService} from "@emreat/proto/backend/v1/locations_pb_service";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import SubmitButton from "../../common/buttons/SubmitButton";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    locations: state.customer.locations
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Location'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Location'>

    user: User | null
    locations: Array<Location>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }
    let location = props.locations.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !location) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} location={location}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>
    location: Location | null
}

interface State {
    lineOne: string
    lineTwo: string
    city: string
    postcode: string

    lineOneError: string
    lineTwoError: string
    cityError: string
    postcodeError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    lineOneRef: RefObject<TextInput>;
    lineTwoRef: RefObject<TextInput>;
    cityRef: RefObject<TextInput>;
    postcodeRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            lineOne: this.props.location ? this.props.location.getLineOne() : "",
            lineTwo: this.props.location ? this.props.location.getLineTwo() : "",
            city: this.props.location ? this.props.location.getCity() : "",
            postcode: this.props.location ? this.props.location.getPostcode() : "",

            lineOneError: '',
            lineTwoError: '',
            cityError: '',
            postcodeError: '',

            submitting: false,
        };
        this.lineOneRef = createRef();
        this.lineTwoRef = createRef();
        this.cityRef = createRef();
        this.postcodeRef = createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.location) {
            this.props.navigation.setOptions({
                title: 'Edit address',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add address',
            })
        }
    };

    isValid = (): boolean => {
        if (ValidationsUtils.locationLineOne(this.state.lineOne) != "") {
            return false
        }
        if (ValidationsUtils.locationLineTwo(this.state.lineTwo) != "") {
            return false
        }
        if (ValidationsUtils.locationPostcode(this.state.postcode) != "") {
            return false
        }
        if (ValidationsUtils.locationCity(this.state.city) != "") {
            return false
        }

        if (this.props.location) {
            return this.state.lineOne != this.props.location.getLineOne() ||
                this.state.lineTwo != this.props.location.getLineTwo() ||
                this.state.city != this.props.location.getCity() ||
                this.state.postcode != this.props.location.getPostcode();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.location) {
                await this.updateLocation()
            } else {
                await this.createLocation()
            }

            if (!this.props.navigation.canGoBack()) {
                this.props.navigation.navigate('Locations', {})
            } else {
                this.props.navigation.pop()
            }
        } catch (e) {
            if (e.toString() == "ERROR_LOCATION_CANNOT_GEOCODE_LOCATION") {
                toastRef.current!.show("Cannot find location. Please enter a valid address!");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    createLocation = async () => {
        let location = new Location();
        location.setUserId(getStore.getState().auth.user!.getId());
        location.setLineOne(this.state.lineOne);
        location.setLineTwo(this.state.lineTwo);
        location.setCity(this.state.city);
        location.setPostcode(this.state.postcode);

        let req = new CreateLocationRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setLocation(location);
        let resp: Location = await GrpcClient.invokeWithAuth(LocationService.CreateLocation, req);
        getStore.dispatch({type: "SET_LOCATIONS", locations: [resp]});
    };

    updateLocation = async () => {
        let updateMasks: Array<LocationUpdateMaskMap[keyof LocationUpdateMaskMap]> = [];
        let location = new Location();
        location.setId(this.props.location!.getId());
        if (this.state.lineOne != this.props.location!.getLineOne()) {
            location.setLineOne(this.state.lineOne);
            updateMasks.push(LocationUpdateMask.LOCATION_UPDATE_MASK_LINE_ONE)
        }
        if (this.state.lineTwo != this.props.location!.getLineTwo()) {
            location.setLineTwo(this.state.lineTwo);
            updateMasks.push(LocationUpdateMask.LOCATION_UPDATE_MASK_LINE_TWO)
        }
        if (this.state.city != this.props.location!.getCity()) {
            location.setCity(this.state.city);
            updateMasks.push(LocationUpdateMask.LOCATION_UPDATE_MASK_CITY)
        }
        if (this.state.postcode != this.props.location!.getPostcode()) {
            location.setPostcode(this.state.postcode);
            updateMasks.push(LocationUpdateMask.LOCATION_UPDATE_MASK_POSTCODE)
        }

        let req = new UpdateLocationRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setLocation(location);
        req.setUpdateMasksList(updateMasks);
        let resp: Location = await GrpcClient.invokeWithAuth(LocationService.UpdateLocation, req);
        getStore.dispatch({type: "SET_LOCATIONS", locations: [resp]});
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title={this.props.location ? 'Edit address' : 'Add address'}
                subTitle=""
                dark>
                {this.renderForm()}
                {
                    Platform.OS == 'web'
                        ? (
                            <View style={containerStyles.paddingRowMedium}>
                                <SubmitButton
                                    text='Save'
                                    onPress={() => this.onSubmit()}
                                    submitting={this.state.submitting}
                                    disabled={!this.isValid()}
                                    inActive={!this.isValid() || this.state.submitting}/>
                            </View>
                        ) : null
                }
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    {
                        this.props.location
                            ? 'Enter the address you would like to create.'
                            : 'Edit the details you would like to change.'
                    }
                </Text>

                <DividerView/>

                <AddressField
                    forwardRef={this.lineOneRef}
                    error={Boolean(this.state.lineOneError)}
                    label="Line one"
                    value={this.state.lineOne}
                    onChangeText={e => this.setState({lineOne: e, lineOneError: ValidationsUtils.locationLineOne(e)})}
                    onSubmitEditing={() => this.lineTwoRef.current!.focus()}/>

                <AddressField
                    forwardRef={this.lineTwoRef}
                    error={Boolean(this.state.lineTwoError)}
                    label="Line two"
                    value={this.state.lineTwo}
                    onChangeText={e => this.setState({lineTwo: e, lineTwoError: ValidationsUtils.locationLineTwo(e)})}
                    onSubmitEditing={() => this.cityRef.current!.focus()}/>

                <AddressField
                    forwardRef={this.cityRef}
                    error={Boolean(this.state.cityError)}
                    label="City"
                    value={this.state.city}
                    onChangeText={e => this.setState({city: e, cityError: ValidationsUtils.locationCity(e)})}
                    onSubmitEditing={() => this.postcodeRef.current!.focus()}/>

                <PostcodeField
                    forwardRef={this.postcodeRef}
                    returnKeyType="done"
                    error={Boolean(this.state.postcodeError)}
                    value={this.state.postcode}
                    onChangeText={e => this.setState({
                        postcode: e,
                        postcodeError: ValidationsUtils.locationPostcode(e)
                    })}
                    onSubmitEditing={() => this.postcodeRef.current!.blur()}/>

                <FieldErrors
                    errors={[
                        this.state.lineOneError,
                        this.state.lineTwoError,
                        this.state.cityError,
                        this.state.postcodeError,
                    ]}/>
            </ScrollView>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});