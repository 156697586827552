import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {StyleSheet, Text, TextInput} from "react-native";
import {textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {getStore, RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {ValidationsUtils} from "../../common/fields/Validations";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {
    CreateProductGroupRequest,
    UpdateProductGroupRequest
} from "@emreat/proto/backend/v1/product_groups_pb";
import {ProductGroupService} from "@emreat/proto/backend/v1/product_groups_pb_service";
import {
    ProductGroup,
    ProductGroupUpdateMask,
    ProductGroupUpdateMaskMap
} from "@emreat/proto/backend/v1/product_groups_pb";
import Constants from "expo-constants";
import {Service} from "../../Service";
import {ProductGroupLinkService} from "@emreat/proto/backend/v1/product_group_links_pb_service";
import {ProductGroupLink} from "@emreat/proto/backend/v1/product_group_links_pb";
import {CreateProductGroupLinkRequest} from "@emreat/proto/backend/v1/product_group_links_pb";
import NameField from "../../common/fields/Name";
import TextField from "../../common/fields/TextField";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    productGroups: state.merchant.productGroups
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'ProductGroup'>
    route: RouteProp<DashboardStackParamList, 'ProductGroup'>

    productGroups: Array<ProductGroup>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let productGroup = props.productGroups.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !productGroup) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} productGroup={productGroup}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    productGroup: ProductGroup | null
}

interface State {
    title: string
    subTitle: string

    titleError: string
    subTitleError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    titleRef: RefObject<TextInput>;
    subTitleRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            title: this.props.productGroup ? this.props.productGroup.getTitle() : "",
            subTitle: this.props.productGroup ? this.props.productGroup.getSubTitle() : "",

            titleError: '',
            subTitleError: '',

            submitting: false,
        };
        this.titleRef = createRef();
        this.subTitleRef = createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.productGroup) {
            this.props.navigation.setOptions({
                title: 'Edit Product Group',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Product Group',
            })
        }
    };

    isValid = (): boolean => {
        if (ValidationsUtils.title(this.state.title) != "") {
            return false
        }
        if (ValidationsUtils.subTitle(this.state.subTitle) != "") {
            return false
        }

        if (this.props.productGroup) {
            return this.state.title != this.props.productGroup.getTitle() ||
                this.state.subTitle != this.props.productGroup.getSubTitle();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.productGroup) {
                await this.updateProductGroup()
            } else {
                await this.createProductGroup()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createProductGroup = async () => {
        let productGroup = new ProductGroup();
        productGroup.setTitle(this.state.title);
        productGroup.setSubTitle(this.state.subTitle);
        productGroup.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateProductGroupRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProductGroup(productGroup);
        let resp: ProductGroup = await GrpcClient.invokeWithAuth(ProductGroupService.CreateProductGroup, req);
        await this.createProductGroupLink(resp.getId(), getStore.getState().merchant!.stores[0].getId());
    };

    updateProductGroup = async () => {
        let updateMasks: Array<ProductGroupUpdateMaskMap[keyof ProductGroupUpdateMaskMap]> = [];
        let productGroup = new ProductGroup();
        productGroup.setId(this.props.productGroup!.getId());
        if (this.state.title != this.props.productGroup!.getTitle()) {
            productGroup.setTitle(this.state.title);
            updateMasks.push(ProductGroupUpdateMask.PRODUCT_GROUP_UPDATE_MASK_TITLE)
        }
        if (this.state.subTitle != this.props.productGroup!.getSubTitle()) {
            productGroup.setSubTitle(this.state.subTitle);
            updateMasks.push(ProductGroupUpdateMask.PRODUCT_GROUP_UPDATE_MASK_SUB_TITLE)
        }

        let req = new UpdateProductGroupRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProductGroup(productGroup);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(ProductGroupService.UpdateProductGroup, req);
    };

    createProductGroupLink = async (productGroupId: string, storeId: string): Promise<ProductGroupLink> => {
        let productGroupLink = new ProductGroupLink();
        productGroupLink.setStoreId(storeId);
        productGroupLink.setProductGroupId(productGroupId);
        productGroupLink.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateProductGroupLinkRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setProductGroupLink(productGroupLink);
        return await GrpcClient.invokeWithAuth(ProductGroupLinkService.CreateProductGroupLink, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the product group information.
                </Text>

                <DividerView/>

                <NameField
                    forwardRef={this.titleRef}
                    error={Boolean(this.state.titleError)}
                    label="Title"
                    placeholder="Title"
                    value={this.state.title}
                    onChangeText={e => this.setState({
                        title: e,
                        titleError: ValidationsUtils.title(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <TextField
                    forwardRef={this.subTitleRef}
                    error={Boolean(this.state.subTitleError)}
                    label="Sub title"
                    placeholder="Sub title"
                    value={this.state.subTitle}
                    numberOfLines={2}
                    onChangeText={e => this.setState({
                        subTitle: e,
                        subTitleError: ValidationsUtils.subTitle(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <FieldErrors errors={[this.state.titleError, this.state.subTitleError]}/>
            </ScrollView>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: "center",
    },
});