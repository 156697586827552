import * as React from "react";
import {Component, RefObject} from "react";
import {
    ActivityIndicator,
    FlatList,
    Platform,
    RefreshControl,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import {colorStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {paginationRefreshLimit} from "../../GrpcClient";
import {Service} from "../../Service";
import {toastRef} from "../../common/views/ToastView";
import DividerView from "../../common/views/DividerView";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import SingleLayout from "../../common/layouts/SingleLayout";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import moment, {Moment} from "moment";
import {formatMoney} from "../../common/views/MoneyView";
import MessageView from "../../common/views/MessageView";
import OrderRow from "../../components/orders/OrderRow";
import {DashboardStackParamList} from "../../DashboardNavigator";
import Swipeable from "react-native-gesture-handler/Swipeable";
import {Ionicons} from "@expo/vector-icons";
import HeaderButton from "../../common/buttons/HeaderButton";

let mapStateToProps = (state: RootState) => ({
    orders: state.dashboard.orders,
});

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList, "Home">

    orders: Array<Order>
}

interface State {
    refreshing: boolean
    loadingMore: boolean
    endReached: boolean

    day: Moment,
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    swipableRef: RefObject<Swipeable>;

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            loadingMore: false,
            endReached: false,

            day: moment()
        };
        this.swipableRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
        this.loadMore().catch()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        this.navigationOptions();
        if (this.state.day.diff(prevState.day, 'days') != 0) {
            this.refresh().catch()
        }
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Orders",
            headerLeft: () => (
                <HeaderButton left={true} onPress={() => this.props.navigation.popToTop()} icon="ios-arrow-back"/>
            ),
        })
    };

    orders = () => {
        return this.props.orders.filter(e => moment(e.getCreateTimestamp()!.toDate()).isSame(this.state.day, "date"))
    };

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await Service.listOrders(0, [], [], this.state.day.clone().startOf('day'), this.state.day.clone().endOf('day'), true);
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    loadMore = async () => {
        try {
            if (!this.state.loadingMore && !this.state.endReached) {
                this.setState({loadingMore: true});
                let reviews = await Service.listOrders(this.orders().length, [], [], this.state.day.clone().startOf('day'), this.state.day.clone().endOf('day'), true);
                this.setState({endReached: reviews.length < paginationRefreshLimit});
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({loadingMore: false});
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Orders"
                dark={this.state.endReached && !this.orders().length}>
                {this.renderSummery()}
                {
                    Platform.OS == 'web'
                        ? <DividerView/>
                        : <View style={{height: 10, backgroundColor: colorStyles.LIGHT_GREY}}/>
                }
                {
                    this.state.endReached && !this.orders().length
                        ? this.renderNoOrders()
                        : this.renderOrders()
                }
            </SingleLayout>
        );
    };

    renderOrders = () => {
        return (
            <FlatList
                data={this.orders()}
                style={{backgroundColor: colorStyles.primaryBackground}}
                keyExtractor={item => item.getId()}
                renderItem={item => (
                    <OrderRow
                        order={item.item}
                        onPress={() => this.props.navigation.navigate('Order', {id: item.item.getId()})}
                        merchant={true}
                        last={this.props.orders.length - 1 == item.index}/>
                )}
                onEndReached={this.loadMore}
                onEndReachedThreshold={10}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={!this.state.endReached ? <ActivityIndicator style={{margin: 30}}/> : null}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };

    renderSummery = () => {
        return (
            <View style={{backgroundColor: colorStyles.primaryBackground}}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 25}}>
                    <View>
                        <TouchableOpacity
                            style={{flex: 1, justifyContent: 'center', width: 30}}
                            onPress={() => this.setState({day: this.state.day.clone().subtract(1, "days")})}>
                            <Ionicons
                                name="ios-arrow-back"
                                size={25}
                                style={{color: colorStyles.BLUE}}/>
                        </TouchableOpacity>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={[textStyles.secondaryHeadline, {marginLeft: 10, textAlign: "center"}]}>
                            Orders for {this.state.day.startOf('day').calendar(null, {
                            lastDay: '[Yesterday]',
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            lastWeek: '[last] dddd',
                            nextWeek: 'dddd',
                            sameElse: 'L'
                        })}
                        </Text>
                        <Text style={[textStyles.secondarySubTitle, {marginTop: 15, textAlign: "center"}]}>
                            {
                                formatMoney(this.orders()
                                    .map(e => e.getCart()!.getTotal())
                                    .reduce((a, b) => a + b, 0))
                            } sales from {this.orders().length} orders.
                        </Text>
                    </View>
                    <View>
                        <TouchableOpacity
                            disabled={this.state.day.diff(moment(), 'days') == 0}
                            style={{flex: 1, justifyContent: 'center', width: 30, alignItems: "flex-end"}}
                            onPress={() => this.setState({day: this.state.day.clone().add(1, "days")})}>
                            <Ionicons
                                name="ios-arrow-forward"
                                size={25}
                                style={{color: this.state.day.diff(moment(), 'days') == 0 ? colorStyles.ALPHA_BLUE : colorStyles.BLUE}}/>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    };

    renderNoOrders = () => {
        let date = this.state.day.startOf('day').calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L'
        });
        return (
            <ScrollView
                contentContainerStyle={{flex: 1}}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                <MessageView
                    title={`No orders ${date}`}
                    subTitle=""
                    icon="md-list-box"/>
            </ScrollView>
        )
    };
})

