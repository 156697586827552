import {Dimensions, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import React, {Component} from "react";
import Modal from "react-native-modal";
import {colorStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";

interface Props {
}

interface State {
    open: boolean
    render: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            render: false,
        }
    }

    open = () => {
        this.setState({open: true, render: true})
    };

    close = () => {
        this.setState({open: false})
    };

    isOpen = (): boolean => {
        return this.state.open
    };

    render() {
        if (!this.state.render) {
            return null
        }
        return (
            <Modal
                isVisible={this.state.open}
                propagateSwipe={true}
                animationIn="slideInRight"
                animationOut="slideOutRight"
                animationInTiming={600}
                animationOutTiming={600}
                onModalHide={() => this.setState({render: false})}
                onBackdropPress={this.close}
                    onSwipeComplete={this.close}
                    swipeDirection={["right"]}
                    style={styles.modal}>
                    <TouchableOpacity onPress={this.close}>
                        <Ionicons name="md-close" size={34} style={styles.closeButton}/>
                    </TouchableOpacity>
                    <View style={styles.container}>
                        {this.props.children}
                    </View>
                </Modal>
        )
    };
};

const styles = StyleSheet.create({
    modal: {
        flex: 1,
        margin: 0,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    container: {
        maxWidth: Platform.OS == 'web' ? "80%" : Dimensions.get("screen").width,
    },
    closeButton: {
        zIndex: 1,
        position: "relative",
        top: 15,
        right: 20,
        color: colorStyles.WHITE,
    }
});