import Constants from "expo-constants";
import {Platform} from "react-native";
import * as Permissions from 'expo-permissions';
import * as Notifications from 'expo-notifications';
import {GrpcClient, paginationRefreshLimit} from "./GrpcClient";
import {Device} from "@emreat/proto/backend/v1/devices_pb";
import {CreateDeviceRequest} from "@emreat/proto/backend/v1/devices_pb";
import {DeviceService} from "@emreat/proto/backend/v1/devices_pb_service";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {GetMerchantRequest} from "@emreat/proto/backend/v1/merchants_pb";
import {Merchant} from "@emreat/proto/backend/v1/merchants_pb";
import {GatewayService} from "@emreat/proto/backend/v1/gateway_pb_service";
import {getStore} from "./reducers/reducers";
import {Review, ReviewOrderBy} from "@emreat/proto/backend/v1/reviews_pb";
import {ListReviewsRequest, ListReviewsResponse} from "@emreat/proto/backend/v1/reviews_pb";
import {ReviewService} from "@emreat/proto/backend/v1/reviews_pb_service";
import {Location, LocationOrderBy} from "@emreat/proto/backend/v1/locations_pb";
import {ListLocationsRequest, ListLocationsResponse} from "@emreat/proto/backend/v1/locations_pb";
import {LocationService} from "@emreat/proto/backend/v1/locations_pb_service";
import {
    Order,
    OrderStatus,
    OrderOrderBy,
    UpdateOrderRequest,
    OrderStatusMap,
    OrderUpdateMask
} from "@emreat/proto/backend/v1/orders_pb";
import {
    CreateOrderRequest,
    ListOrdersRequest,
    ListOrdersResponse
} from "@emreat/proto/backend/v1/orders_pb";
import {OrderService} from "@emreat/proto/backend/v1/orders_pb_service";
import {CreateCartRequest, GetCartRequest} from "@emreat/proto/backend/v1/carts_pb";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {CartService} from "@emreat/proto/backend/v1/carts_pb_service";
import {Token} from "@emreat/proto/backend/v1/tokens_pb";
import {ValidateTokenRequest} from "@emreat/proto/backend/v1/tokens_pb";
import {TokenService} from "@emreat/proto/backend/v1/tokens_pb_service";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {GetUserRequest} from "@emreat/proto/backend/v1/users_pb";
import {UserService} from "@emreat/proto/backend/v1/users_pb_service";
import {
    CartItem,
    CartItemUpdateMask,
    CartItemUpdateMaskMap,
    CartModifier
} from "@emreat/proto/backend/v1/cart_items_pb";
import {CartItemService} from "@emreat/proto/backend/v1/cart_items_pb_service";
import {CreateCartItemRequest, UpdateCartItemRequest} from "@emreat/proto/backend/v1/cart_items_pb";
import {Moment} from "moment";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {TimestampRange} from "@emreat/proto/backend/v1/common_pb";
import {Balance, BalanceOrderBy} from "@emreat/proto/backend/v1/balances_pb";
import {ListBalancesRequest, ListBalancesResponse} from "@emreat/proto/backend/v1/balances_pb";
import {BalanceService} from "@emreat/proto/backend/v1/balances_pb_service";
import {Posting, PostingOrderBy} from "@emreat/proto/backend/v1/postings_pb";
import {ListPostingsRequest, ListPostingsResponse} from "@emreat/proto/backend/v1/postings_pb";
import {PostingService} from "@emreat/proto/backend/v1/postings_pb_service";

export const Service = {
    init: async () => {
        await Service.initToken(await AsyncStorage.getItem("X_AUTH_TOKEN"));
        await Service.initMerchant();
        await Service.initCart(await AsyncStorage.getItem("CART_ID"));
        await Service.listReviews(0);
        Service.registerForPushNotifications().catch(() => console.log("failed to register for push notifications"));
    },

    initMerchant: async () => {
        let req = new GetMerchantRequest();
        req.setId(Constants.manifest.extra.merchantId);
        let merchant: Merchant = await GrpcClient.invoke(GatewayService.GetMerchant, req);
        getStore.dispatch({type: "SET_MERCHANT", merchant: merchant})
    },

    initCart: async (cartId: string | null) => {
        if (cartId) {
            try {
                await Service.getCart(cartId);
                if (!getStore.getState().customer.orders.map(e => e.getCartId()).includes(cartId)) {
                    return
                }
            } catch (e) {
                if (!["ERROR_CART_DOES_NOT_EXIST", "ERROR_UNAUTHENTICATED", "ERROR_TOKEN_DOES_NOT_EXIST"].includes(e.toString())) {
                    throw e
                }
            }
        }
        getStore.dispatch({type: "SET_CART", cart: null});
        await AsyncStorage.removeItem("CART_ID")
    },

    initToken: async (xAuthToken: string | null) => {
        if (xAuthToken) {
            try {
                // Set token.
                let token = await Service.validateToken(xAuthToken);
                token.setAccessSecret(xAuthToken);
                getStore.dispatch({type: "SET_TOKEN", token: token});
                await AsyncStorage.setItem("X_AUTH_TOKEN", xAuthToken);

                // Get user.
                let user = await Service.getUser(token.getUserId());
                getStore.dispatch({type: "SET_USER", user: user});

                // Get customer resources.
                await Promise.all([
                    Service.listLocations(0),
                    Service.listOrders(0, [], [], null, null)
                ]);

                return
            } catch (e) {
                if (!["ERROR_TOKEN_DOES_NOT_EXIST"].includes(e.toString())) {
                    throw e
                }
            }
        }

        getStore.dispatch({type: "SET_TOKEN", token: null});
        getStore.dispatch({type: "SET_USER", user: null});
        await AsyncStorage.removeItem("X_AUTH_TOKEN")
    },

    validateToken: async (xAuthToken: string): Promise<Token> => {
        let req = new ValidateTokenRequest();
        req.setAccessSecret(xAuthToken);
        return await GrpcClient.invoke(TokenService.ValidateToken, req);
    },

    getUser: async (id: string): Promise<User> => {
        let req = new GetUserRequest();
        req.setId(id);
        return await GrpcClient.invokeWithAuth(UserService.GetUser, req);
    },

    getCart: async (id: string) => {
        let req = new GetCartRequest();
        req.setId(id);
        let resp: Cart = await GrpcClient.invokeWithAuth(GatewayService.GetCart, req);
        getStore.dispatch({type: "SET_CART", cart: resp});
    },

    createCart: async (): Promise<Cart> => {
        let cart = new Cart();
        cart.setUserId(getStore.getState().auth.user ? getStore.getState().auth.user!.getId() : "");
        cart.setMerchantId(Constants.manifest.extra.merchantId);
        cart.setStoreId(getStore.getState().merchant.stores[0]!.getId());

        let req = new CreateCartRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setCart(cart);

        let resp: Cart = await GrpcClient.invokeWithAuth(CartService.CreateCart, req);
        getStore.dispatch({type: "SET_CART", cart: resp});
        await AsyncStorage.setItem("CART_ID", resp.getId());
        return resp
    },

    updateOrder: async (id: string, status: OrderStatusMap[keyof OrderStatusMap]): Promise<Order> => {
        let order =  getStore.getState().dashboard.orders.filter(e => e.getId() == id)[0]
        order.setStatus(status)
        order.setStatus(status);

        let req = new UpdateOrderRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setOrder(order);
        req.addUpdateMasks(OrderUpdateMask.ORDER_UPDATE_MASK_STATUS)

        await GrpcClient.invokeWithAuth(OrderService.UpdateOrder, req);
        getStore.dispatch({type: "SET_MERCHANT_ORDERS", orders: [order]});
        return order
    },

    listOrders: async (offset: number, cartIds: Array<string>, paymentIds: Array<string>, fromDate: Moment | null, toDate: Moment | null, merchant: boolean = false): Promise<Array<Order>> => {
        let req = new ListOrdersRequest();
        req.setCartIdsList(cartIds);
        req.setPaymentIdsList(paymentIds);
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addOrderBy(OrderOrderBy.ORDER_ORDER_BY_CREATE_TIMESTAMP_DESC);
        if (!merchant) {
            req.addUserIds(getStore.getState().auth.user!.getId());
        } else {
            req.addMerchantIds(Constants.manifest.extra.merchantId);
        }

        if (fromDate || toDate) {
            let range = new TimestampRange();
            if (fromDate) {
                let from = new Timestamp();
                from.fromDate(fromDate.toDate());
                range.setFrom(from);
            }
            if (toDate) {
                let to = new Timestamp();
                to.fromDate(toDate.toDate());
                range.setTo(to);
            }
            req.setCreateTimestamp(range);
        }

        let resp: ListOrdersResponse = await GrpcClient.invokeWithAuth(GatewayService.ListCheckouts, req);
        if (!merchant) {
            getStore.dispatch({type: "SET_ORDERS", orders: resp.getOrdersList()});
        } else {
            getStore.dispatch({type: "SET_MERCHANT_ORDERS", orders: resp.getOrdersList()});
        }
        return resp.getOrdersList();
    },

    createOrder: async (paymentId: string): Promise<Order> => {
        let order = new Order();
        order.setPaymentId(paymentId);

        let req = new CreateOrderRequest();
        req.setRequestId(paymentId);
        req.setOrder(order);

        let resp: Order = await GrpcClient.invokeWithAuth(OrderService.CreateOrder, req);
        await Service.listOrders(0, [resp.getCartId()], [], null, null);
        return resp
    },

    listLocations: async (offset: number = 0): Promise<Array<Location>> => {
        let req = new ListLocationsRequest();
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addOrderBy(LocationOrderBy.LOCATION_ORDER_BY_CREATE_TIMESTAMP_DESC);
        req.addUserIds(getStore.getState().auth.user!.getId());
        let resp: ListLocationsResponse = await GrpcClient.invokeWithAuth(LocationService.ListLocations, req);
        getStore.dispatch({type: "SET_LOCATIONS", locations: resp.getLocationsList()});
        return resp.getLocationsList();
    },

    listReviews: async (offset: number = 0): Promise<Array<Review>> => {
        let req = new ListReviewsRequest();
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addOrderBy(ReviewOrderBy.REVIEW_ORDER_BY_CREATE_TIMESTAMP_DESC);
        req.addMerchantIds(Constants.manifest.extra.merchantId);

        let resp: ListReviewsResponse = await GrpcClient.invokeWithAuth(ReviewService.ListReviews, req);
        getStore.dispatch({type: "SET_REVIEWS", reviews: resp.getReviewsList()});
        return resp.getReviewsList();
    },

    cartItemModifiersEqual: (modifiers1: Array<CartModifier>, modifiers2: Array<CartModifier>): boolean => {
        if (modifiers1.length != modifiers2.length) {
            return false
        }
        return modifiers1.map(e => modifiers2
            .map(b => e.getQuantity() == b.getQuantity() && e.getModifierId() == b.getModifierId())
            .some(e => e)).every(e => e);
    },
    CreateOrUpdateCartItem: async (
        productId: string,
        productGroupId: string,
        variationId: string,
        quantity: number,
        modifiers: Array<CartModifier>
    ): Promise<CartItem> => {
        if (!getStore.getState().customer.cart) {
            await Service.createCart();
        }

        let equals = getStore.getState().customer.cart!.getItemsList()
            .filter(e => e.getVariationId() == variationId && Service.cartItemModifiersEqual(e.getCartModifiersList(), modifiers));
        if (equals.length >= 1) {
            return await Service.updateCartItem(
                equals[0].getId(),
                variationId,
                equals[0].getQuantity() + quantity,
                modifiers,
            )
        } else {
            return await Service.createCartItem(
                productId,
                productGroupId,
                variationId,
                quantity,
                modifiers,
            )
        }
    },
    createCartItem: async (
        productId: string,
        productGroupId: string,
        variationId: string,
        quantity: number,
        modifiers: Array<CartModifier>
    ): Promise<CartItem> => {
        let cartItem = new CartItem();
        cartItem.setUserId(getStore.getState().auth.user ? getStore.getState().auth.user!.getId() : "");
        cartItem.setMerchantId(Constants.manifest.extra.merchantId);
        cartItem.setStoreId(getStore.getState().merchant.stores[0].getId());
        cartItem.setCartId(getStore.getState().customer.cart!.getId());

        cartItem.setProductId(productId);
        cartItem.setProductGroupId(productGroupId);
        cartItem.setVariationId(variationId);
        cartItem.setQuantity(quantity);
        cartItem.setCartModifiersList(modifiers);

        let req = new CreateCartItemRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setCartitem(cartItem);
        let resp: CartItem = await GrpcClient.invokeWithAuth(CartItemService.CreateCartItem, req);
        await Service.getCart(resp.getCartId());
        return resp
    },
    updateCartItem: async (
        id: string,
        variationId: string,
        quantity: number,
        modifiers: Array<CartModifier>,
    ): Promise<CartItem> => {
        let existingCartItem = getStore.getState().customer.cart!.getItemsList().filter(e => e.getId() == id)[0];
        let updateMasks: Array<CartItemUpdateMaskMap[keyof CartItemUpdateMaskMap]> = [];

        let cartItem = new CartItem();
        cartItem.setId(id);
        if (quantity != existingCartItem.getQuantity()) {
            cartItem.setQuantity(quantity);
            updateMasks.push(CartItemUpdateMask.CART_ITEM_UPDATE_MASK_QUANTITY);
        }
        if (variationId != existingCartItem.getVariationId()) {
            cartItem.setVariationId(variationId);
            updateMasks.push(CartItemUpdateMask.CART_ITEM_UPDATE_MASK_VARIATION_ID);
        }
        if (!Service.cartItemModifiersEqual(existingCartItem.getCartModifiersList(), modifiers)) {
            cartItem.setCartModifiersList(modifiers);
            updateMasks.push(CartItemUpdateMask.CART_ITEM_UPDATE_MASK_CART_MODIFIERS);
        }

        let req = new UpdateCartItemRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setCartitem(cartItem);
        req.setUpdateMasksList(updateMasks);
        let resp: CartItem = await GrpcClient.invokeWithAuth(CartItemService.UpdateCartItem, req);
        await Service.getCart(resp.getCartId());
        return resp
    },
    orderAgain: async (cartItems: Array<CartItem>) => {
        for (let i = 0; i < cartItems.length; i++) {
            await Service.CreateOrUpdateCartItem(
                cartItems[i].getProductId(),
                cartItems[i].getProductGroupId(),
                cartItems[i].getVariationId(),
                cartItems[i].getQuantity(),
                cartItems[i].getCartModifiersList(),
            );
        }
    },

    listBalances: async (offset: number = 0): Promise<Array<Balance>> => {
        let req = new ListBalancesRequest();
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addOrderBy(BalanceOrderBy.BALANCE_ORDER_BY_CREATE_TIMESTAMP_DESC);
        req.addMerchantIds(Constants.manifest.extra.merchantId);
        let resp: ListBalancesResponse = await GrpcClient.invokeWithAuth(BalanceService.ListBalances, req);
        getStore.dispatch({type: "SET_MERCHANT_BALANCES", balances: resp.getBalancesList()});
        return resp.getBalancesList();
    },

    listPostings: async (offset: number = 0, balanceId: string): Promise<Array<Posting>> => {
        let req = new ListPostingsRequest();
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addOrderBy(PostingOrderBy.POSTING_ORDER_BY_CREATE_TIMESTAMP_DESC);
        req.addBalanceIds(balanceId);
        let resp: ListPostingsResponse = await GrpcClient.invokeWithAuth(PostingService.ListPostings, req);
        getStore.dispatch({type: "SET_MERCHANT_POSTINGS", postings: resp.getPostingsList()});
        return resp.getPostingsList();
    },
    registerForPushNotifications: async () => {
        let token = await Service.getPushNotificationTokens();
        if (token) {
            let device = new Device();
            device.setPushNotificationToken(token);
            let req = new CreateDeviceRequest();
            req.setRequestId(GrpcClient.newUuidV4());
            req.setDevice(device);
            try {
                await GrpcClient.invoke(DeviceService.CreateDevice, req);
            } catch (e) {
                if (!["ERROR_DEVICE_ALREADY_EXISTS"].includes(e.toString())) {
                    throw e
                }
            }
        }
    },

    getPushNotificationTokens: async () => {
        let token = "";
        if (Constants.isDevice && ["ios", "android"].includes(Platform.OS)) {
            const {status: existingStatus} = await Permissions.getAsync(Permissions.NOTIFICATIONS);
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const {status} = await Permissions.askAsync(Permissions.NOTIFICATIONS);
                finalStatus = status;
            }
            if (finalStatus === 'granted') {
                token = (await Notifications.getExpoPushTokenAsync()).data;
            }
        }

        if (Platform.OS === 'android') {
            await Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }
        return token
    },
};