import {FlatList, Text, TouchableOpacity, View} from "react-native";
import * as React from "react";
import {Component} from "react";
import {RootState} from "../../reducers/reducers";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import CheckoutButton from "../../common/buttons/CheckoutButton";
import SingleLayout from "../../common/layouts/SingleLayout";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import ProductRow from "../../components/menu/ProductRow";
import SearchBarView from "../../common/components/SearchBarView";
import MessageView from "../../common/views/MessageView";

let mapStateToProps = (state: RootState) => ({
    stores: state.merchant.stores,
    productGroups: state.merchant.productGroups,
    products: state.merchant.products,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Search'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Products'>

    stores: Array<Store>
    productGroups: Array<ProductGroup>
    products: Array<Product>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let productGroups = props.productGroups.filter(e => props.stores[0].getProductGroupIdsList().includes(e.getId()));
    return <Screen
        navigation={props.navigation}
        products={props.products}
        productGroups={productGroups}/>
})


interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList, 'Search'>

    productGroups: Array<ProductGroup>
    products: Array<Product>
}

interface State {
    search: string
}

interface Data {
    type: "PRODUCT_GROUP" | "PRODUCT"
    data: ProductGroup | Product,
    productGroupId: string
}

class Screen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            search: '',
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Search',
            headerStyle: {
                shadowColor: 'transparent',
            },
        });
    };

    getSearchResults = (): Array<Data> => {
        let data: Array<Data> = [];
        this.props.productGroups
            .forEach(p => {
                let products = this.props.products
                    .filter(e => p.getProductIdsList().includes(e.getId()))
                    .filter(y => y.getTitle().toLowerCase().includes(this.state.search.toLowerCase()));

                if (products.length) {
                    data.push({type: "PRODUCT_GROUP", data: p, productGroupId: p.getId()});
                    products.forEach(e => data.push({type: "PRODUCT", data: e, productGroupId: p.getId()}));
                }
            });
        return data;
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation}>
                <SearchBarView value={this.state.search} onChange={value => this.setState({search: value})}/>
                {
                    this.getSearchResults().length == 0
                        ? <MessageView title="That's not on the menu" subTitle="Try something else?" icon="md-search"/>
                        : this.renderSearchResults()
                }
                <CheckoutButton/>
            </SingleLayout>
        )
    };

    renderSearchResults = () => {
        let data = this.getSearchResults();

        let stickyIndies: Array<number> = [];
        data.forEach((d, i) => {
            if (d.type == "PRODUCT_GROUP") stickyIndies.push(i)
        });

        return (
            <FlatList
                data={data}
                showsVerticalScrollIndicator={false}
                stickyHeaderIndices={stickyIndies}
                initialNumToRender={50}
                keyExtractor={item => item.data.getId() + item.productGroupId}
                renderItem={item => {
                    switch (item.item.type) {
                        case "PRODUCT_GROUP":
                            return this.renderProductGroupHeader(item.item.data as ProductGroup);
                        case "PRODUCT":
                            return <ProductRow
                                product={item.item.data as Product}
                                productGroupId={item.item.productGroupId}
                                last={item.index == data.length - 1}/>
                    }
                }}/>
        )
    };

    renderProductGroupHeader = (productGroup: ProductGroup) => {
        return (
            <TouchableOpacity
                onPress={() => this.props.navigation.navigate("Products", {productGroupId: productGroup.getId()})}>
                <View style={[
                    containerStyles.paddingRowMedium, containerStyles.borderBottom,
                    {backgroundColor: colorStyles.secondaryBackground},
                ]}>
                    <Text style={textStyles.primaryTitle}>{productGroup.getTitle()}</Text>
                </View>
            </TouchableOpacity>
        )
    };
}