import * as React from "react";
import {Text} from "react-native";
import {getStore} from "../../reducers/reducers";

interface Props {
    value: number
}

export default (props: Props) => {
    if (props.value < 0) {
        return <Text>-{formatMoney(props.value)}</Text>
    } else {
        return <Text>{formatMoney(props.value)}</Text>
    }
};

export function formatMoney(value: number) {
    let d = getStore.getState().merchant!.denominationSymbol;
    return `${d}${(Math.abs(value) / 100).toFixed(2)}`;
}