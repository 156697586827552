import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {ActivityIndicator, Keyboard, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {getStore, RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {ValidationsUtils} from "../../common/fields/Validations";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {CreateVariationRequest, UpdateVariationRequest} from "@emreat/proto/backend/v1/variations_pb";
import {
    Variation,
    VariationUpdateMask,
    VariationUpdateMaskMap
} from "@emreat/proto/backend/v1/variations_pb";
import Constants from "expo-constants";
import NameField from "../../common/fields/Name";
import {VariationService} from "@emreat/proto/backend/v1/variations_pb_service";
import {Service} from "../../Service";
import MoneyField from "../../common/fields/Money";
import {Ionicons} from "@expo/vector-icons";
import ModifierGroupSimpleRow from "../../components/dashboard/VariationLinkRow";
import Picker from "react-native-picker-select";
import SelectorField from "../../common/fields/SelectorField";
import {VariationLinkService} from "@emreat/proto/backend/v1/variation_links_pb_service";
import {VariationLink} from "@emreat/proto/backend/v1/variation_links_pb";
import {CreateVariationLinkRequest} from "@emreat/proto/backend/v1/variation_links_pb";
import ProductLinkRow from "../../components/dashboard/ProductLinkRow";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    variations: state.merchant.products.map(e => e.getVariationsList()).flat(1)
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'Variation'>
    route: RouteProp<DashboardStackParamList, 'Variation'>

    variations: Array<Variation>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let variation = props.variations.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !variation) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} variation={variation} productId={props.route.params.productId}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    variation: Variation | null
    productId?: string
}

interface State {
    title: string
    basePrice: string

    titleError: string
    basePriceError: string

    submitting: boolean
    selectedModifierGroupId: string
    addingModifierGroup: boolean
}

class Screen extends Component<Props, State> {

    titleRef: RefObject<TextInput>;
    basePriceRef: RefObject<TextInput>;
    modifierGroupRef: RefObject<Picker>;

    constructor(props: Props) {
        super(props);
        this.state = {
            title: this.props.variation ? this.props.variation.getTitle() : "",
            basePrice: this.props.variation ? (this.props.variation.getBasePrice() / 100).toFixed(2) : "0.00",

            titleError: '',
            basePriceError: '',

            submitting: false,
            selectedModifierGroupId: "",
            addingModifierGroup: false,
        };
        this.titleRef = createRef();
        this.basePriceRef = React.createRef();
        this.modifierGroupRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.variation) {
            this.props.navigation.setOptions({
                title: 'Edit Variation',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Variation',
            })
        }
    };

    onAddModifierGroup = async () => {
        this.setState({addingModifierGroup: true});
        try {
            await this.createVariationLink(this.props.variation!.getId(), this.state.selectedModifierGroupId)
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({addingModifierGroup: false});
    };

    createVariationLink = async (variationId: string, modifierGroupId: string): Promise<VariationLink> => {
        let variationLink = new VariationLink();
        variationLink.setVariationId(variationId);
        variationLink.setModifierGroupId(modifierGroupId);
        variationLink.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateVariationLinkRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setVariationLink(variationLink);
        let resp: VariationLink = await GrpcClient.invokeWithAuth(VariationLinkService.CreateVariationLink, req);
        await Service.initMerchant();
        return resp
    };

    isValid = (): boolean => {
        if (ValidationsUtils.title(this.state.title) != "") {
            return false
        }
        if (ValidationsUtils.money(this.state.basePrice) != "") {
            return false
        }

        if (this.props.variation) {
            return this.state.title != this.props.variation.getTitle() ||
                parseFloat(this.state.basePrice) * 100 != this.props.variation.getBasePrice();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.variation) {
                await this.updateVariation()
            } else {
                await this.createVariation()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createVariation = async () => {
        let variation = new Variation();
        variation.setTitle(this.state.title);
        variation.setBasePrice(parseFloat(this.state.basePrice) * 100);
        variation.setProductId(this.props.productId!);
        variation.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateVariationRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setVariation(variation);
        await GrpcClient.invokeWithAuth(VariationService.CreateVariation, req);
    };

    updateVariation = async () => {
        let updateMasks: Array<VariationUpdateMaskMap[keyof VariationUpdateMaskMap]> = [];
        let variation = new Variation();
        variation.setId(this.props.variation!.getId());
        if (this.state.title != this.props.variation!.getTitle()) {
            variation.setTitle(this.state.title);
            updateMasks.push(VariationUpdateMask.VARIATION_UPDATE_MASK_TITLE)
        }
        if (parseFloat(this.state.basePrice) * 100 != this.props.variation!.getBasePrice()) {
            variation.setBasePrice(parseFloat(this.state.basePrice) * 100);
            updateMasks.push(VariationUpdateMask.VARIATION_UPDATE_MASK_BASE_PRICE)
        }
        let req = new UpdateVariationRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setVariation(variation);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(VariationService.UpdateVariation, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the variation information.
                </Text>

                <DividerView/>

                <NameField
                    forwardRef={this.titleRef}
                    error={Boolean(this.state.titleError)}
                    label="Title"
                    placeholder="Title"
                    value={this.state.title}
                    onChangeText={e => this.setState({
                        title: e,
                        titleError: ValidationsUtils.title(e)
                    })}
                    onSubmitEditing={() => this.basePriceRef.current!.focus()}/>

                <MoneyField
                    forwardRef={this.basePriceRef}
                    placeholder="Price"
                    value={this.state.basePrice}
                    label={"Price"}

                    error={Boolean(this.state.basePriceError)}
                    onChangeText={e => this.setState({basePrice: e, basePriceError: ValidationsUtils.money(e)})}
                    onSubmitEditing={() => this.basePriceRef.current!.blur()}/>

                <FieldErrors errors={[this.state.titleError, this.state.basePriceError]}/>

                {this.renderModifierGroups()}
            </ScrollView>
        )
    };

    renderModifierGroups = () => {
        if (!this.props.variation) {
            return null
        } else {
            return (
                <View>
                    <View style={{height: 0, overflow: "hidden"}}>
                        <SelectorField
                            forwardRef={this.modifierGroupRef}
                            value={this.state.selectedModifierGroupId}
                            label="Max Choice"
                            doneText={"Done"}
                            items={getStore.getState().merchant.modifierGroups
                                .filter(e => !this.props.variation!.getModifierGroupIdsList().includes(e.getId()))
                                .map(e => ({value: e.getId(), label: e.getTitle()}))}
                            error={false}
                            onChange={e => this.setState({selectedModifierGroupId: e})}
                            onUp={Keyboard.dismiss}
                            onDown={this.onAddModifierGroup}/>
                    </View>
                    <View style={[styles.infoContainer, {marginTop: 0}]}>
                        <View style={{flex: 1, justifyContent: "center"}}>
                            <Text style={[textStyles.secondarySubTitle, {textAlign: "center"}]}>
                                Add or remove a modifier groups.
                            </Text>
                        </View>
                        <View style={{width: 30}}>
                        {
                            this.state.addingModifierGroup
                                ? <ActivityIndicator color={colorStyles.BLUE} size={28}/>
                                : (
                                    <TouchableOpacity onPress={() => this.modifierGroupRef.current!.togglePicker()}>
                                        <Ionicons name="md-add" size={28} color={colorStyles.BLUE}/>
                                    </TouchableOpacity>
                                )
                        }
                        </View>
                    </View>
                    {getStore.getState().merchant.modifierGroups
                        .filter(e => this.props.variation!.getModifierGroupIdsList().includes(e.getId()))
                        .map(modifierGroup => {
                            return <ModifierGroupSimpleRow
                                key={modifierGroup.getId()}
                                variationId={this.props.variation!.getId()}
                                modifierGroup={modifierGroup}/>
                        })}
                </View>
            )
        }
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: "center",
        flexDirection: "row",
    },
});