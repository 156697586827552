import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {getStore} from "../../reducers/reducers";
import {ModifierGroupService} from "@emreat/proto/backend/v1/modifier_groups_pb_service";
import {DeleteModifierGroupRequest} from "@emreat/proto/backend/v1/modifier_groups_pb";

interface Props {
    modifierGroup: ModifierGroup
    onPressEdit: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteModifierGroup(this.props.modifierGroup.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteModifierGroup = async (id: string) => {
        let req = new DeleteModifierGroupRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(ModifierGroupService.DeleteModifierGroup, req);
        getStore.dispatch({type: "REMOVE_MODIFIER_GROUPS", modifierGroups: [this.props.modifierGroup]});
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}
                onPressUpdate={this.props.onPressEdit}>
                <View style={{backgroundColor: colorStyles.primaryBackground}}>
                    {this.renderButton()}
                </View>
            </SwipableButton>
        )
    }

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPressEdit()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderArrow()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.primaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.modifierGroup.getTitle()}
                </Text>
                {
                    this.props.modifierGroup.getSubTitle()
                        ? <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                            {this.props.modifierGroup.getSubTitle()}
                        </Text>
                        : null
                }
            </View>
        )
    };

    renderArrow = () => {
        return (
            <View style={{paddingLeft: 10, paddingRight: 5}}>
                <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED}}/>
            </View>
        )
    };
};