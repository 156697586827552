import * as React from "react";
import {Component, RefObject} from "react";
import {StyleSheet, Text, TextInput, View} from "react-native";
import {containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import {Password, PasswordUpdateMask} from "@emreat/proto/backend/v1/passwords_pb";
import {UpdatePasswordRequest} from "@emreat/proto/backend/v1/passwords_pb";
import {GrpcClient} from "../../GrpcClient";
import {PasswordService} from "@emreat/proto/backend/v1/passwords_pb_service";
import PasswordField from "../../common/fields/Password";
import FieldErrors from "../../common/fields/Errors";
import SubmitButton from "../../common/buttons/SubmitButton";
import DividerView from "../../common/views/DividerView";
import ScrollView from "../../common/views/ScrollView";
import SingleLayout from "../../common/layouts/SingleLayout";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList, 'Reset'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<AuthNavigatorParamList, 'Reset'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (props.user) {
        props.navigation.navigate('Home', {screen: 'Info'});
        return null
    }

    if (!props.route.params?.passwordId || !props.route.params?.token) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen
        navigation={props.navigation}
        passwordId={props.route.params.passwordId}
        token={props.route.params.token}/>
})

interface Props {
    navigation: StackNavigationProp<AuthNavigatorParamList>
    passwordId: string
    token: string
}

interface State {
    secret: string
    secretError: string
    submitting: boolean
}

class Screen extends Component<Props, State> {

    passwordRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            secret: "",
            secretError: "",
            submitting: false,
        };
        this.passwordRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Reset your password',
        })
    };

    isValid = (): boolean => {
        return ValidationsUtils.password(this.state.secret) == "";

    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.reset();
            this.setState({submitting: false});
            this.props.navigation.navigate('Login', {})
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    reset = async () => {
        let password = new Password();
        password.setId(this.props.passwordId);
        password.setSecret(this.state.secret);

        let req = new UpdatePasswordRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setPassword(password);
        req.addUpdateMasks(PasswordUpdateMask.PASSWORD_UPDATE_MASK_SECRET);
        await GrpcClient.invokeWithToken(PasswordService.UpdatePassword, req, this.props.token);
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Reset your password"
                subTitle="Please enter a new password."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <View style={{flex: 1}}>
                <ScrollView>

                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                        Choose a new password. One that you'll remember.
                    </Text>

                    <DividerView/>

                    <PasswordField
                        forwardRef={this.passwordRef}
                        returnKeyType="done"
                        error={Boolean(this.state.secretError)}
                        value={this.state.secret}
                        onChangeText={e => this.setState({secret: e, secretError: ValidationsUtils.password(e)})}
                        onSubmitEditing={() => this.passwordRef.current!.blur()}/>

                    <FieldErrors errors={[this.state.secretError]}/>
                </ScrollView>

                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Once you've chosen a new password, you'll be able to log into your account.
                </Text>

                <View style={containerStyles.paddingRowMediumBottom}>
                    <SubmitButton
                        text="Change password"
                        inActive={!this.isValid() || this.state.submitting}
                        submitting={this.state.submitting}
                        onPress={this.onSubmit}
                        disabled={!this.isValid()}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});
