import * as React from "react";
import {Component, RefObject} from "react";
import {StyleSheet, Text, TextInput, TouchableWithoutFeedback, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import {CodeRequest, LoginRequest, LoginResponse} from "@emreat/proto/backend/v1/authentication_pb";
import Constants from "expo-constants";
import {GrpcClient} from "../../GrpcClient";
import {AuthenticationService} from "@emreat/proto/backend/v1/authentication_pb_service";
import {Service} from "../../Service";
import {GetUserRequest} from "@emreat/proto/backend/v1/users_pb";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {UserService} from "@emreat/proto/backend/v1/users_pb_service";
import ScrollView from "../../common/views/ScrollView";
import Divider from "../../common/views/DividerView";
import CodeField from "../../common/fields/Code";
import FieldErrors from "../../common/fields/Errors";
import SubmitButton from "../../common/buttons/SubmitButton";
import SingleLayout from "../../common/layouts/SingleLayout";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList, 'Verification'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<AuthNavigatorParamList, 'Verification'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (props.user) {
        props.navigation.navigate('Home', {screen: 'Info'});
        return null
    }

    if (!props.route.params?.emailAddress) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} emailAddress={props.route.params.emailAddress}/>
})

interface Props {
    navigation: StackNavigationProp<AuthNavigatorParamList>
    emailAddress: string
}

interface State {
    code: string
    codeError: string
    submitting: boolean
}

class Screen extends Component<Props, State> {

    codeRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            code: "",
            codeError: "",
            submitting: false,
        };
        this.codeRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Verification',
        })
    };

    isValid = (): boolean => {
        return ValidationsUtils.code(this.state.code) == "";
    };

    onRequestRequestReset = async () => {
        try {
            await this.requestCode();
            toastRef.current!.show("Code sent to your inbox!");
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
    };

    requestCode = async () => {
        let req = new CodeRequest();
        req.setEmailAddress(this.props.emailAddress.toLowerCase());
        req.setMerchantId(Constants.manifest.extra.merchantId);
        await GrpcClient.invoke(AuthenticationService.RequestCode, req);
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            let [token, passwordId] = await this.verify();
            this.setState({submitting: false});
            this.props.navigation.navigate('Reset', {token: token, passwordId: passwordId})
        } catch (e) {
            if (e.toString() == "ERROR_CODE_SECRET_IS_INCORRECT") {
                toastRef.current!.show("You have entered an invalid code!");
            } else if (e.toString() == "ERROR_CODE_HAS_EXPIRED") {
                toastRef.current!.show("You have entered an expired code!");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false})
        }
    };

    verify = async (): Promise<[string, string]> => {
        let auth = await this.getAuth();
        let passwordId = await this.getUserPasswordId(auth.getUserId(), auth.getTokenSecret());
        return [auth.getTokenSecret(), passwordId]
    };

    getAuth = async (): Promise<LoginResponse> => {
        let req = new LoginRequest();
        req.setEmailAddress(this.props.emailAddress.toLowerCase());
        req.setCodeSecret(this.state.code);
        req.setMerchantId(Constants.manifest.extra.merchantId);
        req.setPushNotificationToken(await Service.getPushNotificationTokens());
        return await GrpcClient.invoke(AuthenticationService.Login, req);
    };

    getUserPasswordId = async (userId: string, xAuthToken: string): Promise<string> => {
        let req = new GetUserRequest();
        req.setId(userId);
        let resp: User = await GrpcClient.invokeWithToken(UserService.GetUser, req, xAuthToken);
        return resp.getPasswordId()
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Verification"
                subTitle="Please enter the 6-digit code that was sent to you."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <View style={{flex: 1}}>
                <ScrollView>

                    <TouchableWithoutFeedback onPress={this.onRequestRequestReset}>
                        <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                            Did not receive a code? <Text style={{color: colorStyles.RED}}>Resend</Text>
                        </Text>
                    </TouchableWithoutFeedback>

                    <Divider/>

                    <CodeField
                        forwardRef={this.codeRef}
                        returnKeyType="done"
                        error={this.state.codeError != ""}
                        value={this.state.code}
                        onChangeText={e => this.setState({code: e, codeError: ValidationsUtils.code(e)})}
                        onSubmitEditing={() => this.codeRef.current!.blur()}/>

                    <FieldErrors errors={[this.state.codeError]}/>
                </ScrollView>

                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the one-time code from your inbox, if the code matches, {'\n'} you may reset your password.
                </Text>

                <View style={containerStyles.paddingRowMediumBottom}>
                    <SubmitButton
                        text="Verify"
                        inActive={!this.isValid() || this.state.submitting}
                        submitting={this.state.submitting}
                        onPress={this.onSubmit}
                        disabled={!this.isValid()}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});
