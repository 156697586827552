import * as React from "react";
import {Component} from "react";
import {StyleSheet, Switch, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp} from "@react-navigation/native";
import {getStore, RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import ScrollView from "../../common/views/ScrollView";
import Constants from "expo-constants";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import HeaderButton from "../../common/buttons/HeaderButton";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {GrpcClient} from "../../GrpcClient";
import {Store, StoreUpdateMask, UpdateStoreRequest} from "@emreat/proto/backend/v1/stores_pb";
import {StoreService} from "@emreat/proto/backend/v1/stores_pb_service";
import {Service} from "../../Service";
import {toastRef} from "../../common/views/ToastView";

let mapStateToProps = (state: RootState) => ({
    title: state.merchant.title,
    emailAddress: state.merchant.emailAddress,
    store: state.merchant.stores[0],
});

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<DashboardStackParamList, 'Home'>, StackNavigationProp<RootNavigatorParamList>>

    title: string
    emailAddress: string
    store: Store
}

export default connect(mapStateToProps)(class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Settings',
            headerLeft: () => (
                <HeaderButton left={true} onPress={() => this.props.navigation.popToTop()} icon="ios-arrow-back"/>
            ),
        })
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Settings"
                subTitle="Manage your store."
                dark>
                {this.renderContent()}
            </SingleLayout>
        )
    };

    renderContent = () => {
        return (
            <ScrollView>
                <View style={styles.iconContainer}>
                    <MaterialIcons name="store" size={65} color={colorStyles.LIGHT_TEXT}/>
                    <Text style={[textStyles.primaryTitle, {marginTop: 5}]}>{this.props.title}</Text>
                    <Text style={[textStyles.secondarySubTitle, {marginTop: 5}]}>{this.props.emailAddress}</Text>

                    <View style={{marginTop: 20}}/>

                    <Switch value={this.props.store.getActive()} onValueChange={async () => {
                        try {
                            let store = new Store();
                            store.setId(this.props.store.getId());
                            store.setActive(!this.props.store.getActive());

                            let req = new UpdateStoreRequest();
                            req.setRequestId(GrpcClient.newUuidV4());
                            req.setStore(store);
                            req.addUpdateMasks(StoreUpdateMask.STORE_UPDATE_MASK_ACTIVE);
                            await GrpcClient.invokeWithAuth(StoreService.UpdateStore, req);
                            await Service.initMerchant();
                        } catch (e) {
                            toastRef.current!.show(e.toString());
                            toastRef.current!.show("Oops something went wrong!");
                        }
                    }}>
                    </Switch>
                </View>

                <DividerView/>

                {this.renderButton('Product Groups', () => this.props.navigation.navigate('ProductGroups', {}))}

                {this.renderButton('Products', () => this.props.navigation.navigate('Products', {}))}

                {this.renderButton('Modifier Groups', () => this.props.navigation.navigate('ModifierGroups', {}))}

                <View style={{marginTop: 20}}/>

                {this.renderButton('Images', () => this.props.navigation.navigate('Images', {}))}

                <View style={{marginTop: 20}}/>

                {this.renderButton('Hours', () => this.props.navigation.navigate('Hours', {}))}

                <Text style={[textStyles.secondarySubTitle, {margin: 20, textAlign: 'center'}]}>
                    Version v{Constants.manifest.version}
                </Text>
            </ScrollView>
        )
    };

    renderButton = (title: string, onPress: () => void) => {
        return (
            <View style={[containerStyles.paddingRowSmall, containerStyles.borderBottom, styles.buttonContainer]}>
                <TouchableOpacity onPress={onPress} style={{flexDirection: 'row'}}>
                    <View style={{flex: 1, justifyContent: 'center'}}>
                        <Text style={textStyles.secondaryTitle}>{title}</Text>
                    </View>
                    <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED, marginLeft: 10}}/>
                </TouchableOpacity>
            </View>
        )
    };
})

const styles = StyleSheet.create({
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 25,
        marginTop: 40,
    },
    buttonContainer: {
        backgroundColor: colorStyles.primaryBackground,
        justifyContent: 'center',
        textAlign: 'left',
    }
});
