import {FlatList, RefreshControl} from "react-native";
import * as React from "react";
import {Component} from "react";
import {RootState} from "../../reducers/reducers";
import {StackNavigationProp} from "@react-navigation/stack";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {Service} from "../../Service";
import {toastRef} from "../../common/views/ToastView";
import SingleLayout from "../../common/layouts/SingleLayout";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import HeaderButton from "../../common/buttons/HeaderButton";
import ProductGroupRow from "../../components/menu/ProductGroupRow";

let mapStateToProps = (state: RootState) => ({
    productGroups: state.merchant.productGroups,
});

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList, 'ProductGroups'>
    route: RouteProp<DashboardStackParamList, 'ProductGroups'>

    productGroups: Array<ProductGroup>
}

interface State {
    refreshing: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Product Groups",
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.props.navigation.navigate("ProductGroup", {})}
                    icon="md-add"/>
            ),
        });
    };

    refresh = async () => {
        this.setState({refreshing: true});
        try {
            await Service.initMerchant();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation}>
                {this.renderProductGroups()}
            </SingleLayout>
        )
    };

    renderProductGroups = () => {
        return (
            <FlatList
                showsVerticalScrollIndicator={false}
                data={this.props.productGroups}
                keyExtractor={item => item.getId()}
                initialNumToRender={500}
                renderItem={item => (
                    <ProductGroupRow
                        productGroup={item.item}
                        onPress={() => this.props.navigation.navigate("ProductGroup", {id: item.item.getId()})}
                        onPressEdit={() => this.props.navigation.navigate("ProductGroup", {id: item.item.getId()})}
                        last={item.index == this.props.productGroups.length - 1}/>
                )}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };
});