import {Component, default as React, RefObject} from "react";
import CenterModal from "../../common/modals/CenterModal";
import BottomModal from "../../common/modals/BottomModal";
import {RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import {connect} from "react-redux";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import {CartItem} from "@emreat/proto/backend/v1/cart_items_pb";
import CartItemForm from "./CartItemForm";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
});

interface Props {
    screenSize: ScreenSize
    forwardRef: RefObject<CenterModal | BottomModal>
    product: Product
    productGroupId: string
    cartItem?: CartItem
}

export default connect(mapStateToProps)(class extends Component<Props> {

    render() {
        if (this.props.screenSize == 'SMALL') {
            return (
                <BottomModal ref={this.props.forwardRef as RefObject<BottomModal>}>
                    <CartItemForm
                        cartItem={this.props.cartItem}
                        product={this.props.product}
                        productGroupId={this.props.productGroupId}
                        onSubmit={() => this.props.forwardRef.current!.close()}/>
                </BottomModal>
            )
        } else {
            return (
                <CenterModal ref={this.props.forwardRef as RefObject<CenterModal>} dismiss={true}>
                    <CartItemForm
                        cartItem={this.props.cartItem}
                        product={this.props.product}
                        productGroupId={this.props.productGroupId}
                        onSubmit={() => this.props.forwardRef.current!.close()}/>
                </CenterModal>
            )
        }
    }
})