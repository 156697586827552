import {Picker as PickerNative, Platform, StyleSheet, Text, TouchableWithoutFeedback, View} from "react-native";
import React, {Component, RefObject} from "react";
import RNPickerSelect from 'react-native-picker-select';
import Picker, {Item} from 'react-native-picker-select';
import {colorStyles, containerStyles, textStyles} from "../../Styles";

interface Props {
    forwardRef: RefObject<Picker>
    label: string
    placeholder?: string
    items: Array<Item>
    doneText?: string
    error: boolean

    value: any,
    onChange: (value: any) => void
    onUp: () => void
    onDown: () => void
}

export default class extends Component<Props> {

    componentDidMount(): void {
        if (this.props.placeholder && !this.props.value) {
            return
        }
        if (!this.props.items.map(e => e.value).includes(this.props.value)) {
            this.props.onChange(this.props.items[0].value)
        }
    }

    render() {
        if (Platform.OS == 'web') {
            return this.renderRow();
        } else {
            return (
                <TouchableWithoutFeedback
                    onPress={() => this.props.forwardRef.current!.togglePicker(true)}>
                    {this.renderRow()}
                </TouchableWithoutFeedback>
            )
        }
    };

    renderRow = () => {
        return (
            <View style={[
                {backgroundColor: colorStyles.primaryBackground, flexDirection: "row", alignItems: "center"},
                containerStyles.borderBottom, containerStyles.paddingRowMedium,
                {height: 50},
                this.props.error ? {borderBottomColor: colorStyles.error} : {}
            ]}>
                {this.renderLabel()}
                {this.renderTextInput()}
            </View>
        )
    };

    renderLabel = () => {
        return (
            <Text style={[
                textStyles.secondaryTitle,
                this.props.error ? {color: colorStyles.error} : {},
                {width: 100},
            ]}>
                {this.props.label}
            </Text>
        )
    };

    renderTextInput = () => {
        if (Platform.OS == 'web') {
            return (
                <View style={{flex: 1, justifyContent: "center"}}>
                    <PickerNative
                        selectedValue={this.props.value}
                        onValueChange={e => this.props.onChange(e)}
                        style={{
                            borderWidth: 0,
                            backgroundColor: "transparent",
                            marginLeft: -5, ...StyleSheet.flatten(textStyles.secondarySubTitle),
                        }}>
                        {this.props.items.map(e => (
                            <PickerNative.Item key={e.value} label={e.label} value={e.value} />
                        ))}
                    </PickerNative>
                </View>
            )
        }
        return (
            <View style={{flex: 1, justifyContent: "center"}}>
                <RNPickerSelect
                    ref={this.props.forwardRef}
                    doneText={this.props.doneText ? this.props.doneText : "Next"}
                    value={this.props.value}
                    useNativeAndroidPickerStyle={false}
                    style={{
                        inputIOS: {...styles.picker, ...textStyles.secondarySubTitle},
                        inputAndroid: {...styles.picker, ...textStyles.secondarySubTitle},
                        inputWeb: {
                            borderWidth: 0,
                            backgroundColor: "transparent",
                            marginLeft: -5, ...StyleSheet.flatten(textStyles.secondarySubTitle)
                        },
                    }}
                    onValueChange={this.props.onChange}
                    placeholder={!this.props.placeholder ? {} : {label: this.props.placeholder}}
                    items={this.props.items}
                    onUpArrow={this.props.onUp}
                    onDownArrow={this.props.onDown}
                    onDonePress={this.props.onDown}
                />
            </View>
        );
    };
};

const styles = StyleSheet.create({
    picker: {
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
    }
});