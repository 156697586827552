import React from "react";
import {StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native";
import StarRatingButton from "../buttons/StarRatingButton";
import {colorStyles, textStyles} from "../../Styles";

interface Props {
    score: number
    style?: StyleProp<ViewStyle>
}

export default (props: Props) => {
    return (
        <View style={[{
            flexDirection: 'row',
            marginTop: 10,
            alignItems: "center"
        }, StyleSheet.flatten(props.style)]}>
            <StarRatingButton
                disabled={true}
                maxStars={6}
                rating={props.score / 100}
                starColor={colorStyles.RED}
                starSize={15}
                starStyle={{alignSelf: "center"}}
                onChange={() => null}
            />
            <Text style={textStyles.secondarySubTitle}>{(props.score / 100).toFixed(1)}/6</Text>
        </View>
    )
}