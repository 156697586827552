import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {FontAwesome, Ionicons} from "@expo/vector-icons";
import {Modifier} from "@emreat/proto/backend/v1/modifiers_pb";
import {RootState} from "../../reducers/reducers";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import {connect} from "react-redux";
import MoneyView from "../../common/views/MoneyView";

let mapStateToProps = (state: RootState) => ({
    modifierGroups: state.merchant.modifierGroups,
});

interface Props {
    modifierGroups: Array<ModifierGroup>

    modifier: Modifier
    quantity: number
    onPress: () => void
    onPressDelete: () => void
}

export default connect(mapStateToProps)(class extends Component<Props> {

    modifierGroup = (): ModifierGroup => {
        return this.props.modifierGroups.filter(e => e.getId() == this.props.modifier.getModifierGroupId())[0]
    };

    render() {
        return (
            <TouchableOpacity onPress={this.props.onPress}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 25},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                {this.renderTitle()}
                {this.renderSubTitle()}
            </View>
        )
    };

    renderTitle = () => {
        return (
            <View style={{flexDirection: "row", alignItems: "center"}}>
                {this.renderQuantity()}
                <Text style={[
                    textStyles.primaryTitle,
                    {
                        flex: 1,
                        color: this.props.quantity ? colorStyles.GREEN : colorStyles.DARK_TEXT,
                        textTransform: 'capitalize'
                    }
                ]}>
                    {this.props.modifier.getTitle()}
                </Text>
            </View>
        )
    };

    renderQuantity = () => {
        if (!this.props.quantity) {
            return null
        }
        return (
            <View>
                {
                    this.props.quantity > 1
                        ? <Text style={[textStyles.primaryTitle, {color: colorStyles.GREEN}]}>
                            {this.props.quantity}
                        </Text>
                        : null
                }
                <FontAwesome name="circle" size={12} style={{color: colorStyles.GREEN, marginRight: 10}}/>
            </View>
        )
    };

    renderSubTitle = () => {
        if (!this.props.modifier.getSubTitle()) {
            return null
        }
        return <Text style={textStyles.primarySubTitle}>{this.props.modifier.getSubTitle()}</Text>
    };

    renderRightColumn = () => {
        return (
            <View style={{minWidth: 25, flexDirection: "row"}}>
                {this.renderAdditionalPrice()}
                {this.renderDelete()}
            </View>
        )
    };

    renderAdditionalPrice = () => {
        if (!this.props.modifier.getAdditionalPrice()) {
            return null
        }
        return (
            <Text
                style={[textStyles.primarySubTitle, this.props.quantity ? {color: colorStyles.GREEN} : {}]}>
                <MoneyView value={this.props.modifier.getAdditionalPrice()}/>
            </Text>
        )
    };

    renderDelete = () => {
        if (!this.props.quantity) {
            return null
        }
        if (this.modifierGroup().getMaximumChoice() == 1 || this.props.modifier.getMaximumChoice() == 1) {
            return null
        }
        return (
            <TouchableOpacity onPress={this.props.onPressDelete}>
                <Ionicons
                    name="md-trash" size={25}
                    style={{color: colorStyles.RED, marginLeft: 10, margin: -5}}/>
            </TouchableOpacity>
        )
    }
})