import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import React, {Component} from "react";
import Modal from "react-native-modal";
import {colorStyles, containerStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";

interface Props {
    dismiss?: boolean
}

interface State {
    open: boolean
    render: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            render: false,
        }
    }

    open = () => {
        this.setState({open: true, render: true})
    };

    close = () => {
        this.setState({open: false})
    };

    isOpen = (): boolean => {
        return this.state.open
    };

    render() {
        if (!this.state.render) {
            return null
        }
        return (
            <Modal
                isVisible={this.state.open}
                animationInTiming={500}
                animationOutTiming={500}
                onModalHide={() => this.setState({render: false})}
                onBackdropPress={() => this.props.dismiss && this.close()}
                onSwipeComplete={() => this.props.dismiss && this.close()}
                style={styles.modal}>
                <View style={[{padding: 30, maxHeight: "100%", maxWidth: 600, width: '100%'},]}>
                    {
                        this.props.dismiss
                            ? (<TouchableOpacity onPress={this.close}>
                                    <Ionicons name="md-close" size={34} style={styles.closeButton}/>
                                </TouchableOpacity>
                            ) : null
                    }
                    <View style={[styles.container, containerStyles.border]}>
                        {this.props.children}
                    </View>
                </View>
            </Modal>
        )
    };
};

const styles = StyleSheet.create({
    modal: {
        justifyContent: 'center',
        alignItems: "center",
        margin: 0,
    },
    container: {
        flex: Platform.OS == 'web' ? 1 : 0,
        backgroundColor: colorStyles.WHITE,
    },
    closeButton: {
        zIndex: 1,
        position: "relative",
        top: -5,
        right: 10,
        alignSelf: 'flex-end',
        color: colorStyles.WHITE,
    }
});