import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
}

export default React.memo(function PostcodeField(props: Props) {
    return (
        <Field
            maxLength={32}
            clearTextOnFocus={false}
            placeholder={"Postcode"}
            keyboardType={"default"}
            autoCapitalize={"characters"}
            autoCorrect={true}
            autoCompleteType={"postal-code"}
            multiline={false}
            {...props}

            label={"Postcode"}
            error={props.error}
            showSecureTextEntry={false}/>
    )
});