import {Component, default as React} from "react";
import {StyleSheet, Text, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";

interface Props {
    isValid: boolean
    error: boolean
}

export default class extends Component<Props> {

    render() {
        return (
            <View style={[
                containerStyles.paddingRowMedium,
                {
                    flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 25,
                    backgroundColor: colorStyles.secondaryBackground
                },
                this.props.error ? styles.errorContainer : {},
            ]}>
                {this.renderTitle()}
                {this.renderInstruction()}
            </View>
        )
    };

    renderTitle = () => {
        return (
            <View style={{flex: 1, flexDirection: "row", alignItems: 'center'}}>
                {
                    this.props.error
                        ? <Ionicons name="md-alert" size={20} style={{color: colorStyles.RED, marginRight: 7}}/>
                        : null
                }
                <Text style={[textStyles.primaryTitle, this.props.error ? {color: colorStyles.RED} : {}]}>
                    Choose one
                </Text>
            </View>
        )
    };

    renderInstruction = () => {
        if (this.props.isValid) {
            return <Ionicons name="md-checkmark" size={20} style={{color: colorStyles.GREEN}}/>
        }
        return (
            <Text style={[
                textStyles.primarySubTitle,
                this.props.error ? {color: colorStyles.RED} : {},
            ]}>
                Required
            </Text>
        )
    }
}

const styles = StyleSheet.create({
    errorContainer: {
        backgroundColor: colorStyles.LIGHT_GREY,
        borderBottomColor: colorStyles.RED,
        borderBottomWidth: 2
    },
});