import {mergeArrays, removeFromArray} from "./common";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import {Posting} from "@emreat/proto/backend/v1/postings_pb";
import {Balance} from "@emreat/proto/backend/v1/balances_pb";
import {Image} from "@emreat/proto/backend/v1/images_pb";

export interface DashboardState {
    balances: Array<Balance>
    postings: Array<Posting>
    orders: Array<Order>
    images: Array<Image>
}

export type DashboardAction = {
    readonly type: "SET_MERCHANT_BALANCES",
    readonly balances: Array<Balance>,
} | {
    readonly type: "SET_MERCHANT_POSTINGS",
    readonly postings: Array<Posting>,
} | {
    readonly type: "SET_MERCHANT_ORDERS",
    readonly orders: Array<Order>,
} | {
    readonly type: "SET_MERCHANT_IMAGES",
    readonly images: Array<Image>,
} | {
    readonly type: "REMOVE_MERCHANT_IMAGES",
    readonly images: Array<Image>,
}

const initialState: DashboardState = {
    balances: [],
    postings: [],
    orders: [],
    images: [],
};

export function dashboard(state: DashboardState = initialState, action: DashboardAction): DashboardState {
    switch (action.type) {
        case "SET_MERCHANT_BALANCES":
            return {...state, balances: mergeArrays(state.balances, action.balances)};
        case "SET_MERCHANT_POSTINGS":
            return {...state, postings: mergeArrays(state.postings, action.postings)};
        case "SET_MERCHANT_ORDERS":
            return {...state, orders: mergeArrays(state.orders, action.orders)};
        case "SET_MERCHANT_IMAGES":
            return {...state, images: mergeArrays(state.images, action.images)};
        case "REMOVE_MERCHANT_IMAGES":
            return {...state, images: removeFromArray(state.images, action.images)};
        default:
            return state;
    }
}