import {FlatList, RefreshControl, Text, View} from "react-native";
import * as React from "react";
import {Component} from "react";
import {RootState} from "../../reducers/reducers";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {containerStyles, textStyles} from "../../Styles";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import {Service} from "../../Service";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import NotFoundScreen from "../NotFoundScreen";
import {toastRef} from "../../common/views/ToastView";
import AllergyView from "../../common/components/AllergyView";
import HeaderButton from "../../common/buttons/HeaderButton";
import ProductRow from "../../components/menu/ProductRow";
import SingleLayout from "../../common/layouts/SingleLayout";
import CheckoutButton from "../../common/buttons/CheckoutButton";
import ScreenLayout from "../../common/layouts/ScreenLayout";

let mapStateToProps = (state: RootState) => ({
    productGroups: state.merchant.productGroups,
    products: state.merchant.products,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Products'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Products'>

    productGroups: Array<ProductGroup>
    products: Array<Product>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let productGroup = props.productGroups.filter(e => e.getId() == props.route.params!.productGroupId)[0];
    if (!productGroup) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen
        navigation={props.navigation}
        products={props.products.filter(e => productGroup.getProductIdsList().includes(e.getId()))}
        productGroup={productGroup}/>
})


interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>

    products: Array<Product>
    productGroup: ProductGroup
}

interface State {
    refreshing: boolean
}

class Screen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: this.props.productGroup.getTitle(),
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.props.navigation.navigate("Search", {})}
                    icon="md-search"/>
            ),
        });
    };

    refresh = async () => {
        this.setState({refreshing: true});
        try {
            await Service.initMerchant();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation}>
                {this.renderProductGroups()}
                <CheckoutButton/>
            </SingleLayout>
        )
    };

    renderProductGroups = () => {
        return (
            <FlatList
                ListHeaderComponent={this.renderHeader}
                showsVerticalScrollIndicator={false}
                data={this.props.products}
                keyExtractor={item => item.getId()}
                renderItem={item => (
                    <ProductRow
                        product={item.item}
                        productGroupId={this.props.productGroup.getId()}
                        last={item.index == this.props.products.length - 1}/>
                )}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };

    renderHeader = () => {
        return (
            <View>
                <View style={[containerStyles.paddingRowMedium, containerStyles.borderBottom]}>
                    <Text style={[textStyles.secondaryHeadline, {textTransform: 'capitalize'}]}>
                        {this.props.productGroup.getTitle()}
                    </Text>
                    {
                        this.props.productGroup.getSubTitle()
                            ? (
                                <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                                    {this.props.productGroup.getSubTitle()}
                                </Text>
                            ) : null
                    }
                </View>
                <AllergyView/>
            </View>
        )
    }
};