import {Component, default as React} from "react";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {ScreenSize} from "../../reducers/common";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import DividerView from "../../common/views/DividerView";
import ProductRow from "./ProductRow";
import Constants from "expo-constants";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    products: state.merchant.products,
});

interface Props {
    screenSize: ScreenSize
    products: Array<Product>

    productGroup: ProductGroup
    onPress: () => void
    last?: boolean
}

interface State {
    productsVisible: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            productsVisible: props.screenSize == 'LARGE' || !Constants.manifest.extra.showCategory,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!Constants.manifest.extra.showCategory) {
            return
        }
        if (prevProps.screenSize == 'LARGE' && this.props.screenSize != 'LARGE') {
            this.setState({productsVisible: false})
        }
        if (prevProps.screenSize != 'LARGE' && this.props.screenSize == 'LARGE') {
            this.setState({productsVisible: true})
        }
    }

    render() {
        return (
            <View style={[
                containerStyles.paddingRowLargeBottom,
                this.props.screenSize == 'LARGE' && this.props.last ? {minHeight: "calc(100vh - 60px)"} : {}
            ]}>
                <TouchableOpacity onPress={() => this.setState({productsVisible: !this.state.productsVisible})}>
                    <View style={[
                        {flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 15},
                    ]}>
                        {this.renderLeftColumn()}
                        {this.renderArrow()}
                    </View>
                </TouchableOpacity>
                {!this.state.productsVisible ? <DividerView/> : this.renderProducts()}
            </View>

        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1, paddingRight: 5, paddingLeft: 5}}>
                <Text style={[textStyles.primaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.productGroup.getTitle()}
                </Text>
                {
                    this.props.productGroup.getSubTitle()
                        ? <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                            {this.props.productGroup.getSubTitle()}
                        </Text>
                        : null
                }
            </View>
        )
    };

    renderArrow = () => {
        return (
            <View style={{paddingLeft: 10, paddingRight: 5}}>
                {
                    this.state.productsVisible
                        ? <Ionicons name="ios-arrow-up" size={22} style={{color: colorStyles.BLUE}}/>
                        : <Ionicons name="ios-arrow-down" size={22} style={{color: colorStyles.BLUE}}/>
                }
            </View>
        )
    };

    renderProducts = () => {
        let products = this.props.products
            .filter(e => this.props.productGroup.getProductIdsList().includes(e.getId()));

        return (
            <View style={[
                containerStyles.paddingContainer, containerStyles.border,
                {backgroundColor: colorStyles.primaryBackground},
            ]}>

                <View style={{paddingTop: 5, paddingBottom: 5}}>
                    {products.map((e, i) => (
                        <ProductRow
                            key={e.getId()}
                            product={e}
                            productGroupId={this.props.productGroup.getId()}
                            last={i == products.length - 1}/>
                    ))}
                </View>
            </View>
        )
    };
})