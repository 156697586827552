import React, {Component, RefObject} from "react";
import {ActivityIndicator, StyleSheet, TouchableOpacity, View} from "react-native";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import Swipeable from "react-native-gesture-handler/Swipeable";
import {colorStyles} from "../../Styles";

interface Props {
    forwardRef?: RefObject<Swipeable>
    onPressDelete?: () => void
    deleting?: boolean
    onPressUpdate?: () => void
}

export default class extends Component<Props> {

    render() {
        return (
            <Swipeable
                ref={this.props.forwardRef}
                renderRightActions={this.renderRightAction}
                overshootLeft={false}
                overshootRight={false}
                friction={2}>
                {this.props.children}
            </Swipeable>
        )
    };

    renderRightAction = () => {
        return (
            <View style={{flexDirection: "row", width: this.props.onPressUpdate && this.props.onPressDelete ? 180 : 90}}>
                {
                    this.props.onPressUpdate
                        ? <TouchableOpacity onPress={this.props.onPressUpdate} style={styles.update}>
                            <MaterialIcons name="edit" color={colorStyles.WHITE} size={26}/>
                        </TouchableOpacity>
                        : null
                }
                {
                    this.props.onPressDelete
                        ? <TouchableOpacity onPress={this.props.onPressDelete} style={styles.delete}>
                            {
                                this.props.deleting
                                    ? <ActivityIndicator color={colorStyles.WHITE} size="small" style={{flex: 1}}/>
                                    : <Ionicons name="md-trash" color={colorStyles.WHITE} size={26}/>
                            }

                        </TouchableOpacity>
                        : null
                }

            </View>
        )
    }
}

const styles = StyleSheet.create({
    delete: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: colorStyles.RED,
        flexDirection: "row",
        width: 90,
    },
    update: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: colorStyles.BLUE,
        flexDirection: "row",
        width: 90,
    },
});
