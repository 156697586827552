import * as React from "react";
import {Component} from "react";
import {Text, View} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, containerStyles, textStyles} from "../Styles";
import {HomeNavigatorParamList} from "../Navigator";
import SingleLayout from "../common/layouts/SingleLayout";
import SubmitButton from "../common/buttons/SubmitButton";

interface Props {
    navigation?: StackNavigationProp<HomeNavigatorParamList>
    onClickTryAgain: () => {}
    submitting: boolean
}

export default class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        if (this.props.navigation) {
            this.props.navigation.setOptions({
                title: '500 Internal Error',
            })
        }
    };

    render() {
        if (!this.props.navigation) {
            return this.renderNotFound()
        }
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderNotFound()}
            </SingleLayout>
        );
    };

    renderNotFound = () => {
        return (
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Ionicons name="md-alert" color={colorStyles.secondaryDarkText} size={65}/>
                <Text style={[textStyles.primaryTitle, {marginTop: 5}]}>Oops something went wrong!</Text>
                <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                    Please check your internet connection and try again.
                </Text>
                <View style={[containerStyles.paddingRowMediumTop, {paddingLeft: 25, paddingRight: 25, width: '100%', maxWidth: 500}]}>
                    <SubmitButton
                        text="Try again"
                        inActive={this.props.submitting}
                        submitting={this.props.submitting}
                        onPress={this.props.onClickTryAgain}
                        disabled={this.props.submitting}/>
                </View>
            </View>
        )
    }
};
