import React, {Component, RefObject} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {connect} from "react-redux";
import moment from "moment";
import {getStore, RootState} from "../../reducers/reducers";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import SubmitButton from "../buttons/SubmitButton";
import {dayOfTheWeekProtoToString, dayOfTheWeekToProto, dispatchMethodToName} from "../../Constants";
import CenterModal from "../modals/CenterModal";
import * as Linking from "expo-linking";
import CancelButton from "../buttons/CancelButton";

let mapStateToProps = (state: RootState) => ({
    store: state.merchant.stores[0],
});

interface Props {
    store: Store
}

interface State {
    dismissed: boolean
}

export default connect(mapStateToProps)(class ClosedView extends Component<Props, State> {

    interval: any;
    ref: RefObject<CenterModal>;

    constructor(props: Props) {
        super(props);
        this.state = {
            dismissed: false,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.displayModal();
        this.interval = setInterval(() => !this.ref.current!.isOpen() && this.displayModal(), 10000);
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (!prevState.dismissed && this.state.dismissed) {
            clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    displayModal = () => {
        let matchingTimes = this.props.store.getHoursList()
            .filter(e => e.getDaysOfTheWeekList().includes(dayOfTheWeekToProto[new Date().getDay()]))
            .filter(e => {
                let openTime = moment(`${new Date().getDay()} ${e.getFromHour()}:${e.getFromMinute()}`, "dddd HH:mm");
                let closeTime = moment(`${new Date().getDay()} ${e.getToHour()}:${e.getToMinute()}`, "dddd HH:mm");
                if (e.getFromHour() > e.getToHour()) {
                    closeTime.add(1, "day")
                }
                return moment().isBetween(openTime, closeTime)
            });

        if (matchingTimes.length == 0 || !this.props.store.getActive()) {
            setTimeout(() => this.ref.current!.open(), 1000);
        } else {
            this.ref.current!.close();
        }
    };

    getNextOpenTime = () => {
        let times = this.props.store.getHoursList().map(e => {
            return e.getDaysOfTheWeekList().map(d => {
                let m = moment(`${dayOfTheWeekProtoToString[d]}  ${e.getFromHour()}:${e.getFromMinute()}`, "dddd HH:mm");
                if (d < dayOfTheWeekToProto[moment().get("day")] || (d == dayOfTheWeekToProto[moment().get("day")] && m.isBefore(moment()))) {
                    m.add(1, "week")
                }
                return m
            })
        })
            .flat(1)
            .filter(e => e.isAfter(moment()))
            .sort((a, b) => a.diff(b));
        return times[0]
    };

    render() {
        if (this.props.store.getActive()) {
            return (
                <CenterModal ref={this.ref}>
                    <View>
                        <View style={[containerStyles.paddingRowMedium, {paddingLeft: 25, paddingRight: 25}]}>
                            <View style={{alignItems: 'center'}}>
                                <Text style={[textStyles.primaryTitle, {textAlign: "center"}]}>
                                    Restaurant opens
                                </Text>
                                <Text style={[textStyles.primaryTitle, {textAlign: "center", marginTop: 5}]}>
                                    {this.getNextOpenTime() ? this.getNextOpenTime().calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'}) : ""}
                                </Text>
                                <Text style={[textStyles.secondarySubTitle, {
                                    textAlign: "center",
                                    marginTop: 15,
                                    marginBottom: 15
                                }]}>
                                    Order now for {this.props.store.getDispatchMethodsList()
                                    .map(e => dispatchMethodToName[e.getDispatchMethod()])
                                    .join(", ").replace(/,(?=[^,]*$)/, ' or')} later.
                                </Text>
                            </View>

                            <View style={{width: "100%"}}>
                                <SubmitButton
                                    text="Order for later"
                                    onPress={() => {
                                        this.ref.current!.close();
                                        this.setState({dismissed: true});
                                    }}
                                    submitting={false}
                                    disabled={false}
                                    inActive={false}/>
                            </View>
                        </View>
                    </View>
                </CenterModal>
            )
        } else {
            let phoneNumber = getStore.getState().merchant.stores[0].getPhoneNumber();

            return (
                <CenterModal ref={this.ref}>
                    <View>
                        <View style={[containerStyles.paddingRowMedium, {paddingLeft: 25, paddingRight: 25}]}>
                            <View style={{alignItems: 'center'}}>
                                <Text style={[textStyles.primaryTitle, {textAlign: "center"}]}>
                                    Restaurant Closed
                                </Text>
                                <Text style={[textStyles.secondarySubTitle, {
                                    textAlign: "center",
                                    marginTop: 15,
                                    marginBottom: 15
                                }]}>
                                    If you have any question please phone the restaurant on

                                    <TouchableOpacity onPress={() => Linking.openURL(`tel:${phoneNumber}`)} style={{height: 14}}>
                                        <Text style={[textStyles.secondarySubTitle, {color: colorStyles.BLUE}]}> {phoneNumber}</Text>
                                    </TouchableOpacity>
                                    .
                                </Text>
                            </View>

                            <View style={{width: "100%"}}>
                                <CancelButton
                                    text="Close"
                                    onPress={() => {
                                        this.ref.current!.close();
                                        this.setState({dismissed: true});
                                    }}
                                    submitting={false}
                                    disabled={false}
                                    inActive={false}/>
                            </View>
                        </View>
                    </View>
                </CenterModal>
            )
        }

    }
});