import * as React from "react";
import {TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {MaterialIcons} from "@expo/vector-icons";

interface Props {
    value: string
    onChange: (value: string) => void
}

export default (props: Props) => {
    return (
        <View style={[
            containerStyles.paddingRowZero, containerStyles.borderBottom,
            {
                height: 50, flexDirection: 'row',
                alignItems: 'center', backgroundColor: colorStyles.primaryBackground
            },
        ]}>
            <MaterialIcons name="search" size={20} style={{
                color: colorStyles.secondaryDarkText, marginRight: 10,
            }}/>
            <TextInput
                value={props.value}
                placeholder="Search..."
                placeholderTextColor={colorStyles.secondaryDarkText}
                textAlignVertical="center"
                style={[textStyles.primarySubTitle, {flex: 1}]}
                enablesReturnKeyAutomatically={true}
                onChangeText={props.onChange}/>
            {
                props.value
                    ? (
                        <TouchableOpacity
                            style={{paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 10}}
                            onPress={() => props.onChange("")}>
                            <MaterialIcons
                                name="close"
                                size={20}
                                style={{color: colorStyles.secondaryDarkText,}}/>
                        </TouchableOpacity>
                    ) : null
            }
        </View>
    );
};
