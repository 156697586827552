import * as React from "react";
import {Component} from "react";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {getStore, RootState} from "../../reducers/reducers";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {View} from "react-native";
import {containerStyles} from "../../Styles";
import SubmitButton from "../../common/buttons/SubmitButton";
import SingleLayout from "../../common/layouts/SingleLayout";
import {User} from "@emreat/proto/backend/v1/users_pb";
import EditableCart from "../../components/checkout/EditableCart";
import ClosedView from "../../common/components/ClosedView";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    cart: state.customer.cart,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Cart'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Cart'>

    user: User | null
    cart: Cart | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    return <Screen navigation={props.navigation} user={props.user} cart={props.cart}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList>, StackNavigationProp<RootNavigatorParamList>>

    user: User | null
    cart: Cart | null
}

class Screen extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
        if (this.props.cart && !this.props.cart.getItemsList().length) {
            this.props.navigation.pop();
        }
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Your Order",
        });
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Your order"
                subTitle={"You're one step away!"}>
                {this.renderCart()}
            </SingleLayout>
        );
    };

    renderCart = () => {
        return (
            <View style={{flex: 1}}>
                <EditableCart cart={this.props.cart}/>
                <View style={containerStyles.paddingRowMedium}>
                    <ClosedView/>
                    <SubmitButton
                        text="Go to checkout"
                        onPress={() => {
                            if (!this.props.user) {
                                this.props.navigation.navigate('Auth', {})
                            } else {
                                this.props.navigation.navigate('Checkout', {})
                            }
                        }}
                        submitting={false}
                        disabled={!this.props.cart || !this.props.cart!.getItemsList().length || !getStore.getState().merchant.stores[0].getActive()}
                        inActive={!this.props.cart || !this.props.cart!.getItemsList().length || !getStore.getState().merchant.stores[0].getActive()}/>
                </View>
            </View>
        )
    }
};