import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {colorStyles, textStyles} from "../../Styles";

interface Props {
    errors: Array<string>
}

export default React.memo(function FieldErrors(props: Props) {
    return (
        <View style={styles.container}>
            {
                props.errors.filter(e => e != "").length
                    ? <Text style={[textStyles.secondarySubTitle, {color: colorStyles.error}]}>
                        {props.errors.filter(e => e != "")[0]}
                    </Text>
                    : null
            }
        </View>
    )
});

const styles = StyleSheet.create({
    container: {
        margin: 10,
        minHeight: 17,
        alignItems: "center"
    },
});
