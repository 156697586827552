import * as React from "react";
import {Component} from "react";
import {ActivityIndicator, FlatList, Platform, RefreshControl, ScrollView, Text, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {paginationRefreshLimit} from "../../GrpcClient";
import {Service} from "../../Service";
import {toastRef} from "../../common/views/ToastView";
import DividerView from "../../common/views/DividerView";
import {RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import SingleLayout from "../../common/layouts/SingleLayout";
import {formatMoney} from "../../common/views/MoneyView";
import MessageView from "../../common/views/MessageView";
import {Posting} from "@emreat/proto/backend/v1/postings_pb";
import {Balance} from "@emreat/proto/backend/v1/balances_pb";
import moment from "moment";
import {DashboardStackParamList} from "../../DashboardNavigator";
import HeaderButton from "../../common/buttons/HeaderButton";

let mapStateToProps = (state: RootState) => ({
    balances: state.dashboard.balances,
    postings: state.dashboard.postings,
});

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList, "Home">

    balances: Array<Balance>
    postings: Array<Posting>
}

interface State {
    refreshing: boolean
    loadingMore: boolean
    endReached: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            loadingMore: false,
            endReached: false,
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
        this.loadMore().catch()
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Transactions',
            headerLeft: () => (
                <HeaderButton left={true} onPress={() => this.props.navigation.popToTop()} icon="ios-arrow-back"/>
            ),
        })
    };

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await Service.listBalances(0);
                await Service.listPostings(0, this.props.balances[0].getId());
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    loadMore = async () => {
        try {
            if (!this.state.loadingMore && !this.state.endReached) {
                this.setState({loadingMore: true});
                if (!this.props.balances.length) {
                    await Service.listBalances(0);
                }
                let postings = await Service.listPostings(this.props.postings.length, this.props.balances[0].getId());
                this.setState({endReached: postings.length < paginationRefreshLimit});
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({loadingMore: false});
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Postings"
                dark={this.state.endReached && !this.props.postings.length}>
                {this.renderSummery()}
                {
                    Platform.OS == 'web'
                        ? <DividerView/>
                        : <View style={{height: 10, backgroundColor: colorStyles.LIGHT_GREY}}/>
                }
                {
                    this.state.endReached && !this.props.postings.length
                        ? this.renderNoPostings()
                        : this.renderPostings()
                }
            </SingleLayout>
        );
    };

    renderPostings = () => {
        return (
            <FlatList
                data={this.props.postings}
                style={{backgroundColor: colorStyles.primaryBackground}}
                keyExtractor={item => item.getId()}
                renderItem={item => this.renderPosting(item.item, this.props.postings.length - 1 == item.index)}
                onEndReached={this.loadMore}
                onEndReachedThreshold={10}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={!this.state.endReached ? <ActivityIndicator style={{margin: 30}}/> : null}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };

    renderPosting = (posting: Posting, last?: boolean) => {
        let time = moment(posting.getCreateTimestamp()!.toDate());
        return (
            <View style={[containerStyles.paddingRowMedium, last ? null : containerStyles.borderBottom]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={[textStyles.primaryTitle, {flex: 1}]}>
                        {
                            posting.getCreditorBalanceId() == this.props.balances[0].getId()
                                ? "+"
                                : "-"
                        }
                        {formatMoney(posting.getAmount())}
                    </Text>
                    <Text style={[textStyles.secondarySubTitle, {textAlign: "right", fontWeight: "500"}]}>
                        {time.calendar(null, {sameElse: 'MMMM Do YYYY, [at] h:mm A'})}
                    </Text>
                </View>
                <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>{posting.getReference()}</Text>
            </View>
        )
    };

    renderSummery = () => {
        return (
            <View style={[{
                justifyContent: 'center', alignItems: 'center', padding: 25,
                backgroundColor: colorStyles.primaryBackground
            }]}>
                <View style={{flexDirection: 'row', alignItems: "center"}}>
                    <Text style={[textStyles.secondaryHeadline, {marginLeft: 10, textAlign: "center"}]}>
                        {this.props.balances.length ? formatMoney(this.props.balances[0].getAmount()) : formatMoney(0)}
                    </Text>
                </View>
                <Text style={[textStyles.secondarySubTitle, {marginTop: 15, textAlign: "center"}]}>
                    Based on transactions as
                    of {this.props.balances.length
                    ? moment(this.props.balances[0].getUpdateTimestamp()!.toDate()).calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'})
                    : moment().calendar()}.
                </Text>
            </View>
        )
    };

    renderNoPostings = () => {
        return (
            <ScrollView
                contentContainerStyle={{flex: 1}}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                <MessageView
                    title="No transactions"
                    subTitle=""
                    icon="md-card"/>
            </ScrollView>
        )
    };
})

