import * as React from "react";
import {Component, RefObject} from "react";
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import HeaderButton from "../../common/buttons/HeaderButton";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import {LoginRequest, LoginResponse} from "@emreat/proto/backend/v1/authentication_pb";
import Constants from "expo-constants";
import {GrpcClient} from "../../GrpcClient";
import {AuthenticationService} from "@emreat/proto/backend/v1/authentication_pb_service";
import ScrollView from "../../common/views/ScrollView";
import DividerView from "../../common/views/DividerView";
import EmailField from "../../common/fields/Email";
import PasswordField from "../../common/fields/Password";
import FieldErrors from "../../common/fields/Errors";
import SubmitButton from "../../common/buttons/SubmitButton";
import {AuthNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {Service} from "../../Service";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import SingleLayout from "../../common/layouts/SingleLayout";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {User} from "@emreat/proto/backend/v1/users_pb";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList, 'Login'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<AuthNavigatorParamList, 'Login'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    return <Screen navigation={props.navigation}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList>, StackNavigationProp<RootNavigatorParamList>>
}

interface State {
    emailAddress: string
    secret: string

    emailAddressError: string,
    secretError: string,

    submitting: boolean
}

class Screen extends Component<Props, State> {

    emailAddressRef: RefObject<TextInput>;
    passwordRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            emailAddress: "",
            secret: "",

            emailAddressError: "",
            secretError: "",

            submitting: false,
        };
        this.emailAddressRef = React.createRef();
        this.passwordRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Welcome back!',
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
    };

    isValid = (): boolean => {
        if (ValidationsUtils.emailAddress(this.state.emailAddress) != "") {
            return false
        }
        return this.state.secret != "";
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.login();
            this.setState({submitting: false});
            this.props.navigation.navigate('Home', {})
        } catch (e) {
            if (e.toString() == "ERROR_AUTHENTICATION_USER_NOT_FOUND") {
                toastRef.current!.show("Account does not exist, please create a new account!");
            } else  if (e.toString() == "ERROR_PASSWORD_SECRET_IS_INCORRECT") {
                toastRef.current!.show("You have entered an invalid password!");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    login = async () => {
        let req = new LoginRequest();
        req.setEmailAddress(this.state.emailAddress.toLowerCase());
        req.setPasswordSecret(this.state.secret);
        req.setMerchantId(Constants.manifest.extra.merchantId);
        req.setPushNotificationToken(await Service.getPushNotificationTokens());
        let resp: LoginResponse = await GrpcClient.invoke(AuthenticationService.Login, req);
        await Service.initToken(resp.getTokenSecret());
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Welcome back!"
                subTitle="Sign in with your email address."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <View style={{flex: 1}}>
                <ScrollView>
                    <TouchableOpacity onPress={() => this.props.navigation.navigate('Register', {})}>
                        <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                            Not registered yet? <Text style={{color: colorStyles.RED}}>Create an account</Text>
                        </Text>
                    </TouchableOpacity>

                    <DividerView/>

                    <EmailField
                        forwardRef={this.emailAddressRef}
                        value={this.state.emailAddress}
                        onChangeText={e => this.setState({
                            emailAddress: e,
                            emailAddressError: ValidationsUtils.emailAddress(e)
                        })}
                        onSubmitEditing={() => this.passwordRef.current!.focus()}
                        error={Boolean(this.state.emailAddressError)}/>

                    <PasswordField
                        forwardRef={this.passwordRef}
                        returnKeyType="done"
                        value={this.state.secret}
                        onChangeText={e => this.setState({
                            secret: e,
                            secretError: e == "" ? "Password is a mandatory field" : ""
                        })}
                        onSubmitEditing={() => this.passwordRef.current!.blur()}
                        error={Boolean(this.state.secretError)}/>

                    {
                        Constants.manifest.extra.merchantId == "f704900c-465c-4c2b-b33b-528064072fe0"
                            ? (<Text style={[textStyles.secondarySubTitle, styles.infoContainer, {
                                maxWidth: 500,
                                alignSelf: "center",
                                fontWeight: "500"
                            }]}>
                                Note: If you are an existing user of the old village-kebab-house.com you must create a new
                                account.
                            </Text>)
                            : null
                    }


                    <FieldErrors errors={[this.state.emailAddressError, this.state.secretError]}/>

                </ScrollView>

                <TouchableOpacity onPress={() => this.props.navigation.navigate('Forgotten', {})}>
                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                        Need help signing in? <Text style={{color: colorStyles.RED}}>Reset your password</Text>
                    </Text>
                </TouchableOpacity>

                <View style={containerStyles.paddingRowMediumBottom}>
                    <SubmitButton
                        text="Log in"
                        inActive={!this.isValid() || this.state.submitting}
                        submitting={this.state.submitting}
                        onPress={this.onSubmit}
                        disabled={!this.isValid()}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});