export const ValidationsUtils = {
    notEmpty: (text: string) => {
        if (/^\s+$/.test(text)) {
            return 'Text is empty';
        }
    },

    money: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Price is a mandatory field';
        } else if (!/^\d+(\.\d{1,2})?$/.test(text)) {
            return 'Price must be of valid format';
        } else {
            return ''
        }
    },

    title: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Title is a mandatory field';
        } else {
            return ""
        }
    },
    subTitle: (text: string): string => {
        return ""
    },

    name: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Name is a mandatory field';
        } else if (!/^[a-zA-Z\s]*$/.test(text)) {
            return 'Name must contain only letters';
        } else {
            return ''
        }
    },

    phoneNumber: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Phone is a mandatory field';
        } else if (!/^[0-9]*$/.test(text)) {
            return 'Phone must contain only digits';
        } else if (text.length != 11) {
            return 'Phone must be 11 digits';
        } else {
            return ''
        }
    },

    password: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Password is a mandatory field';
        } else if (text.length < 8) {
            return 'Password must be at least 8 characters';
        } else if (text.length > 15) {
            return 'Password must be at most 15 characters';
            // } else if (/^[a-zA-Z0-9]*$/.test(text)) {
            //     return 'Must contain at least 1 special character.'
        } else if (!/.*[a-z].*/.test(text)) {
            return 'Password must contain at least 1 lower case character.'
        } else if (!/.*[A-Z].*/.test(text)) {
            return 'Password must contain at least 1 upper case character.'
        } else if (!/.*[0-9].*/.test(text)) {
            return 'Password must contain at least digit.'
        } else {
            return ''
        }
    },

    emailAddress: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Email is a mandatory field';
        } else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(text)) {
            return 'Email must be a valid email address'
        } else {
            return ''
        }
    },

    code: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Code is a mandatory field';
        } else if (text.length != 6) {
            return 'Code must be 6 digits';
        } else {
            return ''
        }
    },

    locationLineOne: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Line one is a mandatory field';
        } else {
            return ''
        }
    },
    locationLineTwo: (text: string): string => {
        return ''
    },
    locationCity: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'City is a mandatory field';
        } else {
            return ''
        }
    },
    locationPostcode: (text: string): string => {
        if (!text || ValidationsUtils.notEmpty(text)) {
            return 'Postcode is a mandatory field';
        } else {
            return ''
        }
    },
};
