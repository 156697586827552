import {Component, default as React} from "react";
import {Text, View} from "react-native";
import {containerStyles, textStyles} from "../../Styles";
import {Review} from "@emreat/proto/backend/v1/reviews_pb";
import moment from "moment";
import RatingView from "../../common/views/RatingView";

interface Props {
    review: Review
    last?: boolean
}

export default class extends Component<Props> {

    render() {
        return (
            <View style={[
                containerStyles.paddingRowMedium,
                this.props.last ? null : containerStyles.borderBottom,
            ]}>
                {this.renderRating()}
                {this.renderName()}
                {this.renderComment()}
            </View>
        )
    };

    renderRating = () => {
        return <RatingView style={{marginTop: 0, marginBottom: 10}} score={this.props.review.getAverageRating()}/>
    };

    renderName = () => {
        let time = moment(this.props.review.getCreateTimestamp()?.toDate());
        return (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={[textStyles.primaryTitle, {flex: 1}]}>{this.props.review.getCustomerName()}</Text>
                <Text style={[textStyles.secondarySubTitle, {textAlign: "right", fontWeight: "500"}]}>
                    {time.calendar(null, {sameElse: 'MMMM Do YYYY, [at] h:mm A'})}
                </Text>
            </View>
        )
    };

    renderComment = () => {
        if (!this.props.review.getCustomerComment()) {
            return null
        }
        return (
            // @ts-ignore
            <Text style={[textStyles.secondarySubTitle, {marginTop: 10, wordBreak: "break-word"}]}>
                {this.props.review.getCustomerComment().replace(/^\s+|\s+$/g, '')}
            </Text>
        )

    }
};