import {TextInput, TextInputProps} from "react-native";
import React, {PropsWithChildren, RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef?: RefObject<TextInput>
    error?: boolean
    label: string
}

export default (props: PropsWithChildren<Props>) => {
    return (
        <Field
            keyboardType="default"
            autoCapitalize="sentences"
            autoCorrect={true}
            multiline={true}
            scrollEnabled={true}
            {...props}

            label={props.label}
            error={props.error}
            showSecureTextEntry={false}>
            {props.children}
        </Field>
    )
};
