import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
    label?: string
}

export default function NameField(props: Props) {
    return (
        <Field
            placeholder={props.placeholder ? props.placeholder : "Name"}
            keyboardType="default"
            autoCapitalize="words"
            autoCompleteType="name"
            autoCorrect={false}
            maxLength={32}
            multiline={false}
            {...props}

            label={props.label ? props.label : "Name"}
            error={props.error}
            showSecureTextEntry={false}/>
    )
};
