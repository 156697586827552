import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList} from "../../Navigator";
import {dispatchMethodToName, gatewayToName} from "../../Constants";
import {formatMoney} from "../../common/views/MoneyView";
import RatingView from "../../common/views/RatingView";
import {useNavigation} from "@react-navigation/native";
import moment from "moment";

interface Props {
    navigation?: StackNavigationProp<HomeNavigatorParamList>
    order: Order
    onPress: () => void
    last?: boolean
    merchant?: boolean
}

export default class extends Component<Props> {

    render() {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPress()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center'},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        let quantity = this.props.order.getCart()!.getItemsList().map(e => e.getQuantity()).reduce((a, b) => a + b, 0);
        return (
            <View style={{flex: 1}}>
                {this.props.merchant ? this.renderDispatchTimestamp() : this.renderRating()}
                <Text style={textStyles.primaryTitle}>Order No. #{this.props.order.getReference()}</Text>
                <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                    {
                        [
                            dispatchMethodToName[this.props.order.getCart()!.getDispatchMethod()],
                            gatewayToName[this.props.order.getPayment()!.getGateway()],
                            formatMoney(this.props.order.getCart()!.getTotal()),
                            `${quantity} ${quantity > 1 ? 'items' : 'item'}`
                        ].join(" · ")
                    }
                </Text>
            </View>
        )
    };

    renderRating = () => {
        return (
            <View style={{flexDirection: 'row', alignItems: "center", marginBottom: 10}}>
                <RatingView
                    style={{marginTop: 0,}}
                    score={this.props.order.getReview() ? this.props.order.getReview()!.getAverageRating() : 0}/>
                {
                    !this.props.order.getReview()
                        ? <ReviewOrder orderId={this.props.order.getId()}/>
                        : null
                }
            </View>
        )
    };

    renderDispatchTimestamp = () => {
        return (
            <View style={{flexDirection: 'row', alignItems: "center", marginBottom: 10}}>
                <Text style={textStyles.secondarySubTitle}>
                    {moment(this.props.order.getCart()!.getDispatchTimestamp()!.toDate()).fromNow()}
                </Text>
            </View>
        )
    };

    renderRightColumn = () => {
        return (
            <View style={{paddingLeft: 10, paddingRight: 5}}>
                <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED}}/>
            </View>
        )
    };
}

interface ViewReviewsProps {
    orderId: string
}

const ReviewOrder = (props: ViewReviewsProps) => {
    const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => navigation!.navigate("Review", {orderId: props.orderId})}>
            <Text style={{color: colorStyles.BLUE, fontSize: 12, fontWeight: '500'}}>
                {`   Review`}
            </Text>
        </TouchableOpacity>
    )
};