import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
    label?: string
}

export default React.memo((props: Props) => {
    return (
        <Field
            maxLength={32}
            clearTextOnFocus={false}
            placeholder={props.label}
            keyboardType="decimal-pad"
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            multiline={false}
            {...props}

            label={props.label ? props.label : "Address"}
            error={props.error}
            showSecureTextEntry={false}/>
    )
});