import {TextInput, TextInputProps} from "react-native";
import React, {RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
}

export default React.memo(function PhoneField(props: Props) {
    let onChange = (value: string) => {
        value = value.replace(/\s/g, '');
        props.onChangeText!(value);
    };

    return (
        <Field
            placeholder="01234567891"
            keyboardType="phone-pad"
            autoCapitalize="none"
            autoCompleteType="tel"
            autoCorrect={false}
            multiline={false}
            {...props}

            onChangeText={onChange}
            label="Phone"
            error={props.error}
            showSecureTextEntry={false}/>
    )
});
