import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {getStore} from "../../reducers/reducers";
import {
    DeleteProductGroupLinkRequest,
    ListProductGroupLinksRequest,
    ListProductGroupLinksResponse
} from "@emreat/proto/backend/v1/product_group_links_pb";
import {ProductGroupLinkService} from "@emreat/proto/backend/v1/product_group_links_pb_service";
import {ProductGroupService} from "@emreat/proto/backend/v1/product_groups_pb_service";
import {DeleteProductGroupRequest} from "@emreat/proto/backend/v1/product_groups_pb";

interface Props {
    productGroup: ProductGroup
    onPress: () => void
    onPressEdit?: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteProductGroup(this.props.productGroup.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteProductGroup = async (id: string) => {
        let req = new DeleteProductGroupRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(ProductGroupService.DeleteProductGroup, req);
        await this.deleteProductGroupLink(id, getStore.getState().merchant!.stores[0].getId());
        getStore.dispatch({type: "REMOVE_PRODUCT_GROUPS", productGroups: [this.props.productGroup]});
    };

    deleteProductGroupLink = async (productGroupId: string, storeId: string) => {
        let listReq = new ListProductGroupLinksRequest();
        listReq.addProductGroupIds(productGroupId);
        listReq.addStoreIds(storeId);
        listReq.setLimit(1);
        let resp: ListProductGroupLinksResponse = await GrpcClient.invokeWithAuth(ProductGroupLinkService.ListProductGroupLinks, listReq);

        let req = new DeleteProductGroupLinkRequest();
        req.setId(resp.getProductGroupLinksList()[0].getId());
        await GrpcClient.invokeWithAuth(ProductGroupLinkService.DeleteProductGroupLink, req);
    };

    render() {
        if (this.props.onPressEdit) {
            return (
                <SwipableButton
                    deleting={this.state.deleting}
                    onPressDelete={this.onPressDelete}
                    onPressUpdate={this.props.onPressEdit}>
                    <View style={{backgroundColor: colorStyles.primaryBackground}}>
                        {this.renderButton()}
                    </View>
                </SwipableButton>
            )
        } else {
            return this.renderButton()
        }
    }

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPress()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderArrow()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.primaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.productGroup.getTitle()}
                </Text>
                {
                    this.props.productGroup.getSubTitle()
                        ? <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                            {this.props.productGroup.getSubTitle()}
                        </Text>
                        : null
                }
            </View>
        )
    };

    renderArrow = () => {
        return (
            <View style={{paddingLeft: 10, paddingRight: 5}}>
                <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED}}/>
            </View>
        )
    };
};