import * as React from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {useNavigation} from '@react-navigation/native';
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";

export default () => {
    const navigation = useNavigation();
    return (

        <TouchableOpacity onPress={() => navigation.navigate("Search", {})}>
            <View style={[
                {flexDirection: 'row', alignItems: 'center'},
                containerStyles.borderBottom, containerStyles.paddingRowMedium,
            ]}>
                <Ionicons name="md-search" size={22} style={{color: colorStyles.BLUE, marginRight: 15}}/>
                <Text style={textStyles.primarySubTitle}>Search for an item</Text>
            </View>
        </TouchableOpacity>
    );
};
