import * as React from "react";
import {Component} from "react";
import {View} from "react-native";
import WebView, {WebViewNavigation} from "react-native-webview";

interface Props {
}

interface State {
    render: boolean
    url: string
    paymentUrl: string
    resolve: ((url: string) => void) | null
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            render: false,
            url: "",
            paymentUrl: "",
            resolve: null,
        };
    }

    getUrl = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            this.setState({url: url, render: true, resolve: resolve});
            setTimeout(() => this.resolve(url), 10000);
        });
    };

    resolve = (url: string) => {
        if (this.state.resolve) {
            this.state.resolve(url);
            this.setState({url: "", render: false, resolve: null})
        }
    };

    onUrlRetrieved = async (e: WebViewNavigation) => {
        if (/.*stripe\.com\/pay.*$/.test(e.url)) {
            this.resolve(e.url);
        }
    };

    render() {
        if (!this.state.render) {
            return null
        }
        return (
            <View style={{height: 0, overflow: "hidden"}}>
                <WebView
                    onNavigationStateChange={e => this.onUrlRetrieved(e)}
                    source={{uri: this.state.url}}
                />
            </View>
        )
    }
}