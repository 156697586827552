import {combineReducers, createStore} from "redux";
import {auth, AuthState} from "./auth";
import {merchant, MerchantState} from "./merchant";
import {customer, CustomerState} from "./customer";
import {settings, SettingsState} from "./settings";
import {getScreenSize} from "./common";
import {Platform} from "react-native";
import {dashboard, DashboardState} from "./dashboard";

export interface RootState {
    settings: SettingsState
    auth: AuthState
    merchant: MerchantState
    customer: CustomerState
    dashboard: DashboardState
}

const AppReducer = combineReducers({
    settings,
    auth,
    merchant,
    customer,
    dashboard,
});

export const getStore = createStore(AppReducer);

if (Platform.OS == 'web') {
    window.addEventListener('resize', () => {
        getStore.dispatch({type: "SET_SCREEN_SIZE", screenSize: getScreenSize()})
    });
}
