import {Component, createRef, default as React, RefObject} from "react";
import {Text, View} from "react-native";
import {MapContainer, Marker, TileLayer, Tooltip, useMapEvent} from "react-leaflet";
import {BaseIconOptions, Browser, Icon, Map as LeafletMap} from "leaflet";
import {textStyles} from "../../Styles";
import {MarkerProps} from "./Marker";

const customerIconProps: BaseIconOptions = {
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
};

const merchantIconProps: BaseIconOptions = {
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
};

interface Props {
    markers: Array<MarkerProps>
}

interface State {
    markerReferences: Map<string, RefObject<any>>
}

export default class extends Component<Props, State> {

    map: LeafletMap | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            markerReferences: new Map(this.props.markers.map(e => [e.id, createRef()])),
        };
        this.map = null
    }

    componentDidMount(): void {
        setTimeout(() => this.map!.flyToBounds(this.props.markers.map(e => ([e.latitude, e.longitude]))), 1000);

        setTimeout(() => this.map?.fitBounds(this.props.markers.map(e => ([e.latitude, e.longitude])), {animate: true}), 1000);
    }

    componentDidUpdate() {
        if (!this.props.markers.map(e => e.id).every(e => Array.from(this.state.markerReferences.keys()).includes(e))) {
            this.setState({markerReferences: new Map(this.props.markers.map(e => [e.id, createRef()]))})
        }
    }

    focusMarker = (id: string) => {
        let marker = this.props.markers.filter(e => e.id == id)[0];
        this.map!.flyTo({lat: marker.latitude, lng: marker.longitude}, 15, {animate: true})
    };

    render() {
        return (
            <View style={{flex: 1, height: 500}}>
                <MapContainer
                    whenCreated={e => this.map = e}
                    center={{lat: this.props.markers[0].latitude, lng: this.props.markers[0].longitude}}
                    zoom={14}
                    scrollWheelZoom={true}
                    dragging={!Browser.mobile}
                    tap={!Browser.mobile}
                    style={{height: '100%', width: '100%'}}>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                    <SetViewOnClick/>

                    {
                        this.props.markers.map(e => (
                            <Marker
                                ref={this.state.markerReferences.get(e.id)}
                                key={e.id}
                                icon={e.customer ? new Icon(customerIconProps) : new Icon(merchantIconProps)}
                                position={{lat: e.latitude, lng: e.longitude}}>
                                <Tooltip sticky permanent={this.props.markers.length == 1}>
                                    <View style={{width: 200}}>
                                        <Text style={textStyles.primaryTitle}>{e.title}</Text>
                                        <Text style={textStyles.secondarySubTitle}>{e.description}</Text>
                                    </View>
                                </Tooltip>
                            </Marker>
                        ))
                    }
                </MapContainer>
            </View>
        )
    }
}

function SetViewOnClick() {
    const map = useMapEvent('click', (e) => {
        map.setView(e.latlng, map.getZoom(), {
            animate: true,
        })
    });
    return null
}