import * as React from "react";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {Platform, RefreshControlProps} from "react-native";

interface Props {
    refreshControl?: React.ReactElement<RefreshControlProps>
}

export default (props: React.PropsWithChildren<Props>) => {
    return (
        <KeyboardAwareScrollView
            showsVerticalScrollIndicator={Platform.OS == 'web'}
            extraHeight={200}
            enableOnAndroid
            alwaysBounceVertical={true}
            refreshControl={props.refreshControl}>
            {props.children}
        </KeyboardAwareScrollView>
    );
}
