import * as React from "react";
import {Component, RefObject} from "react";
import {Platform, StyleSheet, Text, TextInput, View} from "react-native";
import {containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {User} from "@emreat/proto/backend/v1/users_pb";
import HeaderButton from "../../common/buttons/HeaderButton";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import {UpdatePasswordRequest, ValidatePasswordRequest} from "@emreat/proto/backend/v1/passwords_pb";
import {GrpcClient} from "../../GrpcClient";
import {PasswordService} from "@emreat/proto/backend/v1/passwords_pb_service";
import {Password, PasswordUpdateMask} from "@emreat/proto/backend/v1/passwords_pb";
import ScrollView from "../../common/views/ScrollView";
import PasswordField from "../../common/fields/Password";
import FieldErrors from "../../common/fields/Errors";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import SubmitButton from "../../common/buttons/SubmitButton";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Password'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Password'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }

    return <Screen navigation={props.navigation} user={props.user}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>

    user: User
}

interface State {
    previousPassword: string
    newPassword: string

    previousPasswordError: string
    newPasswordError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    previousPasswordRef: RefObject<TextInput>;
    newPasswordRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            previousPassword: "",
            newPassword: "",

            previousPasswordError: "",
            newPasswordError: "",

            submitting: false,
        };
        this.previousPasswordRef = React.createRef();
        this.newPasswordRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Change your password',
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        })
    };

    isValid = (): boolean => {
        if (this.state.previousPassword == "") {
            return false
        }
        return ValidationsUtils.password(this.state.newPassword) == "";
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.validatePassword();
            await this.updatePassword();
            if (Platform.OS == 'web') {
                this.props.navigation.navigate('Info', {})
            } else {
                this.props.navigation.pop()
            }
        } catch (e) {
            if (e.toString() == "ERROR_PASSWORD_SECRET_IS_INCORRECT") {
                toastRef.current!.show("You have entered an incorrect previous password!");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    validatePassword = async () => {
        let req = new ValidatePasswordRequest();
        req.setId(this.props.user.getPasswordId());
        req.setSecret(this.state.previousPassword);
        await GrpcClient.invoke(PasswordService.ValidatePassword, req)
    };

    updatePassword = async () => {
        let password = new Password();
        password.setId(this.props.user.getPasswordId());
        password.setSecret(this.state.newPassword);
        let req = new UpdatePasswordRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setPassword(password);
        req.addUpdateMasks(PasswordUpdateMask.PASSWORD_UPDATE_MASK_SECRET);
        await GrpcClient.invokeWithAuth(PasswordService.UpdatePassword, req);
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Change your password"
                subTitle=""
                dark>
                {this.renderForm()}
                {
                    Platform.OS == 'web'
                        ? (
                            <View style={containerStyles.paddingRowMedium}>
                                <SubmitButton
                                    text='Save'
                                    onPress={() => this.onSubmit()}
                                    submitting={this.state.submitting}
                                    disabled={!this.isValid()}
                                    inActive={!this.isValid() || this.state.submitting}/>
                            </View>
                        ) : null
                }
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter a new password, one that you will remember!
                </Text>

                <DividerView/>

                <PasswordField
                    forwardRef={this.previousPasswordRef}
                    error={Boolean(this.state.previousPasswordError)}
                    label="Previous"
                    value={this.state.previousPassword}
                    onChangeText={e => this.setState({
                        previousPassword: e,
                        previousPasswordError: e == "" ? "Password is a mandatory field" : ""
                    })}
                    onSubmitEditing={() => this.newPasswordRef.current!.focus()}/>

                <PasswordField
                    forwardRef={this.newPasswordRef}
                    returnKeyType="done"
                    error={Boolean(this.state.newPasswordError)}
                    label="New"
                    value={this.state.newPassword}
                    onChangeText={e => this.setState({newPassword: e, newPasswordError: ValidationsUtils.password(e)})}
                    onSubmitEditing={() => this.newPasswordRef.current!.blur()}/>

                <FieldErrors errors={[
                    this.state.previousPasswordError,
                    this.state.newPasswordError,
                ]}/>

            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});