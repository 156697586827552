import Constants from "expo-constants";
import {Platform, StyleSheet} from "react-native";
import {StackHeaderOptions} from "@react-navigation/stack/lib/typescript/src/types";

export const toProperCase = (s: string) => {
    if (s) {
        return s.split(' ').map(e => e.length ? e[0].toUpperCase() + e.substr(1).toLowerCase() : e).join(' ');
    } else {
        return null
    }
};

export const tabNavigationOptions = () => {
    return {
        activeTintColor: colorStyles.RED,
        inactiveTintColor: colorStyles.LIGHT_TEXT,
        activeBackgroundColor: colorStyles.WHITE,
        inactiveBackgroundColor: colorStyles.WHITE,
    }
};

export const stackNavigationOption = (): StackHeaderOptions => {
    if (Platform.OS == 'web') {
        return {
            // @ts-ignore
            headerShown: false,
        }
    } else {
        return {
            headerTransparent: false,
            headerBackTitleVisible: false,
            headerTintColor: colorStyles.BLUE,
            headerTitleAlign: 'center',
            headerTitleStyle: textStyles.primaryTitle,
            headerLeftContainerStyle: {
                marginLeft: 20
            },
            headerRightContainerStyle: {
                marginRight: 20
            },
        }
    }
};

export const colorStyles = {
    GREEN: "#2D6732",
    LIGHT_BLUE: "#EDF7FB",
    BLUE: "#125FCA",
    ALPHA_BLUE: "#125FCA40",
    RED: "#D73F2A",
    ALPHA_RED: "#D73F2A40",
    WHITE: "#FFFFFF",
    LIGHT_GREY: "#F9F9FA",
    DARK_GREY: "#B8B8B8",
    LIGHT_TEXT: "#505050",
    DARK_TEXT: "#333333",

    merchantPrimaryColor: Constants.manifest.extra.color,

    primaryLightText: "#FFFFFF",
    secondaryLightText: "#F9F9FA",

    primaryDarkText: "#333333",
    secondaryDarkText: "#505050",

    error: "#D73F2A",
    errorAlpha: "#D73F2A",

    primaryBackground: "#FFFFFF",
    secondaryBackground: "#F9F9FA",

    primaryBorder: Platform.OS == 'web' ? '#e2e6e9' : '#F1F3F4',
};

export const textStyles = StyleSheet.create({
    primaryHeadline: {
        fontSize: 24,
        fontWeight: '600',
        color: colorStyles.primaryDarkText,
    },
    secondaryHeadline: {
        fontSize: 20,
        fontWeight: '600',
        color: colorStyles.primaryDarkText,
    },
    primaryTitle: {
        fontSize: 16,
        fontWeight: '600',
        color: colorStyles.primaryDarkText,
    },
    secondaryTitle: {
        fontSize: 15,
        fontWeight: '600',
        color: colorStyles.primaryDarkText,
    },
    primarySubTitle: {
        fontSize: 15,
        fontWeight: '500',
        color: colorStyles.secondaryDarkText,
    },
    secondarySubTitle: {
        fontSize: 14,
        fontWeight: '400',
        color: colorStyles.primaryDarkText,
    }
});

export const containerStyles = StyleSheet.create({
    border: {
        borderColor: colorStyles.primaryBorder,
        borderWidth: 1,
        borderRadius: 5,
    },
    borderBottom: {
        borderColor: colorStyles.primaryBorder,
        borderBottomWidth: 1,
    },
    borderTop: {
        borderColor: colorStyles.primaryBorder,
        borderTopWidth: 1,
    },
    borderLeft: {
        borderColor: colorStyles.primaryBorder,
        borderLeftWidth: 1,
    },

    page: {
        flex: 1,
        width: '100%',
        maxWidth: 1300,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: '5%',
        paddingRight: '5%',
    },

    paddingContainer: {
        paddingLeft: Platform.OS == 'web' ? 25 : 0,
        paddingRight: Platform.OS == 'web' ? 25 : 0,
    },
    paddingRowZero: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowSmall: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowSmallTop: {
        paddingTop: 10,
        paddingBottom: 0,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowSmallBottom: {
        paddingTop: 0,
        paddingBottom: 10,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowMedium: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowMediumTop: {
        paddingTop: 15,
        paddingBottom: 0,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowMediumBottom: {
        paddingTop: 0,
        paddingBottom: 15,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowLarge: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowLargeTop: {
        paddingTop: 25,
        paddingBottom: 0,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },
    paddingRowLargeBottom: {
        paddingTop: 0,
        paddingBottom: 25,
        paddingLeft: Platform.OS == 'web' ? 0 : 25,
        paddingRight: Platform.OS == 'web' ? 0 : 25,
    },

    primaryShadow: {
        shadowColor: "rgba(0, 0, 0, 0.10)",
        shadowOffset: {
            width: 15,
            height: 0,
        },
        shadowRadius: 30,

        elevation: 5,
    },
    secondaryShadow: {
        shadowColor: "rgba(0, 0, 0, 0.10)",
        shadowOffset: {
            width: 15,
            height: 0,
        },
        shadowRadius: 30,

        elevation: 5,
    },
});