import {default as React, Component, RefObject} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Hoverable} from "react-native-web-hover";
import ScrollView from "./ScrollView";

export interface MenuItem {
    id: string
    title: string
}

interface Props {
    items: Array<MenuItem>
    itemRef?: Array<RefObject<any>>
    onPress: (id: string) => void
}

interface State {
    activeIndex: number
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            activeIndex: 0,
        }
    }

    componentDidMount() {
        if (this.props.itemRef && this.props.itemRef.length) {
            window.addEventListener('scroll', this.onScroll);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = async () => {
        let y = window.scrollY;
        let activeIndex = 0;
        let offScreen = this.props.itemRef!
            .map((e, i) => e.current!.getBoundingClientRect().top <= 0 ? i : -1)
            .filter(e => e !== -1);
        if (offScreen.length) activeIndex = offScreen[offScreen.length - 1];
        if (this.state.activeIndex != activeIndex) this.setState({activeIndex})
    };

    render() {
        return (
            <ScrollView>
                {this.props.items.map((e, i) => this.renderMenuItem(e, i))}
            </ScrollView>
        )
    }

    renderMenuItem = (item: MenuItem, index: number) => {
        return (
            <Hoverable key={item.id}>
                {({hovered}) => (
                    <TouchableOpacity onPress={() => this.props.onPress(item.id)}>
                        <View style={[
                            containerStyles.paddingRowSmall, containerStyles.borderLeft, {paddingLeft: 15},
                            hovered || this.state.activeIndex == index
                                ? {borderLeftWidth: 3, borderColor: colorStyles.primaryDarkText}
                                : {}
                        ]}>
                            <Text style={[
                                textStyles.secondaryTitle,
                                {textTransform: 'capitalize'},
                                hovered || this.state.activeIndex == index ? {fontWeight: '600'} : {}
                            ]}>
                                {item.title}
                            </Text>
                        </View>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    }
}
