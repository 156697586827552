import {FlatList, Platform, RefreshControl, TouchableOpacity, View} from "react-native";
import * as React from "react";
import {Component} from "react";
import {getStore, RootState} from "../../reducers/reducers";
import {StackNavigationProp} from "@react-navigation/stack";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {toastRef} from "../../common/views/ToastView";
import SingleLayout from "../../common/layouts/SingleLayout";
import {DashboardStackParamList} from "../../DashboardNavigator";
import HeaderButton from "../../common/buttons/HeaderButton";
import {GrpcClient, paginationRefreshLimit} from "../../GrpcClient";
import {
    CreateImageRequest,
    DeleteImageRequest,
    Image,
    ListImagesRequest,
    ListImagesResponse
} from "@emreat/proto/backend/v1/images_pb";
import Constants from "expo-constants";
import {ImageService} from "@emreat/proto/backend/v1/images_pb_service";
import {ImageView} from "../../common/views/ImageView";
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import ActionSheet from "react-native-action-sheet";
import {colorStyles} from "../../Styles";

let mapStateToProps = (state: RootState) => ({
    images: state.dashboard.images,
});

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList, 'Images'>
    route: RouteProp<DashboardStackParamList, 'Images'>

    images: Array<Image>
}

interface State {
    refreshing: boolean
    loadingMore: boolean
    endReached: boolean
    uploading: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            loadingMore: false,
            endReached: false,
            uploading: false,
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
        this.loadMore().catch()
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Images",
            headerRight: () => (
                <HeaderButton
                    submitting={this.state.uploading}
                    disabled={this.state.uploading}
                    onPress={() => this.showActionSheet()}
                    icon="md-add"/>
            ),
        });
    };

    listImages = async (offset: number): Promise<Array<Image>> => {
        let req = new ListImagesRequest();
        req.setLimit(paginationRefreshLimit);
        req.setOffset(offset);
        req.addMerchantIds(Constants.manifest.extra.merchantId);
        let resp: ListImagesResponse = await GrpcClient.invokeWithAuth(ImageService.ListImages, req);
        getStore.dispatch({type: "SET_MERCHANT_IMAGES", images: resp.getImagesList()});
        return resp.getImagesList();
    };

    deleteImage = async (image: Image) => {
        let req = new DeleteImageRequest();
        req.setId(image.getId());
        await GrpcClient.invokeWithAuth(ImageService.DeleteImage, req);
        getStore.dispatch({type: "REMOVE_MERCHANT_IMAGES", images: [image]});
    };

    createImage = async (content: any): Promise<Image> => {
        let image = new Image()
        image.setMerchantId(Constants.manifest.extra.merchantId);
        image.setContent(content)

        let req = new CreateImageRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setImage(image);
        let resp: Image = await GrpcClient.invokeWithAuth(ImageService.CreateImage, req);
        getStore.dispatch({type: "SET_MERCHANT_IMAGES", images: [resp]});
        return resp;
    };

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await this.listImages(0);
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    loadMore = async () => {
        try {
            if (!this.state.loadingMore && !this.state.endReached) {
                this.setState({loadingMore: true});
                let reviews = await this.listImages(this.props.images.length);
                this.setState({endReached: reviews.length < paginationRefreshLimit});
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({loadingMore: false});
    };

    showActionSheet = async () => {
        let options = [
            'Camera',
            'Library',
        ];
        if (Platform.OS === 'ios') {
            options.push('Cancel');
        }

        ActionSheet.showActionSheetWithOptions({
                options: options,
                cancelButtonIndex: 2,
                tintColor: colorStyles.DARK_TEXT
            },
            (buttonIndex) => {
                if (buttonIndex === 0) {
                    this.takePhoto()
                } else if (buttonIndex === 1) {
                    this.pickImage()
                }
            });
    }

    takePhoto = async () => {
        const permission = await Permissions.askAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL);
        if (permission.status === 'granted') {
            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: false,
                aspect: [4, 3],
                quality: 1,
            });
            if (!result.cancelled) {
                await this.uploadImage(result.uri)
            }
        }
    }

    pickImage = async () => {
        const permission = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        if (permission.status === 'granted') {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: false,
                aspect: [4, 3],
                quality: 1,
            });
            if (!result.cancelled) {
                await this.uploadImage(result.uri)
            }
        }
    };

    uploadImage = async (uri: string) => {
        this.setState({uploading: true})
        try {
            let image = await ImageManipulator.manipulateAsync(uri, [], {compress: 0});
            const imageBytes = await FileSystem.readAsStringAsync(image.uri, {encoding: 'base64'})
            await this.createImage(imageBytes)
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({uploading: false})
    }

    onPressImage = async (image: Image) => {
        let options = [
            'Delete',
        ];
        if (Platform.OS === 'ios') {
            options.push('Cancel');
        }

        ActionSheet.showActionSheetWithOptions({
                options: options,
                cancelButtonIndex: 1,
                destructiveButtonIndex: 0,
                tintColor: colorStyles.DARK_TEXT
            },
            async (buttonIndex) => {
                if (buttonIndex === 0) {
                    try {
                        await this.deleteImage(image)
                    } catch (e) {
                        toastRef.current!.show(e.toString());
                        toastRef.current!.show("Oops something went wrong!");
                    }
                }
            });
    }

    render() {
        return (
            <SingleLayout navigation={this.props.navigation}>
                <View style={{flex: 1}}>
                    {this.renderImages()}
                </View>
            </SingleLayout>
        )
    };

    renderImages = () => {
        return (
            <FlatList
                showsVerticalScrollIndicator={true}
                data={this.props.images}
                keyExtractor={item => item.getId()}
                initialNumToRender={10}
                numColumns={3}
                renderItem={item => (
                    <TouchableOpacity
                        style={{flex: 1, height: 150, borderColor: colorStyles.primaryBorder, borderWidth: 1}}
                        onPress={() => this.onPressImage(item.item)}>
                        <ImageView imageUrl={item.item.getUrl()} noBorder={true}/>
                    </TouchableOpacity>
                )}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };
});