import * as React from 'react';
import {Component} from 'react';
import {LinkingOptions, NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import {stackNavigationOption, tabNavigationOptions} from "./Styles";
import {Platform} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";

import LocationsScreen from "./screens/customer/LocationsScreen";
import PasswordScreen from "./screens/customer/PasswordScreen";
import ProductGroupsScreen from "./screens/menu/ProductGroupsScreen";
import LocationScreen from "./screens/customer/LocationScreen";
import AccountScreen from "./screens/customer/AccountScreen";
import UserScreen from "./screens/customer/UserScreen";
import OrdersScreen from "./screens/checkout/OrdersScreen";
import LoginScreen from "./screens/auth/LoginScreen";
import RegisterScreen from "./screens/auth/RegisterScreen";
import VerificationScreen from "./screens/auth/VerificationScreen";
import PrivacyPolicyScreen from "./screens/auth/PrivacyPolicyScreen";
import TermsOfUseScreen from "./screens/auth/TermsOfUseScreen";
import ResetScreen from "./screens/auth/ResetScreen";
import ForgottenScreen from "./screens/auth/ForgottenScreen";
import ProductsScreen from "./screens/menu/ProductsScreen";
import ReviewScreen from "./screens/checkout/ReviewScreen";
import CartScreen from "./screens/checkout/CartScreen";
import SearchScreen from "./screens/menu/SearchScreen";
import InformationScreen from "./screens/menu/InformationScreen";
import CheckoutScreen from "./screens/checkout/CheckoutScreen";
import RedirectScreen from "./screens/checkout/RedirectScreen";
import ReviewsScreen from "./screens/menu/ReviewsScreen";
import OrderScreen from "./screens/checkout/OrderScreen";
import DashboardNavigator from "./DashboardNavigator";

const Stack = createStackNavigator();
const prefix = Linking.makeUrl('/');
export type RootNavigatorParamList = {
    Home: {}
    Dashboard: {}
    Auth: {}
};

export default () => {
    const linking: LinkingOptions = {
        prefixes: [prefix],
        config: {
            screens: {
                Home: {
                    path: "",
                    screens: {
                        Menu: "",
                        Products: "Products",
                        Search: "Search",
                        Information: "Information",
                        Reviews: "Reviews",

                        Orders: "Orders",
                        Review: "Review",
                        Cart: "Cart",
                        Checkout: "Checkout",
                        Redirect: "Redirect",
                        Order: "Order",

                        Info: "Info",
                        User: "User",
                        Password: "Password",
                        Location: "Location",
                        Locations: "Locations",

                        TermsOfUse: "TermsOfUse",
                        PrivacyPolicy: "PrivacyPolicy",
                    }
                },
                Dashboard: {
                    path: "Dashboard",
                    screens: {
                        Home: "Home",
                        Order: "Order",
                        ProductGroups: "ProductGroups",
                        ProductGroup: "ProductGroup",
                        Products: "Products",
                        Product: "Product",
                        ModifierGroups: "ModifierGroups",
                        ModifierGroup: "ModifierGroup",
                        Modifier: "Modifier",
                        Variation: "Variation",
                    }
                },
                Auth: {
                    path: "Auth",
                    screens: {
                        Login: "Login",
                        Register: "Register",
                        Verification: "Verification",
                        Forgotten: "Forgotten",
                        Reset: "Reset",
                        TermsOfUse: "TermsOfUse",
                        PrivacyPolicy: "PrivacyPolicy",
                    }
                },
            }
        },
    };
    return (
        <NavigationContainer linking={linking}>
            <Stack.Navigator
                screenOptions={stackNavigationOption}
                mode={Platform.OS == 'ios' ? "modal" : "card"}>
                <Stack.Screen
                    name="Home"
                    component={HomeNavigator}
                    options={{headerShown: false, gestureEnabled: false}}/>
                <Stack.Screen
                    name="Dashboard"
                    component={DashboardNavigator}
                    options={{headerShown: false, gestureEnabled: false}}/>
                <Stack.Screen
                    name="Auth"
                    component={AuthNavigator}
                    options={{headerShown: false, gestureEnabled: false}}/>
            </Stack.Navigator>
        </NavigationContainer>
    )
}


export type AuthNavigatorParamList = {
    Login: {}
    Register: {}
    Verification: { emailAddress: string }
    Forgotten: {}
    Reset: { token: string, passwordId: string }
    TermsOfUse: {}
    PrivacyPolicy: {}
};
const AuthStack = createStackNavigator();

function AuthNavigator() {
    return (
        <AuthStack.Navigator initialRouteName="Login" screenOptions={stackNavigationOption}>
            <AuthStack.Screen name="Login" component={LoginScreen}/>
            <AuthStack.Screen name="Register" component={RegisterScreen}/>
            <AuthStack.Screen name="Verification" component={VerificationScreen}/>
            <AuthStack.Screen name="Forgotten" component={ForgottenScreen}/>
            <AuthStack.Screen name="Reset" component={ResetScreen}/>
            <HomeStack.Screen name="TermsOfUse" component={TermsOfUseScreen}/>
            <HomeStack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen}/>
        </AuthStack.Navigator>
    );
}

export type HomeNavigatorParamList = {
    Menu: {}
    Products: { productGroupId: string }
    Search: {}
    Information: {}
    Reviews: {}

    Orders: {}
    Review: { id?: string, orderId?: string }
    Cart: {}
    Checkout: {}
    Redirect: { paymentId: string }
    Order: { id: string }

    Info: {}
    User: {}
    Password: {}
    Location: { id?: string }
    Locations: {}

    TermsOfUse: {}
    PrivacyPolicy: {}
};
let HomeStack = createStackNavigator();
const HomeNavigator = () => {
    return (
        <HomeStack.Navigator screenOptions={stackNavigationOption}>
            {
                Platform.OS != "web"
                    ? <Stack.Screen
                        name="Menu"
                        component={NativeTabNavigator}
                        options={{headerShown: false, gestureEnabled: false}}/>
                    : [
                        <HomeStack.Screen key="Menu" name="Menu" component={ProductGroupsScreen}/>,
                        <HomeStack.Screen key="Orders" name="Orders" component={OrdersScreen}/>,
                        <HomeStack.Screen key="Info" name="Info" component={AccountScreen}/>
                    ]
            }

            <HomeStack.Screen name="Products" component={ProductsScreen}/>
            <HomeStack.Screen name="Search" component={SearchScreen}/>
            <HomeStack.Screen name="Information" component={InformationScreen}/>
            <HomeStack.Screen name="Reviews" component={ReviewsScreen}/>

            <HomeStack.Screen name="Review" component={ReviewScreen}/>
            <HomeStack.Screen name="Cart" component={CartScreen}/>
            <HomeStack.Screen name="Checkout" component={CheckoutScreen}/>
            <HomeStack.Screen name="Redirect" component={RedirectScreen}/>
            <HomeStack.Screen name="Order" component={OrderScreen}/>

            <HomeStack.Screen name="User" component={UserScreen}/>
            <HomeStack.Screen name="Password" component={PasswordScreen}/>
            <HomeStack.Screen name="Location" component={LocationScreen}/>
            <HomeStack.Screen name="Locations" component={LocationsScreen}/>

            <HomeStack.Screen name="TermsOfUse" component={TermsOfUseScreen}/>
            <HomeStack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen}/>
        </HomeStack.Navigator>
    );
};

export type NativeTabParamList = {
    Menu: {}
    Orders: {}
    Account: {}
};
let NativeTab = createBottomTabNavigator();
let Tab1 = createStackNavigator();
let Tab2 = createStackNavigator();
let Tab3 = createStackNavigator();

class NativeTabNavigator extends Component {
    render() {
        return (
            <NativeTab.Navigator initialRouteName="Menu" tabBarOptions={tabNavigationOptions()}>
                <NativeTab.Screen
                    name="Menu"
                    component={this.renderMenuTabNavigator}
                    options={{
                        title: "Menu",
                        tabBarIcon: (props) => (<Ionicons name="md-home" color={props.color} size={props.size}/>)
                    }}/>

                <NativeTab.Screen
                    name="Orders"
                    component={this.renderCheckoutTabNavigator}
                    options={{
                        title: "Orders",
                        tabBarIcon: (props) => (<Ionicons name="md-list-box" color={props.color} size={props.size}/>)
                    }}/>

                <NativeTab.Screen
                    name="Info"
                    component={this.renderAccountTabNavigator}
                    options={{
                        title: "Account",
                        tabBarIcon: (props) => (<Ionicons name="md-person" color={props.color} size={props.size}/>)
                    }}/>
            </NativeTab.Navigator>
        );
    };

    renderMenuTabNavigator = () => {
        return (
            <Tab1.Navigator screenOptions={stackNavigationOption}>
                <Tab1.Screen name="Menu" component={ProductGroupsScreen}/>
            </Tab1.Navigator>
        );
    };

    renderCheckoutTabNavigator = () => {
        return (
            <Tab2.Navigator screenOptions={stackNavigationOption}>
                <Tab2.Screen name="Orders" component={OrdersScreen}/>
            </Tab2.Navigator>
        );
    };

    renderAccountTabNavigator = () => {
        return (
            <Tab3.Navigator screenOptions={stackNavigationOption}>
                <Tab3.Screen name="Info" component={AccountScreen}/>
            </Tab3.Navigator>
        );
    };
};