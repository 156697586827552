import React, {Component, RefObject} from "react";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import {CartItem, CartModifier} from "@emreat/proto/backend/v1/cart_items_pb";
import {Variation} from "@emreat/proto/backend/v1/variations_pb";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import {Modifier} from "@emreat/proto/backend/v1/modifiers_pb";
import {FlatList, Platform, SafeAreaView, Text, TouchableOpacity, TouchableWithoutFeedback, View} from "react-native";
import {getStore} from "../../reducers/reducers";
import {ImageView} from "../../common/views/ImageView";
import MoneyView from "../../common/views/MoneyView";
import ToastView from "../../common/views/ToastView";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import SubmitButton from "../../common/buttons/SubmitButton";
import CartModifierRow from "./CartModifierRow";
import CartModifierGroupHeader from "./CartModifierGroupHeader";
import CartVariationRow from "./CartVariationRow";
import CartVariationHeader from "./CartVariationHeader";
import {Ionicons} from "@expo/vector-icons";
import {Service} from "../../Service";
import {default as FastToast} from "react-native-fast-toast/lib/typescript/toast-container";

interface Props {
    product: Product
    cartItem?: CartItem
    productGroupId?: string

    onSubmit: () => void
}

interface State {
    quantity: number
    variation: Variation | null
    cartModifiers: Array<CartModifier>

    errorGroupId: string

    submitting: boolean
}

interface Data {
    type: "HEADER_IMAGE" | "HEADER_TITLE" | "VARIATION_HEADER" | "VARIATION" | "MODIFIER_GROUP_HEADER" | "MODIFIER"
    data: null | Variation | ModifierGroup | Modifier,
}


export default class extends Component<Props, State> {

    scrollRef: RefObject<FlatList>;
    toastRef: RefObject<FastToast>;

    constructor(props: Props) {
        super(props);
        this.state = {
            quantity: props.cartItem ? props.cartItem.getQuantity() : 1,
            variation: props.cartItem
                ? props.product.getVariationsList().filter(e => e.getId() == props.cartItem!.getVariationId())[0]
                : props.product.getVariationsList().length == 1 ? props.product.getVariationsList()[0] : null,
            cartModifiers: props.cartItem ? props.cartItem.getCartModifiersList().map(e => e.clone()) : [],
            errorGroupId: '',
            submitting: false,
        };

        this.scrollRef = React.createRef();
        this.toastRef = React.createRef();
    }

    getData = (): Array<Data> => {
        // Header
        let data: Array<Data> = [
            {type: "HEADER_IMAGE", data: null},
            {type: "HEADER_TITLE", data: null},
        ];

        // Variations
        if (this.props.product.getVariationsList().length > 1) {
            data.push({type: "VARIATION_HEADER", data: null});
            this.props.product.getVariationsList()
                .sort((a, b) => a.getBasePrice() - b.getBasePrice())
                .forEach(e => data.push({type: "VARIATION", data: e}));
        }

        // Modifier groups.
        getStore.getState().merchant.modifierGroups
            .filter(e => this.state.variation?.getModifierGroupIdsList().includes(e.getId()))
            .sort((x, y) => {
                let xRequired = x.getMinimumChoice() != 0;
                let yRequired = y.getMinimumChoice() != 0;
                return (xRequired === yRequired) ? 0 : xRequired ? -1 : 1;
            })
            .forEach(e => {
                data.push({type: "MODIFIER_GROUP_HEADER", data: e});
                e.getModifiersList()
                    .sort((a, b) => a.getAdditionalPrice() - b.getAdditionalPrice())
                    .forEach(e => data.push({type: "MODIFIER", data: e}))
            });
        return data
    };

    addQuantity = () => {
        this.setState({quantity: this.state.quantity + 1});
    };

    removeQuantity = () => {
        if (this.state.quantity! > 1) {
            this.setState({quantity: this.state.quantity - 1});
        }
    };

    scrollToNext = (setError = false) => {
        let data = this.getData();
        let invalid = data
            .filter(e => e.type == "MODIFIER_GROUP_HEADER")
            .filter(e => !this.isModifierGroupValid(e.data as ModifierGroup));
        if (setError) {
            this.setState({errorGroupId: (invalid[0].data as ModifierGroup).getId()})
        }

        setTimeout(() => {
            let data = this.getData();
            let invalid = data
                .filter(e => e.type == "MODIFIER_GROUP_HEADER")
                .filter(e => !this.isModifierGroupValid(e.data as ModifierGroup));
            if (invalid.length) {
                this.scrollRef.current!.scrollToIndex({
                    animated: true,
                    viewPosition: 0,
                    index: Platform.OS == 'web' ? data.indexOf(invalid[0]) - 1 : data.indexOf(invalid[0]),
                })
            }
        }, 250);
    };

    setVariation = (variation: Variation) => {
        if (this.state.variation != variation) {
            this.setState({
                variation: variation,
                errorGroupId: "",
                cartModifiers: []
            });
            this.scrollToNext()
        } else {
            this.setState({
                variation: null,
                errorGroupId: "",
                cartModifiers: []
            });
        }
    };

    getTotalQuantityForModifierGroup = (modifierGroupId: string): number => {
        return this.state.cartModifiers
            .filter(e => e.getModifierGroupId() == modifierGroupId)
            .map(e => e.getQuantity())
            .reduce((a, b) => a + b, 0);
    };

    getTotalForModifierGroup = (modifierGroupId: string): number => {
        return this.state.cartModifiers
            .filter(e => e.getModifierGroupId() == modifierGroupId)
            .map(e => e.getQuantity() * e.getUnitPrice())
            .reduce((a, b) => a + b, 0);
    };

    getCartItemTotal = (): number => {
        let modifierTotal = this.state.cartModifiers
            .map(e => e.getQuantity() * e.getUnitPrice()).reduce((a, b) => a + b, 0);
        if (!this.state.variation) {
            return (Math.min(...this.props.product.getVariationsList().map(v => v.getBasePrice())) + modifierTotal) * this.state.quantity
        } else {
            return (this.state.variation.getBasePrice() + modifierTotal) * this.state.quantity
        }
    };

    isModifierGroupValid = (modifierGroup: ModifierGroup): boolean => {
        let totalQuantity = this.getTotalQuantityForModifierGroup(modifierGroup.getId());
        return modifierGroup.getMinimumChoice() <= totalQuantity && totalQuantity <= modifierGroup.getMaximumChoice();
    };

    updateHasChanged = (): boolean => {
        if (this.state.quantity != this.props.cartItem!.getQuantity()) {
            return true
        }
        if (this.state.variation!.getId() != this.props.cartItem!.getVariationId()) {
            return true
        }
        if (this.props.cartItem!.getCartModifiersList().length != this.state.cartModifiers.length) {
            return true
        }
        return !this.props.cartItem!.getCartModifiersList().map(e => this.state.cartModifiers
            .map(b => e.getQuantity() == b.getQuantity() && e.getModifierId() == b.getModifierId())
            .some(e => e)).every(e => e);
    };

    isValid = (): boolean => {
        if (!this.state.variation) {
            return false
        }
        return getStore.getState().merchant.modifierGroups
            .filter(e => this.state.variation!.getModifierGroupIdsList().includes(e.getId()))
            .map(e => this.isModifierGroupValid(e))
            .every(e => e)
    };

    onNotValid = async () => {
        if (!this.state.variation) {
            this.scrollRef.current!.scrollToIndex({
                animated: true,
                viewPosition: 0,
                index: Platform.OS == 'web' ? 1 : 2,
            });
            this.setState({errorGroupId: "VARIATION"})
        } else {
            this.scrollToNext(true)
        }
    };

    onDecrementCartModifier = (modifier: Modifier) => {
        let cartModifiers = this.state.cartModifiers;
        cartModifiers.forEach(e => {
            if (e.getModifierId() == modifier.getId()) e.setQuantity(e.getQuantity() - 1)
        });
        cartModifiers = cartModifiers.filter(e => e.getQuantity() >= 1);
        this.setState({cartModifiers: cartModifiers})
    };

    newCartModifier = (modifier: Modifier): CartModifier => {
        let modifierGroup = getStore.getState().merchant.modifierGroups.filter(e => e.getId() == modifier.getModifierGroupId())[0];
        let cartModifier = new CartModifier();
        cartModifier.setModifierId(modifier.getId());
        cartModifier.setModifierGroupId(modifierGroup.getId());
        cartModifier.setUnitPrice((modifier.getAdditionalPrice()));
        cartModifier.setQuantity(1);
        return cartModifier
    };

    onModifierPress = (modifier: Modifier) => {
        let modifierGroup = getStore.getState().merchant.modifierGroups.filter(e => e.getId() == modifier.getModifierGroupId())[0];
        let cartModifiers = this.state.cartModifiers;

        // If modifier has 1 maximum selection and is already selected we toggle it.
        if (cartModifiers.filter(e => e.getModifierId() == modifier.getId()).length && modifier.getMaximumChoice() == 1) {
            cartModifiers = cartModifiers.filter(e => e.getModifierId() != modifier.getId());
            // Compulsory single selection group. We replace existing modifier.
        } else if (modifierGroup.getMinimumChoice() == 1 && modifierGroup.getMaximumChoice() == 1) {
            cartModifiers = cartModifiers.filter(e => !modifierGroup.getModifiersList().map(e => e.getId()).includes(e.getModifierId()));
            cartModifiers.push(this.newCartModifier(modifier))

            // If total number of modifiers in group is below maximum.
        } else if (this.getTotalQuantityForModifierGroup(modifierGroup.getId()) < modifierGroup.getMaximumChoice()) {
            // CartModifier for modifier does not exist.
            if (!this.state.cartModifiers.filter(e => e.getModifierId() == modifier.getId()).length) {
                cartModifiers.push(this.newCartModifier(modifier))
                // CartModifier for modifier does exist.
            } else {
                cartModifiers.forEach(e => {
                    if (e.getModifierId() == modifier.getId()) {
                        if (e.getQuantity() < modifier.getMaximumChoice()) {
                            e.setQuantity(e.getQuantity() + 1);
                            e.setTotalPrice(e.getUnitPrice() * e.getQuantity());
                        } else {
                            this.toastRef.current!.show("You must remove an item before adding another.");
                        }
                    }
                });
            }
        } else {
            this.toastRef.current!.show("You must remove an item before adding another.");
        }

        this.setState({cartModifiers: cartModifiers}, () => {
            if (this.state.errorGroupId == modifierGroup.getId() && this.isModifierGroupValid(modifierGroup)) {
                this.setState({errorGroupId: ""})
            }
            if (this.isModifierGroupValid(modifierGroup) && this.getTotalQuantityForModifierGroup(modifierGroup.getId()) == modifierGroup.getMaximumChoice()) {
                this.scrollToNext()
            }
        });
    };

    onSubmit = async () => {
        if (!this.isValid()) {
            this.onNotValid();
            this.toastRef.current!.show("Dont miss out, make all your choices.");
        } else if (this.props.cartItem && !this.updateHasChanged()) {
            this.toastRef.current!.show("You have not made any alterations!");
        } else {
            this.setState({submitting: true});
            try {
                await this.submit();
                this.props.onSubmit();
            } catch (e) {
                this.toastRef.current!.show(e.toString());
                this.toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    submit = async () => {
        if (this.props.cartItem!) {
            await Service.updateCartItem(
                this.props.cartItem!.getId(),
                this.state.variation!.getId(),
                this.state.quantity,
                this.state.cartModifiers,
            );
        } else {
            await Service.CreateOrUpdateCartItem(
                this.props.product!.getId(),
                this.props.productGroupId!,
                this.state.variation!.getId(),
                this.state.quantity,
                this.state.cartModifiers,
            );
        }
    };

    render() {
        let data = this.getData();
        let stickyIndies: Array<number> = [];
        data.forEach((d, i) => {
            if (d.type == "VARIATION_HEADER" || d.type == "MODIFIER_GROUP_HEADER") {
                stickyIndies.push(i)
            }
        });

        return (
            <SafeAreaView style={[{maxHeight: "100%"}, Platform.OS == 'web' ? {flex: 1} : {}]}>
                <FlatList
                    ref={this.scrollRef}
                    data={data}
                    bounces={false}
                    initialNumToRender={100}
                    showsVerticalScrollIndicator={Platform.OS == 'web'}
                    stickyHeaderIndices={stickyIndies}
                    onScrollToIndexFailed={() => null}
                    keyExtractor={item => this.getKey(item)}
                    renderItem={item => this.renderData(item.item)}/>
                {this.renderFooter()}

                <ToastView forwardRef={this.toastRef}/>
            </SafeAreaView>
        )
    };

    getKey = (item: Data): string => {
        switch (item.type) {
            case "HEADER_IMAGE":
                return "HEADER_IMAGE";
            case "HEADER_TITLE":
                return "HEADER_TITLE";
            case "VARIATION_HEADER":
                return "VARIATION_HEADER";
            case "VARIATION":
                return (item.data as Variation).getId();
            case "MODIFIER_GROUP_HEADER":
                return (item.data as ModifierGroup).getId();
            case "MODIFIER":
                return (item.data as Modifier).getId()
        }
    };

    renderData = (item: Data) => {
        switch (item.type) {
            case "HEADER_IMAGE":
                return this.renderHeaderImage();
            case "HEADER_TITLE":
                return this.renderHeaderTitle();
            case "VARIATION_HEADER":
                return this.renderVariationHeader();
            case "VARIATION":
                return this.renderVariation(item.data as Variation);
            case "MODIFIER_GROUP_HEADER":
                return this.renderModifierGroupHeader(item.data as ModifierGroup);
            case "MODIFIER":
                return this.renderModifier(item.data as Modifier)
        }
    };

    renderHeaderImage = () => {
        if (!this.props.product.getImageId()) {
            return null
        }
        return (
            <TouchableWithoutFeedback>
                <ImageView imageUrl={this.props.product.getImageUrl()} style={{height: 225, width: '100%'}}/>
            </TouchableWithoutFeedback>
        )
    };

    renderHeaderTitle = () => {
        return (
            <TouchableWithoutFeedback>
                <View style={[
                    containerStyles.paddingRowMedium, containerStyles.borderBottom,
                    {paddingLeft: 25, paddingRight: 25},
                ]}>
                    <Text style={[textStyles.secondaryHeadline, {textTransform: 'capitalize'}]}>
                        {this.props.product.getTitle()}
                    </Text>
                    {
                        this.props.product.getSubTitle()
                            ? <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                                {this.props.product.getSubTitle()}
                            </Text>
                            : null
                    }
                </View>
            </TouchableWithoutFeedback>
        )
    };

    renderFooter = () => {
        return (
            <View style={containerStyles.borderTop}>
                {this.renderQuantitySelector()}
                <View style={[containerStyles.paddingRowMedium, {
                    flexDirection: 'row',
                    paddingLeft: 25,
                    paddingRight: 25
                }]}>
                    <Text style={[textStyles.primaryTitle, {flex: 1}]}>Total</Text>
                    <Text style={textStyles.primaryTitle}><MoneyView value={this.getCartItemTotal()}/></Text>
                </View>
                <View style={[containerStyles.paddingRowMediumBottom, {paddingLeft: 25, paddingRight: 25}]}>
                    {
                        this.props.cartItem
                            ? <SubmitButton
                                text="Update item"
                                onPress={this.onSubmit}
                                submitting={this.state.submitting}
                                disabled={false}
                                inActive={!this.isValid() || !this.updateHasChanged() || this.state.submitting}/>
                            : <SubmitButton
                                text="Add to order"
                                onPress={this.onSubmit}
                                submitting={this.state.submitting}
                                disabled={false}
                                inActive={!this.isValid() || this.state.submitting}/>
                    }
                </View>
            </View>
        )
    };

    renderQuantitySelector = () => {
        let enabled = this.state.variation != null;
        return (
            <View style={[containerStyles.paddingRowMediumTop, {justifyContent: 'center', flexDirection: 'row'}]}>
                <TouchableOpacity
                    onPress={() => enabled && this.removeQuantity()}
                    style={{paddingLeft: 15, paddingRight: 15}}>
                    <Ionicons
                        name="md-remove"
                        size={28}
                        style={{color: this.state.quantity == 1 || !enabled ? colorStyles.ALPHA_RED : colorStyles.RED}}/>
                </TouchableOpacity>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <Text style={textStyles.secondaryHeadline}>{this.state.quantity}</Text>
                </View>
                <TouchableOpacity
                    onPress={() => enabled && this.addQuantity()}
                    style={{paddingLeft: 15, paddingRight: 15}}>
                    <Ionicons
                        name="md-add"
                        size={28}
                        style={{color: !enabled ? colorStyles.ALPHA_RED : colorStyles.RED}}/>
                </TouchableOpacity>
            </View>
        )
    };

    renderVariationHeader = () => {
        return (
            <TouchableWithoutFeedback>
                <View style={containerStyles.borderBottom}>
                    <CartVariationHeader
                        isValid={this.state.variation != null}
                        error={this.state.errorGroupId == "VARIATION"}/>
                </View>
            </TouchableWithoutFeedback>
        );
    };

    renderVariation = (variation: Variation) => {
        return (
            <TouchableWithoutFeedback>
                <View style={containerStyles.borderBottom}>
                    <CartVariationRow
                        onPress={() => this.setVariation(variation)}
                        variation={variation}
                        selected={this.state.variation == variation}/>
                </View>
            </TouchableWithoutFeedback>
        )
    };

    renderModifierGroupHeader = (modifierGroup: ModifierGroup) => {
        return (
            <TouchableWithoutFeedback>
                <View style={containerStyles.borderBottom}>
                    <CartModifierGroupHeader
                        modifierGroup={modifierGroup}
                        isValid={this.isModifierGroupValid(modifierGroup)}
                        error={this.state.errorGroupId == modifierGroup.getId()}
                        totalQuantity={this.getTotalQuantityForModifierGroup(modifierGroup.getId())}
                        totalPrice={this.getTotalForModifierGroup(modifierGroup.getId())}/>
                </View>
            </TouchableWithoutFeedback>
        );
    };

    renderModifier = (modifier: Modifier) => {
        let cartModifiers = this.state.cartModifiers.filter(e => e.getModifierId() == modifier.getId());
        return (
            <TouchableWithoutFeedback>
                <View style={containerStyles.borderBottom}>
                    <CartModifierRow
                        modifier={modifier}
                        quantity={cartModifiers[0]?.getQuantity()}
                        onPress={() => this.onModifierPress(modifier)}
                        onPressDelete={() => this.onDecrementCartModifier(modifier)}/>
                </View>
            </TouchableWithoutFeedback>
        )
    };
}