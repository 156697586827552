import {TextInput, TextInputProps} from "react-native";
import React, {Component, RefObject} from "react";
import Field from "./Field";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
    label?: string
}

export default React.memo(class AddressField extends Component<Props> {

    render() {
        return (
            <Field
                maxLength={32}
                clearTextOnFocus={false}
                placeholder={this.props.label ? this.props.label : "Address"}
                keyboardType="default"
                autoCapitalize="words"
                autoCorrect={true}
                autoCompleteType="street-address"
                multiline={false}
                {...this.props}

                label={this.props.label ? this.props.label : "Address"}
                error={this.props.error}
                showSecureTextEntry={false}/>
        )
    };
});