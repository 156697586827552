import {Location} from "@emreat/proto/backend/v1/locations_pb";
import {Component, default as React, RefObject} from "react";
import {DeleteLocationRequest} from "@emreat/proto/backend/v1/locations_pb";
import {GrpcClient} from "../../GrpcClient";
import {LocationService} from "@emreat/proto/backend/v1/locations_pb_service";
import {getStore, RootState} from "../../reducers/reducers";
import {ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import SwipableButton from "../../common/buttons/SwipableButton";
import {connect} from "react-redux";
import {default as FastToast} from "react-native-fast-toast";

let mapStateToProps = (state: RootState) => ({
    selectedLocation: state.customer.selectedLocation,
});

interface Props {
    toastRef: RefObject<FastToast>
    selectedLocation: Location | null

    onPress: () => void
    onPressEdit: () => void

    location: Location
    disableDelete?: boolean
    last?: boolean
}

interface State {
    deleting: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false,
        };
    }

    onDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.delete();
            if (this.props.selectedLocation?.getId() == this.props.location.getId()) {
                getStore.dispatch({type: "SET_SELECTED_LOCATION", location: null});
            }
        } catch (e) {
            this.props.toastRef.current!.show(e.toString());
            this.props.toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    delete = async () => {
        let req = new DeleteLocationRequest();
        req.setId(this.props.location.getId());
        await GrpcClient.invokeWithAuth(LocationService.DeleteLocation, req);
        await getStore.dispatch({
            type: "REMOVE_LOCATIONS",
            locations: [this.props.location]
        });
    };

    render() {
        if (Platform.OS == 'web') {
            return (
                <TouchableOpacity onPress={this.props.onPress}>
                    {this.renderDetails()}
                </TouchableOpacity>
            )
        } else {
            return (
                <SwipableButton
                    deleting={this.state.deleting}
                    onPressDelete={this.onDelete}
                    onPressUpdate={this.props.onPressEdit}>
                    <View style={{backgroundColor: colorStyles.primaryBackground}}>
                        <TouchableOpacity onPress={this.props.onPress}>
                            {this.renderDetails()}
                        </TouchableOpacity>
                    </View>
                </SwipableButton>
            )
        }
    };

    renderDetails = () => {
        return (
            <View style={[
                containerStyles.paddingRowMedium, styles.container,
                !this.props.last ? containerStyles.borderBottom : null
            ]}>
                {this.renderLeftColumn()}
                {this.renderRightColumn()}
            </View>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.primaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.location.getLineOne()}
                </Text>
                {
                    this.props.location.getLineTwo()
                        ? (<Text style={[textStyles.secondarySubTitle, {textTransform: 'capitalize'}]}>
                            {this.props.location.getLineTwo()}
                        </Text>) : null
                }
                <Text style={[textStyles.secondarySubTitle, {textTransform: 'capitalize'}]}>
                    {this.props.location.getCity()}
                </Text>
                <Text style={[textStyles.secondarySubTitle, {textTransform: 'uppercase'}]}>
                    {this.props.location.getPostcode()}
                </Text>
            </View>
        )
    };

    renderRightColumn = () => {
        if (Platform.OS != 'web') {
            return this.renderDefaultLocation()
        } else {
            return (
                <View style={{flexDirection: "row"}}>
                    <TouchableOpacity onPress={this.props.onPressEdit}>
                        <MaterialIcons
                            name="edit"
                            size={18}
                            color={colorStyles.BLUE}
                            style={{padding: 10, paddingRight: 0}}/>
                    </TouchableOpacity>
                    {this.renderDelete()}
                    {this.renderDefaultLocation()}
                </View>
            )
        }
    };

    renderDelete = () => {
        if (this.state.deleting) {
            return (
                <View style={{padding: 10, paddingRight: 0}}>
                    <ActivityIndicator color={colorStyles.RED} size="small"/>
                </View>
            )
        }
        return (
            <TouchableOpacity onPress={this.onDelete}>
                <Ionicons
                    name="md-trash"
                    size={18}
                    color={colorStyles.RED}
                    style={{padding: 10, paddingRight: 0}}/>
            </TouchableOpacity>
        )
    };

    renderDefaultLocation = () => {
        if (this.props.location.getId() != this.props.selectedLocation?.getId()) {
            return null
        }
        return <Ionicons name="md-checkmark" size={22} color={colorStyles.RED} style={{padding: 10, paddingRight: 0}}/>
    }
})


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colorStyles.primaryBackground
    },
});
