import React, {RefObject} from "react";
import {default as FastToast} from "react-native-fast-toast";
import {Platform, SafeAreaView, View} from "react-native";

export const toastRef: RefObject<FastToast> = React.createRef();

interface Props {
    forwardRef: RefObject<FastToast>
}

export default (props: Props) => {
    return (
        <View style={{height: 0}}>
            <SafeAreaView>
                {/*@ts-ignore*/}
                <View style={{
                    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
                    bottom: 50, width: "100%", left: 0, right: 0,
                }}>
                    <FastToast
                        ref={props.forwardRef}
                        offset={20}
                        style={{borderRadius: 1, margin: -20}}
                        textStyle={{textAlign: "center"}}/>
                </View>
            </SafeAreaView>
        </View>
    )
}