import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import {Product} from "@emreat/proto/backend/v1/products_pb";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import {mergeArrays, removeFromArray} from "./common";
import {Merchant} from "@emreat/proto/backend/v1/merchants_pb";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {Review} from "@emreat/proto/backend/v1/reviews_pb";

export interface MerchantState {
    title: string,
    emailAddress: string,
    denominationSymbol: string
    stores: Array<Store>
    productGroups: Array<ProductGroup>
    products: Array<Product>
    modifierGroups: Array<ModifierGroup>
    reviews: Array<Review>
}

export type MerchantAction = {
    readonly type: "SET_MERCHANT",
    readonly merchant: Merchant,
} | {
    readonly type: "SET_STORES",
    readonly stores: Array<Store>,
} | {
    readonly type: "SET_PRODUCT_GROUPS",
    readonly productGroups: Array<ProductGroup>,
} | {
    readonly type: "SET_PRODUCTS",
    readonly products: Array<Product>,
} | {
    readonly type: "SET_MODIFIER_GROUPS",
    readonly modifierGroups: Array<ModifierGroup>,
} | {
    readonly type: "SET_REVIEWS",
    readonly reviews: Array<Review>,
} | {
    readonly type: "REMOVE_PRODUCT_GROUPS",
    readonly productGroups: Array<ProductGroup>,
} | {
    readonly type: "REMOVE_PRODUCTS",
    readonly products: Array<Product>,
} | {
    readonly type: "REMOVE_MODIFIER_GROUPS",
    readonly modifierGroups: Array<ModifierGroup>,
}

const initialState: MerchantState = {
    title: '',
    emailAddress: '',
    denominationSymbol: '',
    stores: [],
    productGroups: [],
    products: [],
    modifierGroups: [],
    reviews: [],
};

export function merchant(state: MerchantState = initialState, action: MerchantAction): MerchantState {
    switch (action.type) {
        case "SET_MERCHANT":
            return {
                ...state,
                title: action.merchant.getTitle(),
                emailAddress: action.merchant.getEmailAddress(),
                denominationSymbol: action.merchant.getDenominationSymbol(),
                stores: action.merchant.getStoresList(),
                productGroups: action.merchant.getProductGroupsList(),
                products: action.merchant.getProductsList(),
                modifierGroups: action.merchant.getModifierGroupsList(),
            };
        case "SET_STORES":
            return {...state, stores: mergeArrays(state.stores, action.stores, true)};
        case "SET_PRODUCT_GROUPS":
            return {...state, productGroups: mergeArrays(state.productGroups, action.productGroups, true)};
        case "SET_PRODUCTS":
            return {...state, products: mergeArrays(state.products, action.products, true)};
        case "SET_MODIFIER_GROUPS":
            return {...state, modifierGroups: mergeArrays(state.modifierGroups, action.modifierGroups, true)};
        case "SET_REVIEWS":
            return {...state, reviews: mergeArrays(state.reviews, action.reviews)};
        case "REMOVE_PRODUCT_GROUPS":
            return {...state, productGroups: removeFromArray(state.productGroups, action.productGroups)};
        case "REMOVE_PRODUCTS":
            return {...state, products: removeFromArray(state.products, action.products)};
        case "REMOVE_MODIFIER_GROUPS":
            return {...state, modifierGroups: removeFromArray(state.modifierGroups, action.modifierGroups)};
        default:
            return state;
    }
}