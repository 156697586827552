import React, {Component, PropsWithChildren} from "react";
import {GestureResponderEvent, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle} from "react-native";
import {FontAwesome} from "@expo/vector-icons";
import {View as AnimatableView} from 'react-native-animatable';

interface Props {
    disabled: boolean
    maxStars: number
    rating: number
    starColor: string
    starSize: number
    starStyle: StyleProp<ViewStyle>
    onChange: (rating: number) => void
}

export default function StarRatingButton(props: PropsWithChildren<Props>) {

    // Round rating down to nearest .5 star
    let starsLeft = Math.round(props.rating * 2) / 2;
    const starButtons = [];
    for (let i = 0; i < props.maxStars; i++) {
        let starIconName = "star-o";
        if (starsLeft >= 1) {
            starIconName = "star";
        } else if (starsLeft === 0.5) {
            starIconName = "star-half-o";
        }
        const starButtonElement = (
            <AnimatableView key={i}>
                <StarButton
                    disabled={props.disabled}
                    onStarButtonPress={props.onChange}
                    rating={i + 1}
                    starColor={props.starColor}
                    starIconName={starIconName}
                    starSize={props.starSize}
                    starStyle={props.starStyle}
                />
            </AnimatableView>
        );
        starButtons.push(starButtonElement);
        starsLeft -= 1;
    }

    return (
        <View style={{flexDirection: 'row'}} pointerEvents={props.disabled ? 'none' : 'auto'}>
            {starButtons}
        </View>
    );
}

interface StarButtonProps {
    starStyle: StyleProp<ViewStyle>
    onStarButtonPress: (rating: number) => void
    starSize: number
    disabled: boolean
    rating: number
    starColor: string
    starIconName: string
}

class StarButton extends Component<StarButtonProps> {

    onButtonPress = (event: GestureResponderEvent) => {
        const isHalfSelected = event.nativeEvent.locationX < this.props.starSize / 2;
        let addition = isHalfSelected ? -0.5 : 0;
        this.props.onStarButtonPress(this.props.rating + addition);
    };

    render() {
        return (
            <TouchableOpacity
                activeOpacity={0.2}
                style={{marginRight: 5}}
                disabled={this.props.disabled}
                onPress={this.onButtonPress}>
                <FontAwesome
                    name={this.props.starIconName}
                    size={this.props.starSize}
                    color={this.props.starColor}
                    style={{transform: [{scaleX: 1,}], ...StyleSheet.flatten(this.props.starStyle)}}/>
            </TouchableOpacity>
        );
    }
}