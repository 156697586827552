import * as React from "react";
import {Component} from "react";
import {Platform, Text, View} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, containerStyles, textStyles} from "../Styles";
import {HomeNavigatorParamList} from "../Navigator";
import SubmitButton from "../common/buttons/SubmitButton";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import SingleLayout from "../common/layouts/SingleLayout";

interface Props {
    navigation?: StackNavigationProp<HomeNavigatorParamList>
}

export default class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        if (this.props.navigation) {
            this.props.navigation.setOptions({
                title: 'Upgrade Required',
            })
        }
    };

    upgrade = async () => {
        if (Platform.OS == "android") {
            await Linking.openURL(`https://play.google.com/store/apps/details?id=${Constants.manifest.extra.androidId}`);
        }
        if (Platform.OS == "ios") {
            await Linking.openURL(`https://apps.apple.com/gb/app/${Constants.manifest.extra.appleId}`);
        }
    }

    render() {
        if (!this.props.navigation) {
            return this.renderUpgrade()
        }
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderUpgrade()}
            </SingleLayout>
        );
    };

    renderUpgrade = () => {
        return (
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Ionicons name="md-alert" color={colorStyles.secondaryDarkText} size={65}/>
                <Text style={[textStyles.primaryTitle, {marginTop: 5}]}>Upgrade required!</Text>
                <Text style={[textStyles.primarySubTitle, containerStyles.paddingRowMedium, {marginTop: 5, textAlign: 'center'}]}>
                    You are using an old version of this app, which is no longer supported.
                    Please upgrade your app to continue.
                </Text>

                <View style={[containerStyles.paddingRowMediumTop, {paddingLeft: 25, paddingRight: 25, width: '100%', maxWidth: 500}]}>
                    <SubmitButton
                        text="Upgrade"
                        inActive={false}
                        submitting={false}
                        onPress={this.upgrade}
                        disabled={false}/>
                </View>
            </View>
        )
    }
};
