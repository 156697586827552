import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {FontAwesome} from "@expo/vector-icons";
import {Variation} from "@emreat/proto/backend/v1/variations_pb";
import MoneyView from "../../common/views/MoneyView";

interface Props {
    variation: Variation
    onPress: () => void
    selected: boolean
}

export default class extends Component<Props> {
    render() {
        return (
            <TouchableOpacity onPress={this.props.onPress}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 25},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1, flexDirection: "row", alignItems: "center"}}>
                {this.renderSelected()}
                <Text style={[
                    textStyles.primaryTitle,
                    {color: this.props.selected ? colorStyles.GREEN : colorStyles.DARK_TEXT, textTransform: 'capitalize'}
                ]}>
                    {this.props.variation.getTitle()}
                </Text>
            </View>
        )
    };

    renderSelected  = () => {
        if (!this.props.selected) {
            return null
        }
        return <FontAwesome name="circle" size={12} style={{color: colorStyles.GREEN, marginRight: 10}}/>
    };

    renderRightColumn = () => {
        if (!this.props.variation.getBasePrice()) {
            return null
        }
        return (
            <Text style={[textStyles.primarySubTitle, this.props.selected ? {color: colorStyles.GREEN} : {}]}>
                <MoneyView value={this.props.variation.getBasePrice()}/>
            </Text>
        )
    };
}