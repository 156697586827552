import {Review} from "@emreat/proto/backend/v1/reviews_pb";
import {useNavigation} from "@react-navigation/native";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import * as React from "react";

interface Props {
    orderId: string
}

export default (props: Props) => {
    const navigation = useNavigation();

    return (
        <View style={[containerStyles.paddingRowMedium, containerStyles.borderBottom]}>
            <TouchableOpacity onPress={() => navigation.navigate("Review", {orderId: props.orderId})}>
                <View style={[styles.container, containerStyles.border]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons name="md-quote" size={28} style={{color: colorStyles.BLUE, marginRight: 10}}/>
                        <Text style={[textStyles.secondaryHeadline, {color: colorStyles.BLUE}]}>
                            Review your order
                        </Text>
                    </View>
                    <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>Help others find great meals</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        marginTop: 0,
        padding: 15,
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorStyles.LIGHT_BLUE,
    },
});