import {FlatList, Platform, RefreshControl, View} from "react-native";
import * as React from "react";
import {Component, RefObject} from "react";
import {RootState} from "../../reducers/reducers";
import {StackNavigationProp} from "@react-navigation/stack";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import ProductGroupCollapsable from "../../components/menu/ProductGroupCollapsable";
import ScreenLayout from "../../common/layouts/ScreenLayout";
import ProductGroupMenu from "../../components/menu/ProductGroupMenu";
import HeaderButton from "../../common/buttons/HeaderButton";
import {toastRef} from "../../common/views/ToastView";
import {Service} from "../../Service";
import {containerStyles} from "../../Styles";
import ProductGroupRow from "../../components/menu/ProductGroupRow";
import CheckoutButton from "../../common/buttons/CheckoutButton";
import MerchantHeader from "../../components/menu/MerchantHeader";
import StickyCart from "../../components/checkout/StickyCart";
import {User} from "@emreat/proto/backend/v1/users_pb";
import ClosedView from "../../common/components/ClosedView";
import * as Linking from "expo-linking";
import Constants from "expo-constants";

let mapStateToProps = (state: RootState) => ({
    title: state.merchant.title,
    user: state.auth.user,
    stores: state.merchant.stores,
    productGroups: state.merchant.productGroups,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, "Menu">, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, "Menu">
    title: string
    user: User | null
    stores: Array<Store>
    productGroups: Array<ProductGroup>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let productGroups = props.productGroups.filter(e => props.stores[0].getProductGroupIdsList().includes(e.getId()));
    return <Screen
        navigation={props.navigation}
        title={props.title}
        user={props.user}
        store={props.stores[0]}
        productGroups={productGroups}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList>, StackNavigationProp<RootNavigatorParamList>>
    title: string
    user: User | null
    store: Store
    productGroups: Array<ProductGroup>
}

interface State {
    productGroupRefs: Map<string, RefObject<any>>
    refreshing: boolean
}

class Screen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            productGroupRefs: new Map(props.productGroups.map(e => [e.getId(), React.createRef()])),
            refreshing: false,
        };
    }

    componentDidMount() {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: Platform.OS == 'web' ? this.props.title : "Menu",
            headerLeft: () => (
                <HeaderButton
                    left={true}
                    onPress={() => this.props.navigation.navigate("Information", {})}
                    icon="ios-information-circle-outline"/>
            ),
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.props.navigation.navigate("Search", {})}
                    icon="md-search"/>
            ),
        });
    };

    refresh = async () => {
        this.setState({refreshing: true});
        try {
            await Service.initMerchant();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    render() {
        if (Platform.OS != 'web') {
            return (
                <ScreenLayout navigation={this.props.navigation} light>
                    {this.renderNativeProductGroups()}
                    <CheckoutButton/>
                    <ClosedView/>
                </ScreenLayout>
            )
        }
        return (
            <ScreenLayout navigation={this.props.navigation} disableBanner>
                <View style={containerStyles.page}>
                    <View style={{flex: 1, width: "100%", alignItems: 'center', justifyContent: 'center'}}>
                        <View style={{flex: 1, flexDirection: 'row', width: '100%'}}>
                            {this.renderLeft()}
                            {this.renderCenter()}
                            {this.renderRight()}
                        </View>
                    </View>
                    <CheckoutButton/>
                    <ClosedView/>
                </View>
            </ScreenLayout>
        )
    }

    renderLeft = () => {
        return (
            <ProductGroupMenu
                items={this.props.productGroups.map(e => ({id: e.getId(), title: e.getTitle()}))}
                itemRef={this.state.productGroupRefs}/>
        )
    };

    renderCenter = () => {
        return (
            <View style={{flex: 7}}>
                {this.props.productGroups.map((e, i) => (
                    <View ref={this.state.productGroupRefs.get(e.getId())} key={e.getId()}>
                        <ProductGroupCollapsable
                            productGroup={e}
                            onPress={() => this.props.navigation.navigate("Products", {productGroupId: e.getId()})}
                            last={i == this.props.productGroups.length - 1}/>
                    </View>
                ))}
            </View>
        )
    };

    renderRight = () => {
        return <StickyCart
            button="Go to checkout"
            onPress={() => {
                if (!this.props.user) {
                    this.props.navigation.navigate('Auth', {screen: "Login"})
                } else {
                    this.props.navigation.navigate('Checkout', {})
                }
            }}/>
    };

    renderNativeProductGroups = () => {
        return (
            <FlatList
                ListHeaderComponent={<MerchantHeader title={this.props.title} store={this.props.store}/>}
                showsVerticalScrollIndicator={false}
                data={this.props.productGroups}
                keyExtractor={item => item.getId()}
                renderItem={item => (
                    <ProductGroupRow
                        productGroup={item.item}
                        onPress={() => this.props.navigation.navigate("Products", {productGroupId: item.item.getId()})}
                        last={item.index == this.props.productGroups.length - 1}/>
                )}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    }
}