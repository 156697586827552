import {Product} from "@emreat/proto/backend/v1/products_pb";
import {Component, default as React, RefObject} from "react";
import {Platform, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import CenterModal from "../../common/modals/CenterModal";
import MoneyView from "../../common/views/MoneyView";
import {ImageView} from "../../common/views/ImageView";
import BottomModal from "../../common/modals/BottomModal";
import CartItemModal from "../cart_item/CartItemModal";
import {toastRef} from "../../common/views/ToastView";
import {DeleteProductRequest} from "@emreat/proto/backend/v1/products_pb";
import {GrpcClient} from "../../GrpcClient";
import {ProductService} from "@emreat/proto/backend/v1/products_pb_service";
import SwipableButton from "../../common/buttons/SwipableButton";
import {getStore} from "../../reducers/reducers";

interface Props {
    product: Product
    productGroupId?: string
    onPressEdit?: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    modalRef: RefObject<CenterModal | BottomModal>;

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
        this.modalRef = React.createRef();
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteProduct(this.props.product.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteProduct = async (id: string) => {
        let req = new DeleteProductRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(ProductService.DeleteProduct, req);
        getStore.dispatch({type: "REMOVE_PRODUCTS", products: [this.props.product]});
    };

    render() {
        if (this.props.onPressEdit) {
            return (
                <SwipableButton
                    deleting={this.state.deleting}
                    onPressDelete={this.onPressDelete}
                    onPressUpdate={this.props.onPressEdit}>
                    <View style={{backgroundColor: colorStyles.primaryBackground}}>
                        {this.renderButton()}
                    </View>
                </SwipableButton>
            )
        } else {
            return this.renderButton()
        }
    };

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPressEdit ? this.props.onPressEdit() : this.modalRef.current!.open()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                {this.renderDetails()}
            </TouchableOpacity>
        )
    };

    renderDetails = () => {
        return (
            <View style={[
                containerStyles.paddingRowMedium,
                {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
            ]}>
                {this.renderLeftColumn()}
                {this.renderImage()}
                {this.renderArrow()}
                {
                    !this.props.onPressEdit
                        ? <CartItemModal
                            forwardRef={this.modalRef}
                            product={this.props.product}
                            productGroupId={this.props.productGroupId!}/>
                        : null
                }
            </View>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.primaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.product.getTitle()}
                </Text>
                {
                    this.props.product.getSubTitle()
                        ? <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                            {this.props.product.getSubTitle()}
                        </Text>
                        : null
                }
                <Text style={[textStyles.secondarySubTitle, {marginTop: 10, color: colorStyles.RED}]}>
                    {this.props.product.getVariationsList().length > 1 ? 'From ' : ''}
                    <MoneyView value={Math.min(...this.props.product.getVariationsList().map(v => v.getBasePrice()))}/>
                </Text>
            </View>
        )
    };

    renderImage = () => {
        if (!this.props.product.getImageId()) {
            return null
        } else {
            return (
                <View style={{justifyContent: "center",}}>
                    <ImageView imageUrl={this.props.product.getImageUrl()} style={{height: 100, width: 120, marginLeft: 10}}/>
                </View>
            )
        }
    };

    renderArrow = () => {
        if (Platform.OS == 'web') {
            return null
        } else {
            return (
                <View style={{paddingLeft: 10, paddingRight: 5}}>
                    <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED}}/>
                </View>
            )
        }
    };
}