import * as React from "react";
import {Component, RefObject} from "react";
import {Platform, Text, TextInput, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Review, ReviewUpdateMask, ReviewUpdateMaskMap} from "@emreat/proto/backend/v1/reviews_pb";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import StarRatingButton from "../../common/buttons/StarRatingButton";
import HeaderButton from "../../common/buttons/HeaderButton";
import SubmitButton from "../../common/buttons/SubmitButton";
import ScrollView from "../../common/views/ScrollView";
import {toastRef} from "../../common/views/ToastView";
import {getStore, RootState} from "../../reducers/reducers";
import {GrpcClient} from "../../GrpcClient";
import Constants from "expo-constants";
import {CreateReviewRequest, UpdateReviewRequest} from "@emreat/proto/backend/v1/reviews_pb";
import {ReviewService} from "@emreat/proto/backend/v1/reviews_pb_service";
import {connect} from "react-redux";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import NotFoundScreen from "../NotFoundScreen";
import SingleLayout from "../../common/layouts/SingleLayout";
import {Service} from "../../Service";

let mapStateToProps = (state: RootState) => ({
    reviews: state.merchant.reviews
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Review'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Review'>

    reviews: Array<Review>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let review = props.reviews.filter(e => e.getId() == props.route.params?.id)[0];
    if ((props.route.params?.id && !review) || !props.route.params?.orderId) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} review={review} orderId={props.route.params!.orderId}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>
    review: Review | null
    orderId: string
}

interface State {
    qualityRating: number
    serviceRating: number
    deliveryTimeRating: number
    comment: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    commentRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            qualityRating: this.props.review ? this.props.review.getQualityRating() : 0,
            serviceRating: this.props.review ? this.props.review.getServiceRating() : 0,
            deliveryTimeRating: this.props.review ? this.props.review.getDeliveryTimeRating() : 0,
            comment: this.props.review ? this.props.review.getCustomerComment() : "",

            submitting: false,
        };
        this.commentRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.review) {
            this.props.navigation.setOptions({
                title: 'Edit review',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'New review',
            })
        }
    };

    isValid = (): boolean => {
        if (this.state.qualityRating == 0) {
            return false
        }
        if (this.state.deliveryTimeRating == 0) {
            return false
        }
        if (this.state.serviceRating == 0) {
            return false
        }

        if (this.props.review) {
            return this.state.qualityRating != this.props.review.getQualityRating() ||
                this.state.deliveryTimeRating != this.props.review.getDeliveryTimeRating() ||
                this.state.serviceRating != this.props.review.getServiceRating() ||
                this.state.comment != this.props.review.getCustomerComment();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.review) {
                await this.updateReview()
            } else {
                await this.createReview()
            }

            if (Platform.OS == 'web') {
                this.props.navigation.navigate("Reviews", {})
            } else {
                this.props.navigation.pop()
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createReview = async () => {
        let review = new Review();
        review.setUserId(getStore.getState().auth.user!.getId());
        review.setMerchantId(Constants.manifest.extra.merchantId);
        review.setStoreId(getStore.getState().merchant.stores[0].getId());
        review.setOrderId(this.props.orderId!);
        review.setServiceRating(this.state.serviceRating);
        review.setQualityRating(this.state.qualityRating);
        review.setDeliveryTimeRating(this.state.deliveryTimeRating);
        review.setCustomerComment(this.state.comment);
        review.setCustomerName(getStore.getState().auth.user!.getFirstName());

        let req = new CreateReviewRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setReview(review);
        let resp: Review = await GrpcClient.invokeWithAuth(ReviewService.CreateReview, req);
        getStore.dispatch({type: "SET_REVIEWS", reviews: [resp]});

        let order = getStore.getState().customer.orders.filter(e => e.getId() == this.props.orderId)[0];
        order.setReview(resp);
        getStore.dispatch({type: "SET_ORDERS", orders: [order]});
    };

    updateReview = async () => {
        let updateMasks: Array<ReviewUpdateMaskMap[keyof ReviewUpdateMaskMap]> = [];

        let review = new Review();
        review.setId(this.props.review!.getId());
        if (this.state.qualityRating != this.props.review!.getQualityRating()) {
            review.setQualityRating(this.state.qualityRating);
            updateMasks.push(ReviewUpdateMask.REVIEW_UPDATE_MASK_QUALITY_RATING)
        }
        if (this.state.deliveryTimeRating != this.props.review!.getDeliveryTimeRating()) {
            review.setDeliveryTimeRating(this.state.deliveryTimeRating);
            updateMasks.push(ReviewUpdateMask.REVIEW_UPDATE_MASK_DELIVERY_TIME_RATING)
        }
        if (this.state.serviceRating != this.props.review!.getServiceRating()) {
            review.setServiceRating(this.state.serviceRating);
            updateMasks.push(ReviewUpdateMask.REVIEW_UPDATE_MASK_SERVICE_RATING)
        }
        if (this.state.comment != this.props.review!.getCustomerComment()) {
            review.setCustomerComment(this.state.comment);
            updateMasks.push(ReviewUpdateMask.REVIEW_UPDATE_MASK_CUSTOMER_COMMENT)
        }

        let req = new UpdateReviewRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setReview(review);
        req.setUpdateMasksList(updateMasks);
        let resp: Review = await GrpcClient.invokeWithAuth(ReviewService.UpdateReview, req);
        getStore.dispatch({type: "SET_REVIEWS", reviews: [resp]});

        let order = getStore.getState().customer.orders.filter(e => e.getId() == this.props.orderId)[0];
        order.setReview(resp);
        getStore.dispatch({type: "SET_ORDERS", orders: [order]});
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} title="Review Order">
                <ScrollView>
                    <View style={containerStyles.paddingRowMedium}>
                        {this.renderQualityInput("Food quality", this.state.qualityRating, (rating: number) => this.setState({qualityRating: rating * 100}))}
                        {this.renderQualityInput("Restaurant service", this.state.serviceRating, (rating: number) => this.setState({serviceRating: rating * 100}))}
                        {this.renderQualityInput("Delivery time", this.state.deliveryTimeRating, (rating: number) => this.setState({deliveryTimeRating: rating * 100}))}
                        {this.renderLeaveAReviewInput()}
                    </View>
                </ScrollView>
                {
                    Platform.OS == 'web'
                        ? (
                            <View style={containerStyles.paddingRowMedium}>
                                <SubmitButton
                                    text='Save'
                                    onPress={() => this.onSubmit()}
                                    submitting={this.state.submitting}
                                    disabled={!this.isValid()}
                                    inActive={!this.isValid() || this.state.submitting}/>
                            </View>
                        ) : null
                }
            </SingleLayout>
        )
    };

    renderQualityInput = (label: string, rating: number, onChange: (rating: number) => void) => {
        return (
            <View style={{marginTop: 30}}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={[textStyles.secondaryHeadline, {flex: 1}]}>{label}</Text>
                    {this.renderRatingLabel(rating)}
                </View>
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 3}}>
                        <StarRatingButton
                            disabled={false}
                            maxStars={6}
                            rating={rating / 100}
                            onChange={onChange}
                            starColor={colorStyles.RED}
                            starSize={30}
                            starStyle={{marginTop: 10}}/>
                    </View>
                    <View style={{flex: 2}}/>
                </View>
            </View>
        )
    };

    renderRatingLabel = (rating: number) => {
        const ratingLabels: { [key: number]: string } = {
            100: 'Abysmal',
            150: 'Abysmal',
            200: 'Poor',
            250: 'Poor',
            300: 'Alright',
            350: 'Alright',
            400: 'Good',
            450: 'Good',
            500: 'Great',
            550: 'Great',
            600: 'Outstanding',
        };
        const backgroundColors: { [key: number]: string } = {
            100: '#FCEDEE',
            150: '#FCEDEE',
            200: '#FCEDEE',
            250: '#FCEDEE',
            300: '#FDEFD9',
            350: '#FDEFD9',
            400: '#FDEFD9',
            450: '#FDEFD9',
            500: '#F2FAE2',
            550: '#F2FAE2',
            600: '#F2FAE2',
        };
        const textColors: { [key: number]: string } = {
            100: '#D63E2A',
            150: '#D63E2A',
            200: '#D63E2A',
            250: '#D63E2A',
            300: '#2A3846',
            350: '#2A3846',
            400: '#2A3846',
            450: '#2A3846',
            500: '#2D6732',
            550: '#2D6732',
            600: '#2D6732',
        };

        if (rating == 0) {
            return null
        }
        return (
            <View style={[
                {padding: 3, justifyContent: 'center', alignItems: 'center', borderRadius: 2},
                {backgroundColor: backgroundColors[rating]}]}>
                <Text style={{color: textColors[rating]}}>{ratingLabels[rating]}</Text>
            </View>
        )
    };

    renderLeaveAReviewInput = () => {
        return (
            <View style={{marginTop: 20}}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={[textStyles.secondaryHeadline, {flex: 1}]}>Leave a review</Text>
                </View>
                <TextInput
                    ref={this.commentRef}
                    value={this.state.comment}
                    onChangeText={v => this.setState({comment: v.replace(/\n\s*\n/g, "\n")})}
                    onSubmitEditing={() => this.commentRef.current!.blur()}
                    returnKeyType={"done"}
                    placeholderTextColor="#C5CCD3"
                    placeholder="Tell us more about your experience"
                    keyboardType="default"
                    autoCapitalize="sentences"
                    autoCorrect={true}
                    enablesReturnKeyAutomatically={true}
                    multiline={true}
                    scrollEnabled={true}
                    style={{
                        borderWidth: 1,
                        borderColor: "#C5CCD3",
                        borderRadius: 2,
                        marginTop: 10,
                        padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        height: 150,
                    }}>
                </TextInput>
            </View>
        )
    };
}
