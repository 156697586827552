import {Component, default as React, RefObject} from "react";
import {RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import {connect} from "react-redux";
import BottomModal from "../../common/modals/BottomModal";
import CenterModal from "../../common/modals/CenterModal";
import {Location} from "@emreat/proto/backend/v1/locations_pb";
import LocationsView from "../account/LocationsView";
import {View} from "react-native";
import {containerStyles} from "../../Styles";
import SubmitButton from "../../common/buttons/SubmitButton";
import {default as FastToast} from "react-native-fast-toast";
import ToastView from "../../common/views/ToastView";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    locations: state.customer.locations,
    selectedLocation: state.customer.selectedLocation,
});

interface Props {
    screenSize: ScreenSize
    forwardRef: RefObject<CenterModal | BottomModal>
    locations: Array<Location>
    selectedLocation: Location | null
    onPressNewLocation: () => void
    onPressEditLocation: (id: string) => void
}

export default connect(mapStateToProps)(class extends Component<Props> {

    toastRef: RefObject<FastToast>;

    constructor(props: Props) {
        super(props);
        this.toastRef = React.createRef();
    }

    render() {
        if (this.props.screenSize == 'SMALL') {
            return (
                <BottomModal ref={this.props.forwardRef as RefObject<BottomModal>}>
                    {this.renderLocations()}
                </BottomModal>
            )
        } else {
            return (
                <CenterModal ref={this.props.forwardRef as RefObject<CenterModal>} dismiss={true}>
                    {this.renderLocations()}
                </CenterModal>
            )
        }
    };

    renderLocations = () => {
        return (
            <View style={[{flex: 1}, containerStyles.paddingContainer]}>
                <LocationsView
                    toastRef={this.toastRef}
                    onPressLocation={() => null}
                    onPressEditLocation={this.props.onPressEditLocation}/>
                <View style={containerStyles.paddingRowMedium}>
                    <SubmitButton
                        text='New Address'
                        onPress={() => this.props.onPressNewLocation()}
                        submitting={false}
                        disabled={false}
                        inActive={false}/>
                </View>
                <ToastView forwardRef={this.toastRef}/>
            </View>
        )
    }
})