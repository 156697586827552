import {Component, default as React} from "react";
import {Dimensions, Platform, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {ImageView} from "../../common/views/ImageView";
import SearchView from "../../common/components/SearchView";
import AllergyView from "../../common/components/AllergyView";
import RatingView from "../../common/views/RatingView";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
});

interface Props {
    screenSize: ScreenSize
    title: string
    store: Store
}

export default connect(mapStateToProps)(class extends Component<Props> {

    render() {
        return (
            <View>
                {this.renderImage()}
                {this.renderHeader()}
                <AllergyView/>
                <SearchView/>
            </View>
        )
    };

    renderImage = () => {
        return (
            <View style={{maxHeight: 225, width: Dimensions.get("screen").width + 10, marginLeft: -5}}>
                <ImageView imageUrl={this.props.store.getImageUrl()}/>
            </View>
        )
    };

    renderHeader = () => {
        return (
            <View style={[containerStyles.paddingRowMedium, containerStyles.borderBottom]}>
                <Text style={[textStyles.secondaryHeadline, {textTransform: 'capitalize'}]}>
                    {this.props.title}
                </Text>
                <Text style={[textStyles.secondarySubTitle, {marginTop: 10, textTransform: 'capitalize'}]}>
                    {this.props.store.getProductTagsList().join(" · ")}
                </Text>
                {this.renderRating()}
            </View>
        )
    };

    renderRating = () => {
        return (
            <View style={{flexDirection: 'row', alignItems: "center"}}>
                <RatingView score={this.props.store.getRating() ? this.props.store.getRating()!.getScore() : 0}/>
                <ViewReviews store={this.props.store}/>
            </View>
        )
    };
})

interface ViewReviewsProps {
    store: Store
}

const ViewReviews = (props: ViewReviewsProps) => {
    if (!props.store.getRating()) {
        return null
    }
    const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => navigation.navigate("Reviews", {})}>
            <Text style={{marginTop: 10, color: colorStyles.BLUE, fontSize: 12, fontWeight: '500'}}>
                {
                    props.store.getRating()!.getCount() == 1
                        ? `   View ${props.store.getRating()!.getCount()} review`
                        : `   View ${props.store.getRating()!.getCount()} reviews`
                }
            </Text>
        </TouchableOpacity>
    )
};