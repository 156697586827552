import {mergeArrays, removeFromArray} from "./common";
import {Location} from "@emreat/proto/backend/v1/locations_pb";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {Order} from "@emreat/proto/backend/v1/orders_pb";

export interface CustomerState {
    locations: Array<Location>
    selectedLocation: Location | null
    cart: Cart | null
    orders: Array<Order>
}

export type CustomerAction = {
    readonly type: "SET_LOCATIONS",
    readonly locations: Array<Location>,
} | {
    readonly type: "SET_SELECTED_LOCATION",
    readonly location: Location | null,
} | {
    readonly type: "REMOVE_LOCATIONS",
    readonly locations: Array<Location>,
} | {
    readonly type: "SET_CART",
    readonly cart: Cart | null,
} | {
    readonly type: "SET_ORDERS",
    readonly orders: Array<Order>,
} | {
    readonly type: "CLEAR_CUSTOMER",
}

const initialState: CustomerState = {
    locations: [],
    selectedLocation: null,
    cart: null,
    orders: [],
};

export function customer(state: CustomerState = initialState, action: CustomerAction): CustomerState {
    switch (action.type) {
        case "SET_LOCATIONS":
            state = {...state, locations: mergeArrays(state.locations, action.locations)};
            if (!state.selectedLocation) {
                state.selectedLocation = state.locations[0];
            }
            return state;
        case "SET_SELECTED_LOCATION":
            return {...state, selectedLocation: action.location};
        case "REMOVE_LOCATIONS":
            return {...state, locations: removeFromArray(state.locations, action.locations)};
        case "SET_CART":
            return {...state, cart: action.cart};
        case "SET_ORDERS":
            return {...state, orders: mergeArrays(state.orders, action.orders)};
        case "CLEAR_CUSTOMER":
            return {...initialState};
        default:
            return state;
    }
}