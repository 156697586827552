import * as React from "react";
import {Component} from "react";
import {Text, View} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, textStyles} from "../Styles";
import SingleLayout from "../common/layouts/SingleLayout";

interface Props {
    navigation: StackNavigationProp<any>
}

export default class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: '404 Not Found',
        })
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation}>
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Ionicons name="md-alert" color={colorStyles.secondaryDarkText} size={65}/>
                    <Text style={[textStyles.primaryTitle, {marginTop: 5}]}>404 Not found!</Text>
                    <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                        Sorry! We could not find the page you are looking for.
                    </Text>
                </View>
            </SingleLayout>
        );
    };
};