import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {Component, default as React} from "react";
import {DeleteHourRequest, Hour} from "@emreat/proto/backend/v1/hours_pb";
import {getStore} from "../../reducers/reducers";
import {HourService} from "@emreat/proto/backend/v1/hours_pb_service";
import {dayOfTheWeekProtoToString} from "../../Constants";
import moment from "moment";

interface Props {
    hour: Hour
    onPressEdit: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteHour(this.props.hour.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteHour = async (id: string) => {
        let req = new DeleteHourRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(HourService.DeleteHour, req);
        let store = getStore.getState().merchant.stores[0];
        store.setHoursList(store.getHoursList().filter(e => e.getId() != this.props.hour.getId()));
        getStore.dispatch({type: "SET_STORES", stores: [store]});
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}
                onPressUpdate={this.props.onPressEdit}>
                <View style={{backgroundColor: colorStyles.primaryBackground}}>
                    {this.renderButton()}
                </View>
            </SwipableButton>
        )
    }

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPressEdit()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                {
                    this.props.hour.getDaysOfTheWeekList().map(e => {
                        return <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                            {dayOfTheWeekProtoToString[e]}
                        </Text>
                    })
                }
            </View>
        )
    };

    renderRightColumn = () => {
        let openTime = moment(`${this.props.hour.getFromHour()}:${this.props.hour.getFromMinute()}`, "HH:mm");
        let closeTime = moment(`${this.props.hour.getToHour()}:${this.props.hour.getToMinute()}`, "HH:mm");
        return (
            <Text style={[textStyles.secondarySubTitle, {color: colorStyles.RED}]}>
                {openTime.format("HH:mm")} - {closeTime.format("HH:mm")}
            </Text>
        )
    };
};