import * as React from "react";
import {Component, RefObject} from "react";
import {StackNavigationProp} from "@react-navigation/stack";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {
    Cart,
    CartDispatchMethod,
    CartDispatchMethodMap,
    CartUpdateMask,
    CartUpdateMaskMap
} from "@emreat/proto/backend/v1/carts_pb";
import {
    Keyboard,
    Platform,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from "react-native";
import {colorStyles, containerStyles, textStyles, toProperCase} from "../../Styles";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {Location} from "@emreat/proto/backend/v1/locations_pb";
import {Payment, PaymentGateway, PaymentGatewayMap} from "@emreat/proto/backend/v1/payments_pb";
import Picker, {Item} from "react-native-picker-select";
import moment, {Moment} from "moment";
import {FontAwesome, Ionicons} from "@expo/vector-icons"
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {UpdateCartRequest} from "@emreat/proto/backend/v1/carts_pb";
import {GrpcClient} from "../../GrpcClient";
import {CartService} from "@emreat/proto/backend/v1/carts_pb_service";
import Constants from "expo-constants";
import {CreatePaymentRequest} from "@emreat/proto/backend/v1/payments_pb";
import {PaymentService} from "@emreat/proto/backend/v1/payments_pb_service";
import * as Linking from 'expo-linking';
import * as WebBrowser from "expo-web-browser";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import {Service} from "../../Service";
import {HomeNavigatorParamList} from "../../Navigator";
import NotFoundScreen from "../NotFoundScreen";
import CenterModal from "../../common/modals/CenterModal";
import PaymentUrlComponent from "../../components/checkout/PaymentUrl";
import {dayOfTheWeekToProto, dispatchMethodToName, gatewayToIcon, gatewayToName} from "../../Constants";
import {formatMoney} from "../../common/views/MoneyView";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import DividerView from "../../common/views/DividerView";
import CancelButton from "../../common/buttons/CancelButton";
import SelectorField from "../../common/fields/SelectorField";
import NameField from "../../common/fields/Name";
import PhoneField from "../../common/fields/Phone";
import FieldErrors from "../../common/fields/Errors";
import TextField from "../../common/fields/TextField";
import {Divider} from "react-native-paper";
import SubmitButton from "../../common/buttons/SubmitButton";
import ScrollView from "../../common/views/ScrollView";
import SingleLayout from "../../common/layouts/SingleLayout";
import LocationModal from "../../components/checkout/LocationModal";
import BottomModal from "../../common/modals/BottomModal";
import {loadStripe} from '@stripe/stripe-js';
import PasswordField from "../../common/fields/Password";

let mapStateToProps = (state: RootState) => ({
    cart: state.customer.cart!,
    store: state.merchant.stores[0],
    user: state.auth.user!,
    locations: state.customer.locations,
    selectedLocation: state.customer.selectedLocation,
});

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList, 'Checkout'>

    cart: Cart,
    store: Store,
    user: User,
    locations: Array<Location>
    selectedLocation: Location | null
}

export default connect(mapStateToProps)((props: Props) => {
    if (!props.cart || !props.user) {
        return <NotFoundScreen navigation={props.navigation}/>
    } else {
        return <Screen {...props}/>
    }
});

interface State {
    contactName: string
    contactPhone: string
    dispatchMethod: CartDispatchMethodMap[keyof CartDispatchMethodMap]
    dispatchTableNumber: string
    dispatchTime: string
    additionalNotes: string
    tips: number
    paymentGateway: PaymentGatewayMap[keyof PaymentGatewayMap]

    contactNameError: string
    contactPhoneError: string

    dispatchTimes: Array<Item>
    submitting: boolean
}

class Screen extends Component<Props, State> {

    interval: any;

    contactNameRef: RefObject<TextInput>;
    contactPhoneRef: RefObject<TextInput>;
    dispatchMethodRef: RefObject<Picker>;
    dispatchTableNumberRef: RefObject<Picker>;
    dispatchTimeRef: RefObject<Picker>;
    tipsRef: RefObject<Picker>;
    additionalNotesRef: RefObject<TextInput>;

    confirmModalRef: RefObject<CenterModal>;
    locationModalRef: RefObject<CenterModal | BottomModal>;
    paymentUrlRef: RefObject<PaymentUrlComponent>;

    constructor(props: Props) {
        super(props);
        let defaultDeliveryMethod = this.props.store.getDispatchMethodsList()[this.props.store.getDispatchMethodsList().length - 1].getDispatchMethod();
        let defaultPaymentGateway = this.props.store.getPaymentGatewaysList()[0].getPaymentGateway();
        let dispatchTimes = this.getDeliveryTimes(defaultDeliveryMethod);
        this.state = {
            contactName: props.cart.getContactName() ? props.cart.getContactName() : `${props.user!.getFirstName()} ${props.user!.getLastName()}`,
            contactPhone: props.cart.getContactPhoneNumber() ? props.cart.getContactPhoneNumber() : props.user!.getPhoneNumber(),
            dispatchMethod: props.cart.getDispatchMethod() ? props.cart.getDispatchMethod() : defaultDeliveryMethod,
            dispatchTableNumber: props.cart.getDispatchTableNumber() ? props.cart.getDispatchTableNumber() : this.props.cart.getDispatchTableNumber(),
            dispatchTime: props.cart.getDispatchTimestamp() ? props.cart.getDispatchTimestamp()!.toDate().toString() : dispatchTimes[0].value,
            additionalNotes: props.cart.getAdditionalNotes() ? props.cart.getAdditionalNotes() : this.props.cart.getAdditionalNotes(),
            tips: props.cart.getTips() ? props.cart.getTips() : 0,
            paymentGateway: defaultPaymentGateway,

            contactNameError: "",
            contactPhoneError: "",

            dispatchTimes: dispatchTimes,
            submitting: false,
        };
        this.contactNameRef = React.createRef();
        this.contactPhoneRef = React.createRef();
        this.dispatchMethodRef = React.createRef();
        this.dispatchTableNumberRef = React.createRef();
        this.dispatchTimeRef = React.createRef();
        this.tipsRef = React.createRef();
        this.additionalNotesRef = React.createRef();

        this.confirmModalRef = React.createRef();
        this.locationModalRef = React.createRef();
        this.paymentUrlRef = React.createRef();
        this.interval = setInterval(() => this.setState({dispatchTimes: this.getDeliveryTimes(this.state.dispatchMethod)}), 5000);
    }

    componentDidMount(): void {
        this.navigationOptions();
        if (!this.state.dispatchTimes.map(e => e.value).includes(this.state.dispatchTime)) {
            this.setState({dispatchTime: this.state.dispatchTimes[0].value})
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (!this.state.dispatchTimes.map(e => e.value).includes(this.state.dispatchTime)) {
            this.setState({dispatchTime: this.state.dispatchTimes[0].value})
        }
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: "Checkout",
        });
    };

    getNextDays = (): Array<moment.Moment> => {
        let availableDays = this.props.store.getHoursList()
            .map(e => e.getDaysOfTheWeekList())
            .flat(1)
            .filter((v, i, a) => a.indexOf(v) === i);

        let now = moment(moment().format("dddd"), "dddd");
        let nextDays: Array<moment.Moment> = [now.clone()];
        for (let i = 0; i <= 7; i++) {
            nextDays.push(now.add(1, "day").clone())
        }
        return nextDays.filter(e => availableDays.includes(dayOfTheWeekToProto[e.day()]))
    };

    getDeliveryTimes = (dispatchMethod: CartDispatchMethodMap[keyof CartDispatchMethodMap]): Array<Item> => {
        let now = moment(moment().format("HH:mm"), "HH:mm");
        let times: Array<Moment> = [];

        let extensionMinutes = this.props.store.getDispatchMethodsList()
            .filter(e => e.getDispatchMethod() == dispatchMethod)[0].getMinimumDispatchTime();

        this.getNextDays().splice(0, 2).forEach(async d => {
            await Promise.all(this.props.store.getHoursList()
                .filter(e => e.getDaysOfTheWeekList().includes(dayOfTheWeekToProto[d.day()]))
                .map(async h => {
                    let openTime = d.clone().set("hour", h.getFromHour()).set("minute", h.getFromMinute());
                    let closeTime = d.clone().set("hour", h.getToHour()).set("minute", h.getToMinute());
                    if (h.getFromHour() > h.getToHour()) {
                        closeTime.add(1, "day")
                    }

                    if (now.isSameOrAfter(openTime) && now.isSameOrBefore(closeTime)) {
                        times.push(now.clone().add(extensionMinutes, "minutes"));
                    }
                    closeTime.add(extensionMinutes, "minutes");

                    while (openTime.isBefore(closeTime)) {
                        times.push(openTime.clone());
                        openTime.add(10, 'minutes')
                    }
                }))
        });

        times = times
            .filter((e, i) => times.indexOf(e) === i)
            .filter(e => now.clone().add(extensionMinutes, "minutes").isSameOrBefore(e))
            .sort((a, b) => a.diff(b));
        return times.map((e, i) => ({
            value: e.format(),
            label: i == 0 ? `${e.calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'})} (ASAP)` : e.calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'})
        }));
    };

    onPressLocation = () => {
        if (Platform.OS == "web") {
            if (!this.props.locations.length) {
                this.props.navigation.navigate("Location", {})
            } else {
                this.locationModalRef.current!.open()
            }
        } else {
            this.props.navigation.navigate("Locations", {})
        }
    };

    validateDispatchMethod = (): string => {
        let storeDispatchMethod = this.props.store.getDispatchMethodsList().filter(e => e.getDispatchMethod() == this.state.dispatchMethod)[0];
        if (this.props.cart?.getSubtotal() < storeDispatchMethod.getMinimumCartSubtotal()) {
            return `Spend ${formatMoney(storeDispatchMethod.getMinimumCartSubtotal() - this.props.cart?.getSubtotal())} more for ${dispatchMethodToName[this.state.dispatchMethod]}.`
        }
        return ""
    };

    validateDispatchLocation = (): string => {
        if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY && !this.props.selectedLocation) {
            return "Address is a mandatory field"
        }
        return ""
    };

    validateDispatchTableNumber = (): string => {
        if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN && !this.state.dispatchTableNumber) {
            return "Table number is a mandatory field"
        }
        return ""
    };

    validatePaymentGateway = (): string => {
        let storePaymentGateway = this.props.store.getPaymentGatewaysList().filter(e => e.getPaymentGateway() == this.state.paymentGateway)[0];
        if (this.props.cart?.getSubtotal() < storePaymentGateway.getMinimumCartSubtotal()) {
            return `Spend ${formatMoney(storePaymentGateway.getMinimumCartSubtotal() - this.props.cart?.getSubtotal())} more for ${gatewayToName[this.state.paymentGateway]} payment.`
        }
        return ""
    };

    isValid = (): boolean => {
        if (ValidationsUtils.name(this.state.contactName) != "") {
            return false
        }
        if (ValidationsUtils.phoneNumber(this.state.contactPhone) != "") {
            return false
        }
        if (this.validateDispatchMethod()) {
            return false
        }
        if (this.validateDispatchLocation()) {
            return false
        }
        if (this.validateDispatchTableNumber()) {
            return false
        }
        if (this.validatePaymentGateway()) {
            return false
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.checkout();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    checkout = async () => {
        await this.updateCart();
        let payment = await this.createPayment();

        if (this.state.paymentGateway != PaymentGateway.PAYMENT_GATEWAY_CASH) {
            let url = payment.getApproveRedirectUrl();

            if (Platform.OS != 'web') {
                if (this.state.paymentGateway == PaymentGateway.PAYMENT_GATEWAY_STRIPE) {
                    url = await this.paymentUrlRef.current!.getUrl(url)
                }
                let result = await WebBrowser.openAuthSessionAsync(url, "");
                if (result.type != "success" || Linking.parse(result.url).queryParams!["payment"] != "SUCCESS") {
                    this.setState({submitting: false});
                    return
                }
            } else {
                if (this.state.paymentGateway == PaymentGateway.PAYMENT_GATEWAY_STRIPE) {
                    const stripe = await loadStripe(GrpcClient.getStripePublishableKey());
                    await stripe!.redirectToCheckout({sessionId: payment.getGatewaySessionId()})
                } else {
                    await Linking.openURL(url);
                }
                return
            }
        }
        let order = await Service.createOrder(payment.getId());
        if (Platform.OS == "web") {
            this.props.navigation.popToTop();
            this.props.navigation.replace("Order", {id: order.getId()});
        } else {
            this.props.navigation.popToTop();
            this.props.navigation.navigate("Order", {id: order.getId()});
        }
        await Service.initCart(null);
    };

    updateCart = async () => {
        let updateMasks: Array<CartUpdateMaskMap[keyof CartUpdateMaskMap]> = [];
        let cart = new Cart();
        cart.setId(this.props.cart.getId());

        if (this.props.cart.getUserId() == "") {
            cart.setUserId(this.props.user.getId());
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_USER_ID)
        }
        if (this.state.contactName != this.props.cart.getContactName()) {
            cart.setContactName(this.state.contactName);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_CONTACT_PHONE_NAME)
        }
        if (this.state.contactPhone != this.props.cart.getContactPhoneNumber()) {
            cart.setContactPhoneNumber(this.state.contactPhone);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_CONTACT_PHONE_NUMBER)
        }
        if (this.state.additionalNotes != this.props.cart.getAdditionalNotes()) {
            cart.setAdditionalNotes(this.state.additionalNotes);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_ADDITIONAL_NOTES);
        }
        if (this.state.tips != this.props.cart.getTips()) {
            cart.setTips(this.state.tips);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_TIPS);
        }
        if (this.state.dispatchMethod != this.props.cart.getDispatchMethod()) {
            cart.setDispatchMethod(this.state.dispatchMethod);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_METHOD);
        }

        let time = new Timestamp();
        time.fromDate(new Date(this.state.dispatchTime));
        if (time != this.props.cart.getDispatchTimestamp()) {
            cart.setDispatchTimestamp(time);
            updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_TIMESTAMP);
        }

        if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY) {
            if (this.props.selectedLocation!.getId() != this.props.cart.getDispatchLocationId()) {
                cart.setDispatchLocationId(this.props.selectedLocation!.getId());
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_LOCATION_ID);
            }
            if (this.props.cart.getDispatchTableNumber()) {
                cart.setDispatchTableNumber("");
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_TABLE_NUMBER);
            }
        } else if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN) {
            if (this.state.dispatchTableNumber != this.props.cart.getDispatchTableNumber()) {
                cart.setDispatchTableNumber(this.state.dispatchTableNumber);
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_TABLE_NUMBER);
            }
            if (this.props.cart.getDispatchLocationId()) {
                cart.setDispatchLocationId("");
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_LOCATION_ID);
            }
        } else if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_COLLECTION) {
            if (this.props.cart.getDispatchLocationId()) {
                cart.setDispatchLocationId("");
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_LOCATION_ID);
            }
            if (this.props.cart.getDispatchTableNumber()) {
                cart.setDispatchTableNumber("");
                updateMasks.push(CartUpdateMask.CART_UPDATE_MASK_DISPATCH_TABLE_NUMBER);
            }
        }

        if (updateMasks.length) {
            let req = new UpdateCartRequest();
            req.setRequestId(GrpcClient.newUuidV4());
            req.setCart(cart);
            req.setUpdateMasksList(updateMasks);
            await GrpcClient.invokeWithAuth(CartService.UpdateCart, req);
            await Service.getCart(this.props.cart.getId())
        }
    };

    createPayment = async (): Promise<Payment> => {
        let payment = new Payment();
        payment.setId(GrpcClient.newUuidV4());
        payment.setMerchantId(Constants.manifest.extra.merchantId);
        payment.setStoreId(this.props.cart.getStoreId());
        payment.setUserId(this.props.cart.getUserId());
        payment.setCartId(this.props.cart.getId());
        payment.setGateway(this.state.paymentGateway);
        payment.setSuccessRedirectUrl(Linking.makeUrl(Platform.OS == "web" ? "Redirect" : "Checkout", {
            payment: "SUCCESS",
            paymentId: payment.getId(),
        }));
        payment.setCancelRedirectUrl(Linking.makeUrl("Checkout", {
            payment: "CANCEL",
            paymentId: payment.getId(),
        }));

        let req = new CreatePaymentRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setPayment(payment);
        return await GrpcClient.invokeWithAuth(PaymentService.CreatePayment, req);
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Checkout"
                subTitle="You're one step away!"
                dark>
                <ScrollView>
                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                        How would you like your order?
                    </Text>

                    <DividerView/>

                    <NameField
                        forwardRef={this.contactNameRef}
                        placeholder="Full name"
                        label={"Full name"}
                        value={this.state.contactName}
                        error={Boolean(this.state.contactNameError)}
                        onChangeText={e => this.setState({
                            contactName: e,
                            contactNameError: ValidationsUtils.name(e),
                        })}
                        onSubmitEditing={() => this.contactPhoneRef.current!.focus()}/>

                    <PhoneField
                        forwardRef={this.contactPhoneRef}
                        value={this.state.contactPhone}
                        error={Boolean(this.state.contactPhoneError)}
                        onChangeText={e => this.setState({
                            contactPhone: e,
                            contactPhoneError: ValidationsUtils.phoneNumber(e)
                        })}
                        onSubmitEditing={() => this.dispatchMethodRef.current!.togglePicker(true)}/>

                    <SelectorField
                        forwardRef={this.dispatchMethodRef}
                        value={this.state.dispatchMethod}
                        label="Method"
                        error={false}
                        doneText={this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN ? "Next" : "Done"}
                        onChange={e => this.setState({dispatchMethod: e})}
                        items={this.props.store.getDispatchMethodsList().map(e => {
                            return {
                                label: dispatchMethodToName[e.getDispatchMethod()],
                                value: e.getDispatchMethod(),
                            }
                        })}
                        onUp={() => this.contactPhoneRef.current!.focus()}
                        onDown={() => {
                            if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_COLLECTION) {
                                Keyboard.dismiss()
                            } else if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN) {
                                this.dispatchTableNumberRef.current!.togglePicker(true)
                            } else if (this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY) {
                                Keyboard.dismiss();
                            }
                        }}/>
                    {
                        this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_EAT_IN
                            ? (<SelectorField
                                forwardRef={this.dispatchTableNumberRef}
                                value={this.state.dispatchTableNumber}
                                label="Table no."
                                doneText={"Done"}
                                placeholder={"Select table number"}
                                error={Boolean(this.validateDispatchTableNumber())}
                                onChange={e => this.setState({dispatchTableNumber: e})}
                                items={Constants.manifest.extra.tableNumbers.map((e: string) => {
                                    return {label: e, value: e}
                                })}
                                onUp={() => this.dispatchMethodRef.current!.togglePicker(true)}
                                onDown={() => Keyboard.dismiss()}/>)
                            : null
                    }

                    {
                        this.state.dispatchMethod == CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY
                            ? (<TouchableWithoutFeedback onPress={this.onPressLocation}>
                                <View>
                                    <TextField
                                        editable={false}
                                        onTouchStart={this.onPressLocation}
                                        placeholder="Select a delivery address."
                                        value={this.props.selectedLocation ?
                                            this.props.selectedLocation!.getLineTwo() ? [
                                                    toProperCase(this.props.selectedLocation!.getLineOne()),
                                                    toProperCase(this.props.selectedLocation!.getLineTwo()),
                                                    toProperCase(this.props.selectedLocation!.getCity()),
                                                    this.props.selectedLocation!.getPostcode().toUpperCase(),
                                                ].join("\n")
                                                : [
                                                    toProperCase(this.props.selectedLocation!.getLineOne()),
                                                    toProperCase(this.props.selectedLocation!.getCity()),
                                                    this.props.selectedLocation!.getPostcode().toUpperCase(),
                                                ].join("\n") : ""}
                                        label={"Address"}
                                        numberOfLines={this.props.selectedLocation ? this.props.selectedLocation!.getLineTwo() ? 4 : 3 : 1}
                                        error={Boolean(this.validateDispatchLocation())}/>
                                </View>
                            </TouchableWithoutFeedback>)
                            : null
                    }

                    <FieldErrors errors={[
                        this.state.contactNameError,
                        this.state.contactPhoneError,
                        this.validateDispatchMethod(),
                        this.validateDispatchLocation(),
                        this.validateDispatchTableNumber(),
                        this.validatePaymentGateway(),
                    ]}/>

                    <Divider/>

                    <SelectorField
                        forwardRef={this.dispatchTimeRef}
                        value={this.state.dispatchTime}
                        label="Time"
                        error={false}
                        onChange={e => this.setState({dispatchTime: e})}
                        items={this.state.dispatchTimes}
                        onUp={() => Keyboard.dismiss()}
                        onDown={() => this.additionalNotesRef.current!.focus()}/>

                    <TextField
                        forwardRef={this.additionalNotesRef}
                        placeholder={"Leave additional notes for the restaurant."}
                        value={this.state.additionalNotes}
                        label={"Notes"}
                        numberOfLines={2}
                        error={false}
                        onChangeText={e => this.setState({additionalNotes: e})}
                        onSubmitEditing={() => this.tipsRef.current!.togglePicker(true)}/>

                    <SelectorField
                        forwardRef={this.tipsRef}
                        value={this.state.tips}
                        label="Tips"
                        doneText="Done"
                        error={false}
                        onChange={e => this.setState({tips: e})}
                        items={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(e => {
                            return {label: `${e}% (${formatMoney(this.props.cart.getSubtotal() * e / 100)})`, value: e};
                        })}
                        onUp={() => this.additionalNotesRef.current!.focus()}
                        onDown={() => Keyboard.dismiss()}/>


                    <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                        How would you like to pay?
                    </Text>

                    <View style={[styles.paymentGatewayContainer, containerStyles.paddingRowZero]}>
                        {this.props.store.getPaymentGatewaysList().map((e, i) => (
                            <View key={e.getPaymentGateway()} style={{
                                flex: 1,
                                marginRight: this.props.store.getPaymentGatewaysList().length - 1 == i ? 0 : 10,
                            }}>
                                {this.renderPaymentGateway(e.getPaymentGateway())}
                            </View>
                        ))}
                    </View>

                </ScrollView>

                <View style={containerStyles.paddingRowMedium}>
                    <SubmitButton
                        text='Go to payment'
                        onPress={() => {
                            if (this.state.paymentGateway == PaymentGateway.PAYMENT_GATEWAY_CASH) {
                                this.confirmModalRef.current!.open()
                            } else {
                                this.onSubmit()
                            }
                        }}
                        submitting={this.state.submitting}
                        disabled={!this.isValid()}
                        inActive={!this.isValid() || this.state.submitting}/>
                </View>

                {this.renderConfirmModal()}
                <PaymentUrlComponent ref={this.paymentUrlRef}/>
                <LocationModal
                    forwardRef={this.locationModalRef}
                    onPressNewLocation={() => {
                        this.locationModalRef.current!.close();
                        this.props.navigation.navigate('Location', {})
                    }}
                    onPressEditLocation={id => {
                        this.locationModalRef.current!.close();
                        this.props.navigation.navigate('Location', {id})
                    }}/>
            </SingleLayout>
        )
    };

    renderPaymentGateway = (paymentGateway: PaymentGatewayMap[keyof PaymentGatewayMap]) => {
        return (
            <TouchableOpacity
                style={[containerStyles.border, styles.paymentGateway]}
                onPress={() => this.setState({paymentGateway})}>
                {
                    this.state.paymentGateway == paymentGateway
                        ? <View
                            style={[styles.paymentIconView, {backgroundColor: colorStyles.WHITE}, containerStyles.borderBottom]}>
                            <Ionicons name="md-checkmark" size={32} style={{color: colorStyles.RED}}/>
                        </View>
                        : <View style={[styles.paymentIconView, containerStyles.borderBottom]}>
                            <FontAwesome name={gatewayToIcon[paymentGateway]} size={32}/>
                        </View>
                }
                <View style={{marginTop: 10, marginBottom: 10}}>
                    <Text style={textStyles.secondarySubTitle}>{gatewayToName[paymentGateway]}</Text>
                </View>
            </TouchableOpacity>
        )
    };

    renderConfirmModal = () => {
        return (
            <CenterModal ref={this.confirmModalRef}>
                <View>
                    <View style={[containerStyles.paddingRowMedium, {paddingLeft: 25, paddingRight: 25}]}>
                        <View style={{alignItems: 'center'}}>
                            <Text style={textStyles.primaryTitle}>Confirm order</Text>
                            <Text style={[textStyles.secondarySubTitle, {
                                marginTop: 10,
                                marginBottom: 20,
                                textAlign: 'center'
                            }]}>
                                Are you sure you want to place an order?
                            </Text>
                        </View>
                        <View style={{width: '100%'}}>
                            <SubmitButton
                                text='Yes'
                                onPress={() => {
                                    this.confirmModalRef.current!.close();
                                    this.onSubmit();
                                }}
                                submitting={this.state.submitting}
                                inActive={this.state.submitting}/>
                            <View style={{height: 15}}/>
                            <CancelButton text='No' onPress={() => this.confirmModalRef.current!.close()}/>
                        </View>
                    </View>
                </View>
            </CenterModal>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    paymentGatewayContainer: {
        flexDirection: "row",
        justifyContent: "center",
        height: 120,
    },
    paymentGateway: {
        flex: 1,
        backgroundColor: colorStyles.WHITE,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        overflow: 'hidden',
    },
    paymentIconView: {
        flex: 1,
        width: "100%",
        backgroundColor: "#e7e7e7",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
});