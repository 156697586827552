import * as React from "react";
import {Component, RefObject} from "react";
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {ValidationsUtils} from "../../common/fields/Validations";
import {toastRef} from "../../common/views/ToastView";
import ScrollView from "../../common/views/ScrollView";
import DividerView from "../../common/views/DividerView";
import EmailField from "../../common/fields/Email";
import FieldErrors from "../../common/fields/Errors";
import SubmitButton from "../../common/buttons/SubmitButton";
import Constants from "expo-constants";
import {AuthenticationService} from "@emreat/proto/backend/v1/authentication_pb_service";
import {CodeRequest} from "@emreat/proto/backend/v1/authentication_pb";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<AuthNavigatorParamList, 'Forgotten'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<AuthNavigatorParamList, 'Forgotten'>

    user: User | null
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (props.user) {
        props.navigation.navigate('Home', {screen: 'Info'});
        return null
    }
    return <Screen navigation={props.navigation}/>
})

interface Props {
    navigation: StackNavigationProp<AuthNavigatorParamList>
}

interface State {
    emailAddress: string
    emailAddressError: string,

    submitting: boolean
}

class Screen extends Component<Props, State> {

    emailAddressRef: RefObject<TextInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            emailAddress: "",
            emailAddressError: "",
            submitting: false,
        };
        this.emailAddressRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Forgotten your password?',
        })
    };

    isValid = (): boolean => {
        return ValidationsUtils.emailAddress(this.state.emailAddress) == "";
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            await this.requestCode();
            this.setState({submitting: false});
            this.props.navigation.navigate('Verification', {emailAddress: this.state.emailAddress})
        } catch (e) {
            if (e.toString() == "ERROR_AUTHENTICATION_USER_NOT_FOUND") {
                toastRef.current!.show("Account does not exist, please create a new account!");
            } else {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({submitting: false});
        }
    };

    requestCode = async () => {
        let req = new CodeRequest();
        req.setEmailAddress(this.state.emailAddress.toLowerCase());
        req.setMerchantId(Constants.manifest.extra.merchantId);
        await GrpcClient.invoke(AuthenticationService.RequestCode, req);
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Forgotten your Password?"
                subTitle="Please enter your email."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <View style={{flex: 1}}>
                <ScrollView>
                    <TouchableOpacity onPress={() => this.props.navigation.navigate('Login', {})}>
                        <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                            Remember your password? <Text style={{color: colorStyles.RED}}>Log in</Text>
                        </Text>
                    </TouchableOpacity>

                    <DividerView/>

                    <EmailField
                        forwardRef={this.emailAddressRef}
                        returnKeyType="done"
                        value={this.state.emailAddress}

                        error={Boolean(this.state.emailAddressError)}
                        onChangeText={e => this.setState({
                            emailAddress: e,
                            emailAddressError: ValidationsUtils.emailAddress(e)
                        })}
                        onSubmitEditing={() => this.emailAddressRef.current!.blur()}/>

                    <FieldErrors errors={[this.state.emailAddressError]}/>
                </ScrollView>

                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    If you're email links to an account, you'll see {"\n"} a one-time  password in your inbox soon.
                </Text>

                <View style={containerStyles.paddingRowMediumBottom}>
                    <SubmitButton
                        text="Reset password"
                        inActive={!this.isValid() || this.state.submitting}
                        submitting={this.state.submitting}
                        onPress={this.onSubmit}
                        disabled={!this.isValid()}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
});

