import React, {PropsWithChildren} from "react";
import {ActivityIndicator, Text, TouchableOpacity, View} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, textStyles} from "../../Styles";

interface Props {
    onPress: () => void
    icon?: string
    text?: string
    submitting?: boolean
    disabled?: boolean
    left?: boolean
}

export default (props: PropsWithChildren<Props>) => {
    if (props.submitting) {
        return (
            <View style={[
                props.left ? {paddingLeft: 10, paddingRight: 20} : {paddingLeft: 20, paddingRight: 10},
                {paddingTop: 5, paddingBottom: 5},
            ]}>
                <ActivityIndicator color={colorStyles.BLUE} size="small"/>
            </View>
        )
    }
    return (
        <TouchableOpacity
            onPress={props.onPress}
            disabled={props.disabled}
            style={[
                props.left ? {paddingLeft: 10, paddingRight: 20} : {paddingLeft: 20, paddingRight: 10},
                {paddingTop: 5, paddingBottom: 5},
            ]}>
            <View>
                {props.text
                    ? <Text
                        style={[textStyles.secondaryTitle, {color: !props.disabled ? colorStyles.BLUE : colorStyles.ALPHA_BLUE}]}>
                        {props.text}
                    </Text>
                    : null}
                {props.icon
                    ? <Ionicons
                        name={props.icon}
                        size={28} color={!props.disabled ? colorStyles.BLUE : colorStyles.ALPHA_BLUE}/>
                    : null}
            </View>
        </TouchableOpacity>
    )
}
