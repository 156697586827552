import * as React from "react";
import {RefObject} from "react";
import {Image, ImageRequireSource, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import RightModal from "../modals/RightModal";
import {useNavigation} from "@react-navigation/native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import DividerView from "../views/DividerView";
import * as Linking from 'expo-linking';
import Constants from "expo-constants";

const localImages: { [key in string]: ImageRequireSource } = {
    'f704900c-465c-4c2b-b33b-528064072fe0': require('../../../assets/village-kebab-house/icon.png'),
    '14676e68-9afc-4d6a-ab80-cceb166f4101': require('../../../assets/dulwich-cafe/icon.png'),
    '91376788-0535-4463-b4e5-1c2cb76419a9': require('../../../assets/kebab-planet/icon.png'),
    'f6219a49-2a39-437b-989c-f7324fbf383d': require('../../../assets/dolphin-fish-chips/icon.png'),
    'ac3b5feb-f32d-4971-b21c-a1cb48667fb0': require('../../../assets/rock-n-roe/icon.png'),
    'e422b097-bd0e-4d50-b814-5d677ae8461e': require('../../../assets/piddaji/icon.png'),
};


interface Props {
    forwardRef: RefObject<RightModal>
}
export default (props: Props) => {
    const navigation = useNavigation();

    return (
        <RightModal ref={props.forwardRef}>
            <View style={[
                {
                    flex: 1, width: "100%", backgroundColor: colorStyles.primaryBackground,
                    paddingTop: 25, paddingBottom: 25
                },
                containerStyles.paddingContainer,
            ]}>
                <View style={{flex: 1}}>
                    <View>
                        <DividerView/>
                        {renderButton("Menu", () => {
                            props.forwardRef.current!.close();
                            navigation.navigate("Home", {screen: "Menu"});
                        })}
                        {renderButton("Information", () => {
                            props.forwardRef.current!.close();
                            navigation.navigate("Home", {screen: "Information"});
                        })}
                        {renderButton("Reviews", () => {
                            props.forwardRef.current!.close();
                            navigation.navigate("Home", {screen: "Reviews"});
                        })}
                        {renderButton("Orders", () => {
                            props.forwardRef.current!.close();
                            navigation.navigate("Home", {screen: "Orders"});
                        })}
                    </View>
                </View>
                {renderAppLogo()}
                {renderIPhoneAppButton()}
                {renderAndroidAppButton()}
            </View>
        </RightModal>
    );
}

const renderButton = (title: string, onPress: () => void) => {
    return (
        <View style={[containerStyles.paddingRowSmall, containerStyles.borderBottom, styles.buttonContainer]}>
            <TouchableOpacity onPress={onPress} style={{flexDirection: 'row'}}>
                <View style={{flex: 1, justifyContent: 'center'}}>
                    <Text style={textStyles.secondaryTitle}>{title}</Text>
                </View>
                <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED, marginLeft: 10}}/>
            </TouchableOpacity>
        </View>
    )
};

const renderAppLogo = () => {
    return (
        <View style={{flexDirection: "row", paddingTop: 20, paddingBottom: 10, alignItems: "center"}}>
            <Image
                source={localImages[Constants.manifest.extra.merchantId]}
                style={[containerStyles.border, {width: 65, height: 65, borderRadius: 10, marginRight: 10}]}/>
            <Text style={textStyles.primaryTitle}>Download the app!</Text>
        </View>
    )
};

const renderIPhoneAppButton = () => {
    return (
        <TouchableOpacity onPress={() => Linking.openURL(`https://apps.apple.com/gb/app/${Constants.manifest.extra.appleId}`)}>
            <View>
                <View style={[containerStyles.border, styles.appButton]}>
                    <Ionicons name="logo-apple" size={25}
                              style={{color: colorStyles.primaryDarkText, marginRight: 10}}/>
                    <Text style={textStyles.secondaryTitle}>IPhone</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
};

const renderAndroidAppButton = () => {
    return (
        <TouchableOpacity onPress={() => Linking.openURL(`https://play.google.com/store/apps/details?id=${Constants.manifest.extra.androidId}`)}>
            <View>
                <View style={[containerStyles.border, styles.appButton]}>
                    <Ionicons name="logo-android" size={25}
                              style={{color: colorStyles.primaryDarkText, marginRight: 10}}/>
                    <Text style={textStyles.secondaryTitle}>Android</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    buttonContainer: {
        backgroundColor: colorStyles.primaryBackground,
        justifyContent: 'center',
        textAlign: 'left',
        width: 225,
    },
    appButton: {
        borderRadius: 36,
        padding: 10,
        backgroundColor: colorStyles.LIGHT_GREY,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
    }
});
