import OrderReceiptComponent from "../../components/orders/OrderReceipt";
import {RootNavigatorParamList} from "../../Navigator";
import {Order, OrderStatus} from "@emreat/proto/backend/v1/orders_pb";
import {toastRef} from "../../common/views/ToastView";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Alert, RefreshControl, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {orderStatusToName} from "../../Constants";
import {StackNavigationProp} from "@react-navigation/stack";
import {Component, default as React} from "react";
import {Service} from "../../Service";
import SingleLayout from "../../common/layouts/SingleLayout";
import ScrollView from "../../common/views/ScrollView";
import * as Linking from "expo-linking";
import {Ionicons} from "@expo/vector-icons";
import moment from "moment";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import SubmitButton from "../../common/buttons/SubmitButton";
import CancelButton from "../../common/buttons/CancelButton";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    orders: state.dashboard.orders,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<DashboardStackParamList, 'Order'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<DashboardStackParamList, 'Order'>

    user: User | null
    orders: Array<Order>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }
    let order = props.orders.filter(e => e.getId() == props.route.params?.id)[0];
    if (!order) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} order={order}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<DashboardStackParamList>, StackNavigationProp<RootNavigatorParamList>>
    order: Order
}

interface State {
    refreshing: boolean
    submitting: boolean
}

class Screen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            submitting: false
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: `Order No. #${this.props.order.getReference()}`,
        });
    };

    refresh = async () => {
        if (!this.state.refreshing) {
            this.setState({refreshing: true});
            try {
                await Service.listOrders(0, [this.props.order.getCartId()], [], null, null, true);
            } catch (e) {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({refreshing: false});
        }
    };

    acceptOrder = async () => {
        Alert.alert(
            `Accept Order No. #${this.props.order.getReference()}`,
            "Are you sure?",
            [
                {text: "No", onPress: () => null},
                {
                    text: "Yes",
                    onPress: async () => {
                        this.setState({submitting: true})
                        try {
                            await Service.updateOrder(this.props.order.getId(), OrderStatus.ORDER_STATUS_CONFIRMED)
                        } catch (e) {
                            toastRef.current!.show(e.toString());
                            toastRef.current!.show("Oops something went wrong!");
                        }
                        this.setState({submitting: false})
                    },
                    style: "cancel"
                },
            ],
            {cancelable: true}
        );
    }

    cancelOrder = async () => {
        Alert.alert(
            `Cancel Order No. #${this.props.order.getReference()}`,
            "Are you sure?",
            [
                {text: "No", onPress: () => null},
                {
                    text: "Yes",
                    onPress: async () => {
                        this.setState({submitting: true})
                        try {
                            await Service.updateOrder(this.props.order.getId(), OrderStatus.ORDER_STATUS_CANCELLED)
                        } catch (e) {
                            toastRef.current!.show(e.toString());
                            toastRef.current!.show("Oops something went wrong!");
                        }
                        this.setState({submitting: false})
                    },
                    style: "cancel"
                },
            ],
            {cancelable: true}
        );
    }

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title={`Order No. #${this.props.order.getReference()}`}
                subTitle={""}>
                <ScrollView
                    refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                    {this.renderHeader()}
                    {this.renderContactDetails()}
                    <OrderReceiptComponent order={this.props.order}/>
                </ScrollView>
                <View style={[containerStyles.paddingRowMedium, containerStyles.borderTop]}>
                    {
                        this.props.order.getStatus() == OrderStatus.ORDER_STATUS_RECEIVED
                            ? <SubmitButton
                                text="Accept"
                                onPress={() => this.acceptOrder()}
                                submitting={this.state.submitting}
                                disabled={this.state.submitting}
                                inActive={this.state.submitting}/>
                            : this.props.order.getStatus() == OrderStatus.ORDER_STATUS_CONFIRMED
                            ? < CancelButton
                                text="Cancel"
                                onPress={() => this.cancelOrder()}
                                submitting={this.state.submitting}
                                disabled={this.state.submitting}
                                inActive={this.state.submitting}/>
                            : null
                    }
                </View>
            </SingleLayout>
        )
    };

    renderHeader = () => {
        return (
            <View style={[containerStyles.paddingRowMedium, {alignItems: 'center'}]}>
                <View>
                    <Text style={textStyles.secondaryTitle}>{
                        moment(new Date(this.props.order.getCart()!.getDispatchTimestamp()!.toDate()!)).calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'})}
                    </Text>
                </View>
                <View style={{paddingTop: 10}}>
                    <Text style={[textStyles.secondaryHeadline, {fontSize: 26, color: colorStyles.GREEN}]}>
                        {orderStatusToName[this.props.order.getStatus()] ? orderStatusToName[this.props.order.getStatus()] : "Unknown"}
                    </Text>
                </View>
            </View>
        )
    };

    renderContactDetails = () => {
        return (
            <TouchableOpacity
                style={[containerStyles.borderTop, containerStyles.borderBottom, containerStyles.paddingRowZero]}
                onPress={() => Linking.openURL(`tel:${this.props.order.getCart()!.getContactPhoneNumber()}`)}>
                <View style={[styles.contactContainer, containerStyles.border]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons name="md-call" size={28} style={{color: colorStyles.BLUE, marginRight: 10}}/>
                        <Text style={[textStyles.secondaryHeadline, {color: colorStyles.BLUE, paddingBottom: 3}]}>
                            Need help?
                        </Text>
                    </View>
                    <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                        Contact the customer on {this.props.order.getCart()!.getContactPhoneNumber()}
                    </Text>
                </View>
            </TouchableOpacity>
        )
    };
}

const styles = StyleSheet.create({
    contactContainer: {
        backgroundColor: colorStyles.LIGHT_BLUE,
        marginBottom: 15,
        marginTop: 15,
        padding: 15,
        alignItems: 'center',
        textAlign: 'center',
    },
});
