import * as React from "react";
import {Component, RefObject} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {textStyles} from "../../Styles";
import {getStore, RootState} from "../../reducers/reducers";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import {Ionicons} from "@expo/vector-icons";
import DrawerView from "./DrawerView";
import {StackNavigationProp} from "@react-navigation/stack";
import RightModal from "../modals/RightModal";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
});

interface Props {
    user: User | null
    navigation: StackNavigationProp<any>
}

export default connect(mapStateToProps)(class extends Component<Props> {

    drawerRef: RefObject<RightModal>;

    constructor(props: Props) {
        super(props);
        this.drawerRef = React.createRef();
    }

    render() {
        return (
            <View style={[styles.container]}>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{justifyContent: 'center'}}>
                        <TouchableOpacity onPress={() => this.props.navigation.navigate("Home", {screen: "Menu"})}>
                            <Text style={textStyles.secondaryHeadline}>
                                {getStore.getState().merchant.title}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{flexDirection: 'row-reverse', alignItems: 'center'}}>
                    <TouchableOpacity onPress={() => this.drawerRef.current!.open()} style={{marginLeft: 20}}>
                        <Ionicons name="md-menu" size={25}/>
                    </TouchableOpacity>
                    <DrawerView forwardRef={this.drawerRef}/>
                    {
                        this.props.user
                            ? (
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("Home", {screen: "Info"})}>
                                    <Text style={textStyles.secondaryTitle}>{this.props.user!.getFirstName()}</Text>
                                </TouchableOpacity>
                            )
                            : (
                                <TouchableOpacity onPress={() => this.props.navigation.navigate('Auth', {})}>
                                    <Text style={textStyles.secondaryTitle}>Login</Text>
                                </TouchableOpacity>
                            )
                    }
                </View>
            </View>
        )
    }
})

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
});
