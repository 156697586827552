import * as React from "react";
import {Component} from "react";
import {Text, View} from "react-native";
import {colorStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {AuthNavigatorParamList} from "../../Navigator";
import ScrollView from "../../common/views/ScrollView";
import SingleLayout from "../../common/layouts/SingleLayout";

interface Props {
    navigation: StackNavigationProp<AuthNavigatorParamList>
}

export default class extends Component<Props> {

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Privacy notice',
        })
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Privacy notice"
                subTitle="The information we collect, how it is used and shared, and your choices regarding this information."
                dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <View style={{paddingLeft: 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15}}>
                    <View>
                        <Text style={textStyles.primaryTitle}>Last Updated 5th December 2020</Text>
                    </View>
                    <View style={{paddingTop: 10, borderBottomColor: colorStyles.LIGHT_TEXT, borderBottomWidth: 0.5}}/>
                    <View style={{paddingTop: 15}}>
                        <Text style={textStyles.primaryTitle}>Introduction</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            This privacy notice (“Privacy Notice”) explains how EMREAT Ltd collects, shares, and uses
                            your personal information. You’ll also find information about how you can exercise your
                            privacy rights. By using our Services you agree to EMREAT Ltd using your personal
                            information as described in this Privacy Notice. The terms “we”, “us” or “our” are each
                            intended as reference to EMREAT Ltd and any terms not defined in this Privacy Notice
                            are defined in our Terms.
                        </Text>
                    </View>
                    <View style={{paddingTop: 15}}>
                        <Text style={textStyles.primaryTitle}>1. Information we collect from you and why</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>A. Information that you provide voluntarily</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            Information that you provide voluntarily:
                            {'\n'} {'\n'}

                            1.1. Registration information when you create an account so we can:
                            {'\n'}
                            - create your account so you can place Orders under our Terms
                            {'\n'}
                            - identify you when you sign-in to your account;
                            {'\n'}
                            - contact you for your views on our Services; and
                            {'\n'}
                            - notify you of changes or updates to our Services.
                            {'\n'} {'\n'}

                            1.2. Transaction Information when you place an Order with a Restaurant in order to:
                            {'\n'}
                            - process your Order and to bill you (though note, EMREAT never stores your credit card
                            information in our systems);
                            {'\n'}
                            - communicate your Order to the Restaurant;
                            {'\n'}
                            - send you status updates on your Order;
                            {'\n'}
                            - reply to your Order queries and questions and to resolve problems;

                            1.3. Feedback on your views of our Services in order to:
                            {'\n'}
                            - reply to your questions or queries;
                            {'\n'}
                            - publish reviews you submit to us about our Restaurants and services; and
                            {'\n'}

                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>2. Who we share personal information with</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            We may share your personal information with the following recipients (and whenever we do,
                            we will ensure that we have appropriate security and contractual safeguards in place to
                            protect it):
                            {'\n'} {'\n'}
                            2.1. Restaurants that you have placed an Order with for processing and delivery
                            {'\n'} {'\n'}
                            2.2. Third parties who undertake various activities to promote, market or support our
                            Services. This includes payment processing providers which process payment card transactions
                            - any of these third parties might be inside or outside your country of residence;
                            {'\n'} {'\n'}
                            2.3. Responsibility: You are responsible for making all arrangements necessary for you to
                            have access to the Website. You are also responsible for ensuring that all persons who
                            access the Website through your Internet connection are aware of these Website Terms and
                            that they comply with them.
                            {'\n'} {'\n'}
                            2.4. Any other person provided that you have given your consent.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>3. Data retention</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            EMREAT Ltd will retain your personal information for no longer than is necessary to fulfil
                            the purposes described in this Privacy Notice. We may also retain certain elements of your
                            personal information for a period after you delete or deactivate your account for our
                            legitimate operations such as record keeping and to comply with our legal obligations.
                            Whenever we retain your information we will do so in compliance with applicable laws.
                        </Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.primaryTitle}>4. Your data protection rights</Text>
                    </View>
                    <View style={{paddingTop: 10}}>
                        <Text style={textStyles.secondarySubTitle}>
                            You can access your account at any time to review and update your personal information.
                            You can also contact us to ask us to delete your personal information or restrict its
                            processing. You also have the right to unsubscribe from the marketing communications we
                            send you by using the unsubscribe functionality in any marketing communication you receive
                            from us or by amending your profile accordingly.
                        </Text>
                    </View>
                </View>
            </ScrollView>
        )
    }
}
