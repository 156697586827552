import {Token} from "@emreat/proto/backend/v1/tokens_pb";
import {User} from "@emreat/proto/backend/v1/users_pb";

export interface AuthState {
    token: Token | null
    user: User | null
}

export type AuthAction = {
    readonly type: "SET_TOKEN",
    readonly token: Token | null,
} | {
    readonly type: "SET_USER",
    readonly user: User | null,
}

const initialState: AuthState = {
    token: null,
    user: null,
};

export function auth(state: AuthState = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case "SET_TOKEN":
            return {...state, token: action.token};
        case "SET_USER":
            return {...state, user: action.user};
        default:
            return state;
    }
}