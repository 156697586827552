import * as React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useNavigation} from '@react-navigation/native';
import {textStyles} from "../../Styles";

export default () => {
    const navigation = useNavigation();
    return (
        <View style={styles.container}>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <Text style={textStyles.secondarySubTitle}>{'© 2020 Emreat Ltd. '}</Text>
            </View>
            <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                <TouchableOpacity onPress={() => navigation.navigate("Home", {screen: 'TermsOfUse'})}>
                    <Text style={textStyles.secondarySubTitle}>{' Terms of Use'}</Text>
                </TouchableOpacity>
                <Text style={textStyles.secondarySubTitle}>{'|'}</Text>
                <TouchableOpacity onPress={() => navigation.navigate("Home", {screen: 'PrivacyPolicy'})}>
                    <Text style={textStyles.secondarySubTitle}>{'Privacy Policy '}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
});
