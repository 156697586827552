import ReviewSummaryView from "../../components/orders/ReviewSummaryView";
import LeaveReviewView from "../../components/orders/LeaveReviewView";
import OrderReceiptComponent from "../../components/orders/OrderReceipt";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {Order, OrderStatus} from "@emreat/proto/backend/v1/orders_pb";
import {toastRef} from "../../common/views/ToastView";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {RefreshControl, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {orderStatusToDescription, orderStatusToName} from "../../Constants";
import {StackNavigationProp} from "@react-navigation/stack";
import {Component, default as React} from "react";
import SubmitButton from "../../common/buttons/SubmitButton";
import {Store} from "@emreat/proto/backend/v1/stores_pb";
import {Service} from "../../Service";
import SingleLayout from "../../common/layouts/SingleLayout";
import ScrollView from "../../common/views/ScrollView";
import * as Linking from "expo-linking";
import {Ionicons} from "@expo/vector-icons";
import moment from "moment";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    orders: state.customer.orders,
    stores: state.merchant.stores,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Order'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Order'>

    user: User | null
    orders: Array<Order>
    stores: Array<Store>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    if (!props.user) {
        props.navigation.navigate('Auth', {});
        return null
    }
    let order = props.orders.filter(e => e.getId() == props.route.params?.id)[0];
    if (!order) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} order={order} store={props.stores[0]}/>
})

interface Props {
    navigation: StackNavigationProp<HomeNavigatorParamList>
    order: Order
    store: Store
}

interface State {
    refreshing: boolean
    reOrdering: boolean
}

class Screen extends Component<Props, State> {

    interval: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            reOrdering: false,
        };
    }

    componentDidMount(): void {
        this.navigationOptions();
        if (this.props.order.getStatus() == OrderStatus.ORDER_STATUS_RECEIVED) {
            this.interval = setInterval(this.poll, 5000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevProps.order.getStatus() == OrderStatus.ORDER_STATUS_RECEIVED && this.props.order.getStatus() == OrderStatus.ORDER_STATUS_CONFIRMED) {
            clearInterval(this.interval);
        }
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: `Order No. #${this.props.order.getReference()}`,
        });
    };

    poll = async () => {
        try {
            await Service.listOrders(0, [this.props.order.getCartId()], [], null, null);
        } catch (e) {
            console.log(e.toString());
        }
    };

    refresh = async () => {
        if (!this.state.refreshing) {
            this.setState({refreshing: true});
            try {
                await Service.listOrders(0, [this.props.order.getCartId()], [], null, null);
            } catch (e) {
                toastRef.current!.show(e.toString());
                toastRef.current!.show("Oops something went wrong!");
            }
            this.setState({refreshing: false});
        }
    };

    orderAgain = async () => {
        this.setState({reOrdering: true});
        try {
            await Service.orderAgain(this.props.order.getCart()!.getItemsList());
            this.setState({reOrdering: false});
            this.props.navigation.navigate("Cart", {});
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({reOrdering: false});
        }
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title={`Order No. #${this.props.order.getReference()}`}
                subTitle={""}>
                <ScrollView
                    refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                    {this.renderHeader()}
                    <View style={[containerStyles.paddingRowMediumBottom, containerStyles.borderBottom]}>
                        <SubmitButton
                            text="Order again"
                            onPress={this.orderAgain}
                            submitting={this.state.reOrdering}
                            disabled={false}
                            inActive={this.state.reOrdering}/>
                    </View>
                    {
                        this.props.order.getReview()
                            ? <ReviewSummaryView review={this.props.order.getReview()!}/>
                            : <LeaveReviewView orderId={this.props.order.getId()}/>
                    }
                    <OrderReceiptComponent order={this.props.order}/>
                    {this.renderContactDetails()}
                </ScrollView>
            </SingleLayout>
        )
    };

    renderHeader = () => {
        return (
            <View style={[containerStyles.paddingRowMedium, {alignItems: 'center'}]}>
                <View>
                    <Text style={textStyles.secondaryTitle}>{
                        moment(new Date(this.props.order.getCart()!.getDispatchTimestamp()!.toDate()!)).calendar(null, {sameElse: 'dddd, MMMM Do YYYY, [at] h:mm A'})}
                    </Text>
                </View>
                <View style={{paddingTop: 10}}>
                    <Text style={[textStyles.secondaryHeadline, {fontSize: 26, color: colorStyles.GREEN}]}>
                        {orderStatusToName[this.props.order.getStatus()] ? orderStatusToName[this.props.order.getStatus()] : "Unknown"}
                    </Text>
                </View>
                <View style={{paddingTop: 10}}>
                    <Text style={[textStyles.primarySubTitle, {textAlign: "center"}]}>
                        {orderStatusToDescription[this.props.order.getStatus()] ? orderStatusToDescription[this.props.order.getStatus()] : ""}
                    </Text>
                </View>
            </View>
        )
    };

    renderContactDetails = () => {
        return (
            <TouchableOpacity
                style={[containerStyles.borderTop, containerStyles.paddingRowZero]}
                onPress={() => Linking.openURL(`tel:${this.props.store.getPhoneNumber()}`)}>
                <View style={[styles.contactContainer, containerStyles.border]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons name="md-call" size={28} style={{color: colorStyles.BLUE, marginRight: 10}}/>
                        <Text style={[textStyles.secondaryHeadline, {color: colorStyles.BLUE, paddingBottom: 3}]}>
                            Need help?
                        </Text>
                    </View>
                    <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                        Call us on {this.props.store.getPhoneNumber()}
                    </Text>
                </View>
            </TouchableOpacity>
        )
    };
}

const styles = StyleSheet.create({
    contactContainer: {
        backgroundColor: colorStyles.LIGHT_BLUE,
        marginBottom: 15,
        marginTop: 15,
        padding: 15,
        alignItems: 'center',
        textAlign: 'center',
    },
});
