import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {Keyboard, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colorStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {ValidationsUtils} from "../../common/fields/Validations";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {
    CreateModifierGroupRequest,
    UpdateModifierGroupRequest
} from "@emreat/proto/backend/v1/modifier_groups_pb";
import {ModifierGroupService} from "@emreat/proto/backend/v1/modifier_groups_pb_service";
import {
    ModifierGroup,
    ModifierGroupUpdateMask,
    ModifierGroupUpdateMaskMap
} from "@emreat/proto/backend/v1/modifier_groups_pb";
import Constants from "expo-constants";
import {Service} from "../../Service";
import NameField from "../../common/fields/Name";
import TextField from "../../common/fields/TextField";
import Picker from "react-native-picker-select";
import SelectorField from "../../common/fields/SelectorField";
import ModifierRow from "../../components/dashboard/ModifierRow";
import {Ionicons} from "@expo/vector-icons";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    modifierGroups: state.merchant.modifierGroups
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'ModifierGroup'>
    route: RouteProp<DashboardStackParamList, 'ModifierGroup'>

    modifierGroups: Array<ModifierGroup>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let modifierGroup = props.modifierGroups.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !modifierGroup) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen navigation={props.navigation} modifierGroup={modifierGroup}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    modifierGroup: ModifierGroup | null
}

interface State {
    title: string
    subTitle: string
    minimumChoice: number
    maximumChoice: number

    titleError: string
    subTitleError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    titleRef: RefObject<TextInput>;
    subTitleRef: RefObject<TextInput>;
    minimumChoiceRef: RefObject<Picker>;
    maximumChoiceRef: RefObject<Picker>;

    constructor(props: Props) {
        super(props);
        this.state = {
            title: this.props.modifierGroup ? this.props.modifierGroup.getTitle() : "",
            subTitle: this.props.modifierGroup ? this.props.modifierGroup.getSubTitle() : "",
            minimumChoice: this.props.modifierGroup ? this.props.modifierGroup.getMinimumChoice() : 0,
            maximumChoice: this.props.modifierGroup ? this.props.modifierGroup.getMaximumChoice() : 1,

            titleError: '',
            subTitleError: '',

            submitting: false,
        };
        this.titleRef = createRef();
        this.subTitleRef = createRef();
        this.minimumChoiceRef = React.createRef();
        this.maximumChoiceRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.modifierGroup) {
            this.props.navigation.setOptions({
                title: 'Edit Modifier Group',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Modifier Group',
            })
        }
    };

    isValid = (): boolean => {
        if (ValidationsUtils.title(this.state.title) != "") {
            return false
        }
        if (ValidationsUtils.subTitle(this.state.subTitle) != "") {
            return false
        }

        if (this.props.modifierGroup) {
            return this.state.title != this.props.modifierGroup.getTitle() ||
                this.state.subTitle != this.props.modifierGroup.getSubTitle() ||
                this.state.minimumChoice != this.props.modifierGroup.getMinimumChoice() ||
                this.state.maximumChoice != this.props.modifierGroup.getMaximumChoice();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.modifierGroup) {
                await this.updateModifierGroup()
            } else {
                await this.createModifierGroup()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createModifierGroup = async () => {
        let modifierGroup = new ModifierGroup();
        modifierGroup.setTitle(this.state.title);
        modifierGroup.setSubTitle(this.state.subTitle);
        modifierGroup.setMinimumChoice(this.state.minimumChoice);
        modifierGroup.setMaximumChoice(this.state.maximumChoice);
        modifierGroup.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateModifierGroupRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setModifierGroup(modifierGroup);
        await GrpcClient.invokeWithAuth(ModifierGroupService.CreateModifierGroup, req);
    };

    updateModifierGroup = async () => {
        let updateMasks: Array<ModifierGroupUpdateMaskMap[keyof ModifierGroupUpdateMaskMap]> = [];
        let modifierGroup = new ModifierGroup();
        modifierGroup.setId(this.props.modifierGroup!.getId());
        if (this.state.title != this.props.modifierGroup!.getTitle()) {
            modifierGroup.setTitle(this.state.title);
            updateMasks.push(ModifierGroupUpdateMask.MODIFIER_GROUP_UPDATE_MASK_TITLE)
        }
        if (this.state.subTitle != this.props.modifierGroup!.getSubTitle()) {
            modifierGroup.setSubTitle(this.state.subTitle);
            updateMasks.push(ModifierGroupUpdateMask.MODIFIER_GROUP_UPDATE_MASK_SUB_TITLE)
        }
        if (this.state.minimumChoice != this.props.modifierGroup!.getMinimumChoice()) {
            modifierGroup.setMinimumChoice(this.state.minimumChoice);
            updateMasks.push(ModifierGroupUpdateMask.MODIFIER_GROUP_UPDATE_MASK_MINIMUM_CHOICE)
        }
        if (this.state.maximumChoice != this.props.modifierGroup!.getMaximumChoice()) {
            modifierGroup.setMaximumChoice(this.state.maximumChoice);
            updateMasks.push(ModifierGroupUpdateMask.MODIFIER_GROUP_UPDATE_MASK_MAXIMUM_CHOICE)
        }

        let req = new UpdateModifierGroupRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setModifierGroup(modifierGroup);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(ModifierGroupService.UpdateModifierGroup, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the modifier group information.
                </Text>

                <DividerView/>

                <NameField
                    forwardRef={this.titleRef}
                    error={Boolean(this.state.titleError)}
                    label="Title"
                    placeholder="Title"
                    value={this.state.title}
                    onChangeText={e => this.setState({
                        title: e,
                        titleError: ValidationsUtils.title(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <TextField
                    forwardRef={this.subTitleRef}
                    error={Boolean(this.state.subTitleError)}
                    label="Sub title"
                    placeholder="Sub title"
                    value={this.state.subTitle}
                    numberOfLines={2}
                    onChangeText={e => this.setState({
                        subTitle: e,
                        subTitleError: ValidationsUtils.subTitle(e)
                    })}
                    onSubmitEditing={() => this.minimumChoiceRef.current!.togglePicker()}/>

                <SelectorField
                    forwardRef={this.minimumChoiceRef}
                    value={this.state.minimumChoice}
                    label="Min Choice"
                    doneText={"Next"}
                    items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => ({value: e, label: e.toString()}))}
                    error={false}
                    onChange={e => this.setState({minimumChoice: e})}
                    onUp={() => this.subTitleRef.current!.focus()}
                    onDown={() => this.maximumChoiceRef.current!.togglePicker()}/>

                <SelectorField
                    forwardRef={this.maximumChoiceRef}
                    value={this.state.maximumChoice}
                    label="Max Choice"
                    doneText={"Done"}
                    items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                        .filter(e => e >= this.state.minimumChoice)
                        .map(e => ({value: e, label: e.toString()}))}
                    error={false}
                    onChange={e => this.setState({maximumChoice: e})}
                    onUp={() => this.minimumChoiceRef.current!.togglePicker()}
                    onDown={() => Keyboard.dismiss()}/>

                <FieldErrors errors={[this.state.titleError, this.state.subTitleError]}/>

                {this.renderModifiers()}

            </ScrollView>
        )
    };

    renderModifiers = () => {
        if (!this.props.modifierGroup) {
            return null
        } else {
            return (
                <View>
                    <View style={[styles.infoContainer, {marginTop: 0}]}>
                        <View style={{flex: 1, justifyContent: "center"}}>
                            <Text style={[textStyles.secondarySubTitle, {textAlign: "center"}]}>
                                Add, remove or edit modifiers.
                            </Text>
                        </View>
                        <TouchableOpacity
                            onPress={() => this.props.navigation.navigate("Modifier", {modifierGroupId: this.props.modifierGroup!.getId()})}>
                            <Ionicons name="md-add" size={28} color={colorStyles.BLUE}/>
                        </TouchableOpacity>
                    </View>
                    {this.props.modifierGroup.getModifiersList().map(modifier => {
                        return <ModifierRow
                            key={modifier.getId()}
                            modifier={modifier}
                            onPressEdit={() => this.props.navigation.navigate("Modifier", {id: modifier.getId()})}/>
                    })}
                </View>
            )
        }
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        flexDirection: "row",
        textAlign: "center",
    },
});