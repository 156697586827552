import {
    Platform,
    StyleSheet,
    Text,
    TextInput,
    TextInputProps,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from "react-native";
import React, {Component, RefObject} from "react";
import {Ionicons} from "@expo/vector-icons";
import {colorStyles, containerStyles, textStyles} from "../../Styles";

interface Props extends TextInputProps {
    forwardRef?: RefObject<TextInput>
    label: string
    error?: boolean
    showSecureTextEntry: boolean
}

interface State {
    secureTextEntry: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            secureTextEntry: this.props.showSecureTextEntry,
        }
    }

    onChange = (value: string) => {
        return this.props.onChangeText!(value.replace(/\n\s*\n/g, "\n"))
    };

    render() {
        if (this.props.editable == false) {
            return this.renderNotEditable()
        } else if (Platform.OS == 'web') {
            return this.renderRow();
        } else {
            return (
                <TouchableWithoutFeedback
                    onPress={() => this.props.forwardRef ? this.props.forwardRef.current!.focus() : null}>
                    {this.renderRow()}
                </TouchableWithoutFeedback>
            )
        }
    };

    renderNotEditable = () => {
        return (
            <View style={[
                styles.container,
                containerStyles.borderBottom, containerStyles.paddingRowMedium,
                this.props.error ? {borderBottomColor: colorStyles.error} : {}
            ]}>
                {this.renderLabel()}
                <Text
                    numberOfLines={this.props.numberOfLines}
                    style={textStyles.secondarySubTitle}>
                    {this.props.value}
                </Text>
            </View>
        )
    };

    renderRow = () => {
        return (
            <View style={[
                styles.container,
                containerStyles.borderBottom, containerStyles.paddingRowMedium,
                this.props.error ? {borderBottomColor: colorStyles.error} : {}
            ]}>
                {this.renderLabel()}
                {this.renderTextInput()}
                {this.renderSecureTextEntryToggle()}
                {this.props.children}
            </View>
        )
    };

    renderLabel = () => {
        return (
            <Text style={[
                textStyles.secondaryTitle,
                this.props.error ? {color: colorStyles.error} : {},
                {width: 100},
            ]}>
                {this.props.label}
            </Text>
        )
    };

    renderTextInput = () => {
        return (
            <View style={{flex: 1, justifyContent: "center"}}>
                <TextInput
                    ref={this.props.forwardRef}
                    style={[
                        {flex: 1},
                        // @ts-ignore
                        Platform.OS == 'web' ? {outlineWidth: 0} : {},
                        textStyles.secondarySubTitle
                    ]}
                    textAlignVertical="center"
                    returnKeyType={"next"}
                    enablesReturnKeyAutomatically={true}
                    placeholderTextColor={colorStyles.secondaryDarkText}
                    {...this.props}
                    secureTextEntry={this.state.secureTextEntry}
                    onChangeText={value => this.onChange(value)}/>
            </View>
        )
    };

    renderSecureTextEntryToggle = () => {
        if (!this.props.showSecureTextEntry) {
            return null
        }
        return (
            <TouchableOpacity
                onPress={() => this.setState({secureTextEntry: !this.state.secureTextEntry})}>
                <View style={{margin: -5, padding: 5}}>
                    {
                        this.state.secureTextEntry
                            ? <Ionicons name="md-eye" size={22} style={{color: colorStyles.error}}/>
                            : <Ionicons name="md-eye-off" size={22} style={{color: colorStyles.error}}/>
                    }
                </View>
            </TouchableOpacity>
        )
    }
};


const styles = StyleSheet.create({
    container: {
        backgroundColor: colorStyles.primaryBackground,
        flexDirection: "row",
        alignItems: "center",
        minHeight: 50,
    },
});
