import {Ionicons} from "@expo/vector-icons";
import {colorStyles, textStyles} from "../../Styles";
import {Text, View} from "react-native";
import * as React from "react";
import SubmitButton from "../buttons/SubmitButton";

interface Props {
    title: string
    subTitle: string
    icon: string
    button?: string
    onPress?: () => void
}

export default (props: Props) => {
    return (
        <View style={{flex: 1, justifyContent: 'center', padding: 20}}>
            <Ionicons name={props.icon} color={colorStyles.secondaryDarkText} size={65} style={{alignSelf: 'center'}}/>
            <Text style={[textStyles.primaryTitle, {textAlign: 'center', marginTop: 5}]}>
                {props.title}
            </Text>
            <Text style={[textStyles.secondarySubTitle, {
                marginTop: 10,
                marginLeft: 10,
                marginRight: 10,
                textAlign: 'center'
            }]}>
                {props.subTitle}
            </Text>
            {
                props.button
                    ? (
                        <View style={{marginTop: 10}}>
                            <SubmitButton text={props.button} onPress={props.onPress}/>
                        </View>
                    ) : null
            }
        </View>
    )
}