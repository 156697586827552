import React, {Component} from "react";
import {Image, ImageRequireSource, ImageResizeMode, ImageURISource, Platform, View} from "react-native";
import SkeletonContent from "react-native-skeleton-content";
import * as FileSystem from 'expo-file-system';
import {GrpcClient} from "../../GrpcClient";
import {containerStyles} from "../../Styles";

interface Props {
    imageUrl: string
    style?: object
    noBorder?: boolean
}

interface State {
    loading: boolean
    error: boolean
    resizeMode: ImageResizeMode
}

export class ImageView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            resizeMode: "cover",
        };
    };

    render() {
        if (this.state.error) {
            return null
        } else if (this.props.imageUrl) {
            return this.image()
        } else {
            return null
        }
    };

    image = () => {
        return (
            <View style={[this.props.style, !this.props.noBorder ? containerStyles.border : null]}>
                <Image
                    progressiveRenderingEnabled={true}
                    resizeMode={this.state.resizeMode}
                    style={[{width: '100%', height: '100%'}, !this.props.noBorder ? containerStyles.border : null]}
                    source={{uri: this.props.imageUrl}}
                    onError={() => this.setState({error: true})}
                    onLoadEnd={() => this.setState({loading: false})}
                    onLoad={async (e) => {
                        if (Platform.OS != "web") {
                            let resizeMode: ImageResizeMode = "cover";
                            let width = e.nativeEvent.source.width;
                            let height = e.nativeEvent.source.height;
                            if (width < height || width == height) {
                                resizeMode = "contain"
                            }
                            this.setState({resizeMode});
                        } else {
                            Image.getSize(this.props.imageUrl, (width, height) => {
                                let resizeMode: ImageResizeMode = "cover";
                                if (width < height || width == height) {
                                    resizeMode = "contain"
                                }
                                this.setState({resizeMode});
                            }, () => this.setState({error: true}));
                        }
                    }}/>
            </View>
        )
    };
}
