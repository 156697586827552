import * as React from "react";
import {Component, createRef, RefObject} from "react";
import {Keyboard, StyleSheet, Text, TextInput} from "react-native";
import {textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {RootState} from "../../reducers/reducers";
import HeaderButton from "../../common/buttons/HeaderButton";
import ScrollView from "../../common/views/ScrollView";
import {ValidationsUtils} from "../../common/fields/Validations";
import FieldErrors from "../../common/fields/Errors";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import {RouteProp} from "@react-navigation/native";
import {connect} from "react-redux";
import NotFoundScreen from "../NotFoundScreen";
import {DashboardStackParamList} from "../../DashboardNavigator";
import {CreateModifierRequest, UpdateModifierRequest} from "@emreat/proto/backend/v1/modifiers_pb";
import {
    Modifier,
    ModifierUpdateMask,
    ModifierUpdateMaskMap
} from "@emreat/proto/backend/v1/modifiers_pb";
import Constants from "expo-constants";
import NameField from "../../common/fields/Name";
import TextField from "../../common/fields/TextField";
import {ModifierService} from "@emreat/proto/backend/v1/modifiers_pb_service";
import {Service} from "../../Service";
import Picker from "react-native-picker-select";
import SelectorField from "../../common/fields/SelectorField";
import MoneyField from "../../common/fields/Money";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    modifiers: state.merchant.modifierGroups.map(e => e.getModifiersList()).flat(1)
});

interface RouteProps {
    navigation: StackNavigationProp<DashboardStackParamList, 'Modifier'>
    route: RouteProp<DashboardStackParamList, 'Modifier'>

    modifiers: Array<Modifier>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    let modifier = props.modifiers.filter(e => e.getId() == props.route.params?.id)[0];
    if (props.route.params?.id && !modifier) {
        return <NotFoundScreen navigation={props.navigation}/>
    }
    return <Screen
        navigation={props.navigation}
        modifier={modifier}
        modifierGroupId={props.route.params.modifierGroupId!}/>
})

interface Props {
    navigation: StackNavigationProp<DashboardStackParamList>
    modifier: Modifier | null
    modifierGroupId: string | null
}

interface State {
    title: string
    subTitle: string
    additionalPrice: string
    maximumChoice: number

    titleError: string
    subTitleError: string
    additionalPriceError: string

    submitting: boolean
}

class Screen extends Component<Props, State> {

    titleRef: RefObject<TextInput>;
    subTitleRef: RefObject<TextInput>;
    additionalPriceRef: RefObject<TextInput>;
    maximumChoiceRef: RefObject<Picker>;

    constructor(props: Props) {
        super(props);
        this.state = {
            title: this.props.modifier ? this.props.modifier.getTitle() : "",
            subTitle: this.props.modifier ? this.props.modifier.getSubTitle() : "",
            additionalPrice: this.props.modifier ? (this.props.modifier.getAdditionalPrice() / 100).toFixed(2) : "0.00",
            maximumChoice: this.props.modifier ? this.props.modifier.getMaximumChoice() : 1,

            titleError: '',
            subTitleError: '',
            additionalPriceError: '',

            submitting: false,
        };
        this.titleRef = createRef();
        this.subTitleRef = createRef();
        this.additionalPriceRef = React.createRef();
        this.maximumChoiceRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    componentDidUpdate(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            headerRight: () => (
                <HeaderButton
                    onPress={() => this.onSubmit()}
                    text="Save"
                    submitting={this.state.submitting}
                    disabled={!this.isValid() || this.state.submitting}/>
            ),
            headerLeft: () => <HeaderButton onPress={() => this.props.navigation.goBack()} text="Cancel" left/>,
        });
        if (this.props.modifier) {
            this.props.navigation.setOptions({
                title: 'Edit Modifier',
            })
        } else {
            this.props.navigation.setOptions({
                title: 'Add Modifier',
            })
        }
    };

    isValid = (): boolean => {
        if (ValidationsUtils.title(this.state.title) != "") {
            return false
        }
        if (ValidationsUtils.subTitle(this.state.subTitle) != "") {
            return false
        }
        if (ValidationsUtils.money(this.state.additionalPrice) != "") {
            return false
        }

        if (this.props.modifier) {
            return this.state.title != this.props.modifier.getTitle() ||
                this.state.subTitle != this.props.modifier.getSubTitle() ||
                parseFloat(this.state.additionalPrice) * 100 != this.props.modifier.getAdditionalPrice() ||
                this.state.maximumChoice != this.props.modifier.getMaximumChoice();
        }
        return true;
    };

    onSubmit = async () => {
        this.setState({submitting: true});
        try {
            if (this.props.modifier) {
                await this.updateModifier()
            } else {
                await this.createModifier()
            }
            await Service.initMerchant();
            this.props.navigation.pop()
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({submitting: false});
        }
    };

    createModifier = async () => {
        let modifier = new Modifier();
        modifier.setTitle(this.state.title);
        modifier.setSubTitle(this.state.subTitle);
        modifier.setAdditionalPrice(parseFloat(this.state.additionalPrice) * 100);
        modifier.setMaximumChoice(this.state.maximumChoice);
        modifier.setModifierGroupId(this.props.modifierGroupId!);
        modifier.setMerchantId(Constants.manifest.extra.merchantId);

        let req = new CreateModifierRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setModifier(modifier);
        await GrpcClient.invokeWithAuth(ModifierService.CreateModifier, req);
    };

    updateModifier = async () => {
        let updateMasks: Array<ModifierUpdateMaskMap[keyof ModifierUpdateMaskMap]> = [];
        let modifier = new Modifier();
        modifier.setId(this.props.modifier!.getId());
        if (this.state.title != this.props.modifier!.getTitle()) {
            modifier.setTitle(this.state.title);
            updateMasks.push(ModifierUpdateMask.MODIFIER_UPDATE_MASK_TITLE)
        }
        if (this.state.subTitle != this.props.modifier!.getSubTitle()) {
            modifier.setSubTitle(this.state.subTitle);
            updateMasks.push(ModifierUpdateMask.MODIFIER_UPDATE_MASK_SUB_TITLE)
        }
        if (parseFloat(this.state.additionalPrice) * 100 != this.props.modifier!.getAdditionalPrice()) {
            modifier.setAdditionalPrice(parseFloat(this.state.additionalPrice) * 100);
            updateMasks.push(ModifierUpdateMask.MODIFIER_UPDATE_MASK_ADDITIONAL_PRICE)
        }
        if (this.state.maximumChoice != this.props.modifier!.getMaximumChoice()) {
            modifier.setMaximumChoice(this.state.maximumChoice);
            updateMasks.push(ModifierUpdateMask.MODIFIER_UPDATE_MASK_MAXIMUM_CHOICE)
        }

        let req = new UpdateModifierRequest();
        req.setRequestId(GrpcClient.newUuidV4());
        req.setModifier(modifier);
        req.setUpdateMasksList(updateMasks);
        await GrpcClient.invokeWithAuth(ModifierService.UpdateModifier, req);
    };

    render() {
        return (
            <SingleLayout navigation={this.props.navigation} dark>
                {this.renderForm()}
            </SingleLayout>
        )
    };

    renderForm = () => {
        return (
            <ScrollView>
                <Text style={[textStyles.secondarySubTitle, styles.infoContainer]}>
                    Enter the modifier information.
                </Text>

                <DividerView/>

                <NameField
                    forwardRef={this.titleRef}
                    error={Boolean(this.state.titleError)}
                    label="Title"
                    placeholder="Title"
                    value={this.state.title}
                    onChangeText={e => this.setState({
                        title: e,
                        titleError: ValidationsUtils.title(e)
                    })}
                    onSubmitEditing={() => this.subTitleRef.current!.focus()}/>

                <TextField
                    forwardRef={this.subTitleRef}
                    error={Boolean(this.state.subTitleError)}
                    label="Sub title"
                    placeholder="Sub title"
                    value={this.state.subTitle}
                    numberOfLines={2}
                    onChangeText={e => this.setState({
                        subTitle: e,
                        subTitleError: ValidationsUtils.subTitle(e)
                    })}
                    onSubmitEditing={() => this.additionalPriceRef.current!.focus()}/>

                <MoneyField
                    forwardRef={this.additionalPriceRef}
                    placeholder="Price"
                    value={this.state.additionalPrice}
                    label={"Price"}

                    error={Boolean(this.state.additionalPriceError)}
                    onChangeText={e => this.setState({
                        additionalPrice: e,
                        additionalPriceError: ValidationsUtils.money(e)
                    })}
                    onSubmitEditing={() => this.maximumChoiceRef.current!.togglePicker()}/>

                <SelectorField
                    forwardRef={this.maximumChoiceRef}
                    value={this.state.maximumChoice}
                    label="Max Choice"
                    doneText={"Done"}
                    items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => {
                        return {value: e, label: e.toString()}
                    })}
                    error={false}
                    onChange={e => this.setState({maximumChoice: e})}
                    onUp={() => this.additionalPriceRef.current!.focus()}
                    onDown={() => Keyboard.dismiss()}/>

                <FieldErrors
                    errors={[this.state.titleError, this.state.subTitleError, this.state.additionalPriceError]}/>
            </ScrollView>
        )
    };
}

const styles = StyleSheet.create({
    infoContainer: {
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: "center",
    },
});