import React from 'react';
import * as SplashScreen from 'expo-splash-screen';
import {Platform, View} from "react-native";
import {Provider} from 'react-redux';
import {getStore} from "./src/reducers/reducers";
import Navigator from "./src/Navigator";
import * as Font from 'expo-font';
import {Service} from "./src/Service";
import ToastView, {toastRef} from "./src/common/views/ToastView";
import ErrorScreen from "./src/screens/ErrorScreen";
import LoadingScreen from "./src/screens/LoadingScreen";
import {StatusBar} from "expo-status-bar";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import {GrpcClient} from "./src/GrpcClient";
import {Empty} from "google-protobuf/google/protobuf/empty_pb";
import {Emreat} from "@emreat/proto/backend/v1/service_pb_service";
import {GetAppVersionResponse} from "@emreat/proto/backend/v1/service_pb";
import semver from "semver/preload";
import UpdateScreen from "./src/screens/UpdateScreen";


SplashScreen.preventAutoHideAsync().catch();

interface State {
    isLoaded: boolean
    error: boolean
    tryingAgain: boolean
    forceUpgrade: boolean
}

export default class App extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoaded: false,
            error: false,
            tryingAgain: false,
            forceUpgrade: false,
        };
    }

    componentDidMount(): void {
        if (Platform.OS == "web") {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.has('download')) {
                if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                    Linking.openURL(`https://play.google.com/store/apps/details?id=${Constants.manifest.extra.androidId}`);
                }
                if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                    Linking.openURL(`https://apps.apple.com/gb/app/${Constants.manifest.extra.appleId}`);
                }
            }
        }

        this.initApp().then(() => SplashScreen.hideAsync().catch()).catch();
    }

    initApp = async () => {
        try {
            let resp: GetAppVersionResponse = await GrpcClient.invoke(Emreat.GetAppVersion, new Empty());
            console.log(`App Version: ${Constants.manifest.version}, Minimum Supported Version ${resp.getMinVersion()!.getMajor()}.${resp.getMinVersion()!.getMinor()}.0`)
            // if (semver.lt(Constants.manifest.version!, `${resp.getMinVersion()!.getMajor()}.${resp.getMinVersion()!.getMinor()}.0`)) {
            //     this.setState({isLoaded: true, error: false, forceUpgrade: true});
            //     return
            // }

            await Service.init();
            this.setState({isLoaded: true, error: false});
        } catch (e) {
            console.log(e);
            this.setState({isLoaded: true, error: true});
            toastRef.current?.show(e.toString());
            toastRef.current?.show("Oops something went wrong!");
        }
    };

    tryAgain = async () => {
        this.setState({tryingAgain: true})
        await this.initApp()
        this.setState({tryingAgain: false})
    }

    cacheFonts = (fonts: Array<any>): Array<Promise<void>> => {
        return fonts.map(font => Font.loadAsync(font));
    };

    render() {
        return (
            <View style={{flex: 1}}>
                <StatusBar style="dark" hidden={Platform.OS == "android"} translucent={Platform.OS == "android"}/>
                <Provider store={getStore}>
                    {
                        this.state.isLoaded && !this.state.error && !this.state.forceUpgrade
                            ? <Navigator/>
                            : this.state.error
                            ? <ErrorScreen onClickTryAgain={this.tryAgain} submitting={this.state.tryingAgain}/>
                            : this.state.forceUpgrade
                                ? <UpdateScreen/>
                                : <LoadingScreen/>
                    }
                </Provider>
                <ToastView forwardRef={toastRef}/>
            </View>
        )
    };
}