import * as React from "react";
import {PropsWithChildren} from "react";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Platform, SafeAreaView, Text, TouchableOpacity, View} from "react-native";
import {RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import {connect} from "react-redux";
import ScreenLayout from "./ScreenLayout";
import {Ionicons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
});

interface Props {
    navigation: StackNavigationProp<any>
    screenSize: ScreenSize
    children?: any
    title?: string
    subTitle?: string
    dark?: boolean
}

export default connect(mapStateToProps)((props: PropsWithChildren<Props>) => {
    if (Platform.OS != 'web') {
        return (
            <View style={{
                flex: 1,
                backgroundColor: props.dark ? colorStyles.secondaryBackground : colorStyles.primaryBackground,
            }}>
                <SafeAreaView style={{flex: 1}}>
                    {props.children}
                </SafeAreaView>
            </View>
        )
    }

    return (
        <ScreenLayout navigation={props.navigation}>
            <View style={containerStyles.page}>
                <View style={{flex: 1, alignItems: 'center'}}>
                    <View style={[
                        {flex: 1},
                        props.screenSize == 'SMALL' ? {width: '100%'} : {minWidth: 600},
                    ]}>
                        <View style={{flexDirection: "row", marginBottom: 15, alignItems: "center"}}>
                            {
                                props.navigation.canGoBack()
                                    ? (
                                        <TouchableOpacity
                                            onPress={() => props.navigation.goBack()}
                                            style={{position: "absolute", paddingLeft: 15, paddingRight: 15, zIndex: 10}}>
                                            <View style={{flexDirection: "row", alignItems: "center"}}>
                                                <Ionicons name="ios-arrow-back" size={28} color={colorStyles.BLUE}/>
                                                <Text style={[
                                                    textStyles.primarySubTitle,
                                                    {color: colorStyles.BLUE, marginLeft: 5, marginBottom: 2},
                                                ]}>
                                                    Back
                                                </Text>
                                            </View>
                                        </TouchableOpacity>
                                    ) : null
                            }
                            <View style={{alignItems: 'center', flex: 1}}>
                                <Text style={textStyles.primaryHeadline}>{props.title}</Text>
                                {
                                    props.subTitle
                                        ? (
                                            <Text style={[textStyles.primarySubTitle, {marginTop: 5}]}>
                                                {props.subTitle}
                                            </Text>
                                        ) : null
                                }
                            </View>
                        </View>
                        <View style={[
                            containerStyles.border,
                            {backgroundColor: colorStyles.primaryBackground}, containerStyles.paddingContainer,
                        ]}>
                            {props.children}
                        </View>
                    </View>
                </View>

            </View>
        </ScreenLayout>
    )
});