import {grpc} from "@improbable-eng/grpc-web";
import {ReactNativeTransport} from "@improbable-eng/grpc-web-react-native-transport/lib";
import {ProtobufMessage} from "@improbable-eng/grpc-web/dist/typings/message";
import {MethodDefinition} from "@improbable-eng/grpc-web/dist/typings/service";
import {getStore} from "./reducers/reducers";
import Constants from "expo-constants";

const authTokenHeader = "X-Auth-Token";
const versionHeader = "X-Emreat-Mobile-Verion";
export const paginationRefreshLimit = 20;

export const GrpcClient = {
    getUrl: (): string => {
        return Constants.manifest.extra.url
    },

    getStripePublishableKey: (): string => {
        return Constants.manifest.extra.stripePublishableKey
    },

    newUuidV4: (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    invoke: <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage, M extends MethodDefinition<TRequest, TResponse>>(method: M, req: TRequest): Promise<TResponse> => {
        return GrpcClient._invoke(method, req, new Map())
    },

    invokeWithAuth: <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage, M extends MethodDefinition<TRequest, TResponse>>(method: M, req: TRequest): Promise<TResponse> => {
        let metadata = new Map([[authTokenHeader, getStore.getState().auth.token?.getAccessSecret() || ""]]);
        return GrpcClient._invoke(method, req, metadata)
    },

    invokeWithToken: <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage, M extends MethodDefinition<TRequest, TResponse>>(method: M, req: TRequest, token: string): Promise<TResponse> => {
        let metadata = new Map([[authTokenHeader, token]]);
        return GrpcClient._invoke(method, req, metadata)
    },

    _invoke: <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage, M extends MethodDefinition<TRequest, TResponse>>(method: M, req: TRequest, metadata: Map<string, string>): Promise<TResponse> => {
        metadata.set(versionHeader, Constants.manifest.version!);
        return new Promise((resolve, reject) => {
            grpc.invoke(method, {
                request: req,
                host: GrpcClient.getUrl(),
                transport: ReactNativeTransport({}),
                metadata: metadata,
                onMessage: (resp: TResponse) => {
                    resolve(resp)
                },
                onEnd: (code: grpc.Code, msg: string | undefined) => {
                    if (code != grpc.Code.OK) {
                        console.log(`Request ${method.service.serviceName}.${method.methodName} failed: ${msg}`);
                        reject(msg);
                    }
                }
            })
        })
    },
};