import {Component, default as React, RefObject} from "react";
import {getStore, RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import {connect} from "react-redux";
import {Location} from "@emreat/proto/backend/v1/locations_pb";
import {ActivityIndicator, FlatList, RefreshControl, ScrollView} from "react-native";
import LocationRow from "./LocationRow";
import MessageView from "../../common/views/MessageView";
import {Service} from "../../Service";
import {paginationRefreshLimit} from "../../GrpcClient";
import {default as FastToast} from "react-native-fast-toast";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    locations: state.customer.locations,
    selectedLocation: state.customer.selectedLocation,
});

interface Props {
    toastRef: RefObject<FastToast>

    screenSize: ScreenSize
    locations: Array<Location>
    selectedLocation: Location | null

    onPressLocation: (id: string) => void
    onPressEditLocation: (id: string) => void
}

interface State {
    refreshing: boolean
    loadingMore: boolean
    endReached: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            loadingMore: false,
            endReached: false,
        };
    }

    componentDidMount(): void {
        this.loadMore().catch()
    }

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await Service.listLocations(0);
            }
        } catch (e) {
            this.props.toastRef.current!.show(e.toString());
            this.props.toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    loadMore = async () => {
        try {
            if (!this.state.loadingMore && !this.state.endReached) {
                this.setState({loadingMore: true});
                let locations = await Service.listLocations(this.props.locations.length);
                this.setState({endReached: locations.length < paginationRefreshLimit});
            }
        } catch (e) {
            this.props.toastRef.current!.show(e.toString());
            this.props.toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({loadingMore: false});
    };

    onPressLocation = async (location: Location) => {
        if (this.props.selectedLocation?.getId() != location.getId()) {
            await getStore.dispatch({type: "SET_SELECTED_LOCATION", location: location});
            this.props.toastRef.current!.show("Default delivery address has been updated");
        }
        this.props.onPressLocation(location.getId())
    };

    render() {
        if (this.state.endReached && !this.props.locations.length) {
            return this.renderNoLocations()
        }
        return (
            <FlatList
                data={this.props.locations}
                keyExtractor={item => item.getId()}
                renderItem={item => (
                    <LocationRow
                        toastRef={this.props.toastRef}
                        location={item.item}
                        onPress={() => this.onPressLocation(item.item)}
                        onPressEdit={() => this.props.onPressEditLocation(item.item.getId())}
                        last={item.index == this.props.locations.length - 1}/>
                )}
                onEndReached={this.loadMore}
                onEndReachedThreshold={10}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={!this.state.endReached ? <ActivityIndicator style={{margin: 30}}/> : null}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };

    renderNoLocations = () => {
        return (
            <ScrollView
                contentContainerStyle={{flex: 1}}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                <MessageView title="Address book is empty" subTitle="Speed up your future checkouts" icon="md-map"/>
            </ScrollView>
        )
    }
})