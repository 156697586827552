import React from "react";
import {Platform, SafeAreaView, View} from "react-native";
import {FAB} from 'react-native-paper';
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {colorStyles} from "../../Styles";
import {Cart} from "@emreat/proto/backend/v1/carts_pb";
import {ScreenSize} from "../../reducers/common";
import {useNavigation} from "@react-navigation/native";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
    cart: state.customer.cart,
});

interface Props {
    screenSize: ScreenSize
    cart: Cart | null
}

export default connect(mapStateToProps)((props: Props) => {
    if (!props.cart || !props.cart!.getItemsList().length) {
        return null
    }
    if (Platform.OS == 'web' && props.screenSize != 'SMALL') {
        return null
    }

    const navigation = useNavigation();
    return (
        <SafeAreaView>
            {/*@ts-ignore*/}
            <View style={{
                position: Platform.OS == 'web' ? 'fixed' : 'absolute',
                bottom: 50,
                right: '5%',
            }}>
                <FAB
                    style={{backgroundColor: colorStyles.RED}}
                    icon="cart"
                    color={colorStyles.WHITE}
                    onPress={() => navigation.navigate("Cart", {})}/>
            </View>
        </SafeAreaView>
    )
});