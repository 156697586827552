import * as React from "react";
import {Component} from "react";
import {ActivityIndicator, FlatList, RefreshControl, ScrollView} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {paginationRefreshLimit} from "../../GrpcClient";
import {Order} from "@emreat/proto/backend/v1/orders_pb";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {Service} from "../../Service";
import {toastRef} from "../../common/views/ToastView";
import OrderRow from "../../components/orders/OrderRow";
import MessageView from "../../common/views/MessageView";
import {RootState} from "../../reducers/reducers";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {User} from "@emreat/proto/backend/v1/users_pb";
import {connect} from "react-redux";
import SingleLayout from "../../common/layouts/SingleLayout";

let mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    orders: state.customer.orders,
});

interface RouteProps {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'Orders'>, StackNavigationProp<RootNavigatorParamList>>
    route: RouteProp<HomeNavigatorParamList, 'Orders'>

    user: User | null
    orders: Array<Order>
}

export default connect(mapStateToProps)((props: RouteProps) => {
    return <Screen navigation={props.navigation} orders={props.orders} user={props.user}/>
})

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList>, StackNavigationProp<RootNavigatorParamList>>
    user: User | null
    orders: Array<Order>
}

interface State {
    refreshing: boolean
    loadingMore: boolean
    endReached: boolean
}

class Screen extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            loadingMore: false,
            endReached: false,
        };
    }

    componentDidMount(): void {
        if (this.props.user) {
            this.loadMore().catch()
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!prevProps.user && this.props.user) {
            this.loadMore().catch()
        }
    }

    refresh = async () => {
        try {
            if (!this.state.refreshing) {
                this.setState({refreshing: true});
                await Service.listOrders(0, [], [], null, null);
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({refreshing: false});
    };

    loadMore = async () => {
        try {
            if (!this.state.loadingMore && !this.state.endReached) {
                this.setState({loadingMore: true});
                let orders = await Service.listOrders(this.props.orders.length, [], [], null, null);
                this.setState({endReached: orders.length < paginationRefreshLimit});
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
        }
        this.setState({loadingMore: false});
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Previous Orders"
                subTitle="Manage your previous orders."
                dark={!this.props.user || (this.state.endReached && !this.props.orders.length)}>
                {
                    !this.props.user
                        ? this.renderLogin()
                        : this.state.endReached && !this.props.orders.length
                        ? this.renderNoOrders()
                        : this.renderOrders()
                }
            </SingleLayout>
        )
    };

    renderLogin = () => {
        return (
            <MessageView
                title={'Previous orders?'}
                subTitle={'Login to see your previous orders.'}
                icon={'md-list-box'}
                button={"Login"}
                onPress={() => this.props.navigation.navigate('Auth', {})}/>
        )
    };

    renderOrders = () => {
        return (
            <FlatList
                data={this.props.orders}
                keyExtractor={item => item.getId()}
                renderItem={item => (
                    <OrderRow
                        navigation={this.props.navigation}
                        order={item.item}
                        onPress={() => this.props.navigation.navigate('Order', {id: item.item.getId()})}
                        last={this.props.orders.length - 1 == item.index}/>
                )}
                onEndReached={this.loadMore}
                onEndReachedThreshold={10}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={!this.state.endReached ? <ActivityIndicator style={{margin: 30}}/> : null}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}/>
        )
    };

    renderNoOrders = () => {
        return (
            <ScrollView
                contentContainerStyle={{flex: 1}}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this.refresh}/>}>
                <MessageView
                    title="No previous orders"
                    subTitle="Place a new order"
                    icon="md-list-box"
                    button="Go to menu"
                    onPress={() => this.props.navigation.navigate("Menu", {})}/>
            </ScrollView>
        )
    };
};