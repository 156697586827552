import {Component, default as React} from "react";
import {StyleSheet, Text, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {ModifierGroup} from "@emreat/proto/backend/v1/modifier_groups_pb";
import MoneyView from "../../common/views/MoneyView";

interface Props {
    modifierGroup: ModifierGroup
    isValid: boolean
    error: boolean
    totalQuantity: number
    totalPrice: number
}

export default class extends Component<Props> {

    render() {
        return (
            <View style={[
                containerStyles.paddingRowMedium,
                {
                    flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 25,
                    backgroundColor: colorStyles.secondaryBackground,
                },
                this.props.error ? styles.errorContainer : {},
            ]}>
                {this.renderTitle()}
                {this.renderTotalPrice()}
                {this.renderInstruction()}
            </View>
        )
    };

    renderTitle = () => {
        return (
            <View style={{flex: 1, flexDirection: "row", alignItems: 'center'}}>
                {
                    this.props.error
                        ? <Ionicons name="md-alert" size={20} style={{color: colorStyles.RED, marginRight: 7}}/>
                        : null
                }
                <Text style={[textStyles.primaryTitle, this.props.error ? {color: colorStyles.RED} : {}]}>
                    {this.props.modifierGroup.getSubTitle()}
                </Text>
            </View>
        )
    };

    renderTotalPrice = () => {
        if (!this.props.totalPrice) {
            return null
        }
        return (
            <Text style={[textStyles.primarySubTitle, {color: colorStyles.GREEN}]}>
                +<MoneyView value={this.props.totalPrice}/>{" "}
            </Text>
        )
    };

    renderInstruction = () => {
        if (this.props.isValid && this.props.modifierGroup.getMinimumChoice() != 0) {
            return <Ionicons name="md-checkmark" size={20} style={{color: colorStyles.GREEN}}/>
        }

        let minChoice = this.props.modifierGroup.getMinimumChoice();
        let maxChoice = this.props.modifierGroup.getMaximumChoice();

        let instruction: string;
        if (minChoice == 0) {
            instruction = "Optional";
        } else if (minChoice == 1 && maxChoice == 1) {
            instruction = "Required"
        } else if (minChoice > 1 && maxChoice == minChoice) {
            if (this.props.totalQuantity == 0) {
                instruction = `${minChoice} required`
            } else {
                instruction = `${minChoice - this.props.totalQuantity} more required`
            }
        } else {
            instruction = `Select at least ${minChoice}`
        }
        return (
            <Text style={[
                textStyles.primarySubTitle,
                this.props.error ? {color: colorStyles.RED} : {},
            ]}>
                {instruction}
            </Text>
        )
    }
}

const styles = StyleSheet.create({
    errorContainer: {
        backgroundColor: colorStyles.LIGHT_GREY,
        borderBottomColor: colorStyles.RED,
        borderBottomWidth: 2
    },
});