import {Component, default as React} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import {Modifier} from "@emreat/proto/backend/v1/modifiers_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {getStore} from "../../reducers/reducers";
import {DeleteModifierRequest} from "@emreat/proto/backend/v1/modifiers_pb";
import MoneyView from "../../common/views/MoneyView";
import {ModifierService} from "@emreat/proto/backend/v1/modifiers_pb_service";

interface Props {
    modifier: Modifier
    onPressEdit: () => void
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteModifier(this.props.modifier.getId());
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteModifier = async (id: string) => {
        let req = new DeleteModifierRequest();
        req.setId(id);
        await GrpcClient.invokeWithAuth(ModifierService.DeleteModifier, req);
        let modifierGroup = getStore.getState().merchant.modifierGroups
            .filter(e => e.getId() == this.props.modifier.getModifierGroupId())[0];
        modifierGroup.setModifiersList(modifierGroup.getModifiersList().filter(e => e.getId() != this.props.modifier.getId()));
        getStore.dispatch({type: "SET_MODIFIER_GROUPS", modifierGroups: [modifierGroup]});
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}
                onPressUpdate={this.props.onPressEdit}>
                <View style={{backgroundColor: colorStyles.primaryBackground}}>
                    {this.renderButton()}
                </View>
            </SwipableButton>
        )
    }

    renderButton = () => {
        return (
            <TouchableOpacity
                onPress={() => this.props.onPressEdit()}
                style={!this.props.last ? containerStyles.borderBottom : null}>
                <View style={[
                    containerStyles.paddingRowMedium,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </View>
            </TouchableOpacity>
        )
    };

    renderLeftColumn = () => {
        return (
            <View style={{flex: 1}}>
                <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                    {this.props.modifier.getTitle()}
                </Text>
                {
                    this.props.modifier.getSubTitle()
                        ? <Text style={[textStyles.secondarySubTitle, {marginTop: 10}]}>
                            {this.props.modifier.getSubTitle()}
                        </Text>
                        : null
                }
            </View>
        )
    };

    renderRightColumn = () => {
        if (!this.props.modifier.getAdditionalPrice()) {
            return null
        }
        return (
            <Text style={[textStyles.secondarySubTitle, {color: colorStyles.RED}]}>
                <MoneyView value={this.props.modifier.getAdditionalPrice()}/>
            </Text>
        )
    };
};