import {Review} from "@emreat/proto/backend/v1/reviews_pb";
import {useNavigation} from "@react-navigation/native";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import RatingView from "../../common/views/RatingView";
import * as React from "react";

interface Props {
    review: Review
}

export default (props: Props) => {
    const navigation = useNavigation();

    return (
        <View style={[containerStyles.paddingRowMedium, containerStyles.borderBottom]}>
            <TouchableOpacity onPress={() => navigation.navigate("Review", {id: props.review.getId(), orderId: props.review.getOrderId(),})}>
                <View style={[styles.container, containerStyles.border]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons name="md-quote" size={28}
                                  style={{color: colorStyles.DARK_TEXT, marginRight: 10}}/>
                        <Text style={textStyles.secondaryHeadline}>Your review</Text>
                    </View>
                    <View>
                        <Text style={[textStyles.primaryTitle, {textAlign: 'center', marginTop: 10}]}>
                            Food quality
                        </Text>
                        <RatingView score={props.review.getQualityRating()}/>
                    </View>
                    <View>
                        <Text style={[textStyles.primaryTitle, {textAlign: 'center', marginTop: 10}]}>
                            Restaurant service
                        </Text>
                        <RatingView score={props.review.getServiceRating()}/>
                    </View>
                    <View>
                        <Text style={[textStyles.primaryTitle, {textAlign: 'center', marginTop: 10}]}>
                            Delivery time
                        </Text>
                        <RatingView score={props.review.getDeliveryTimeRating()}/>
                    </View>
                    {
                        props.review.getCustomerComment()
                            ? (
                                <View>
                                    <Text style={[textStyles.primaryTitle, {textAlign: 'center', marginTop: 10}]}>
                                        You said
                                    </Text>
                                    {/*@ts-ignore*/}
                                    <Text style={[textStyles.secondarySubTitle, {marginTop: 10, wordBreak: "break-word"}]}>
                                        "{props.review.getCustomerComment()}"
                                    </Text>
                                </View>
                            ) : null
                    }
                </View>
            </TouchableOpacity>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        marginTop: 0,
        padding: 15,
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorStyles.LIGHT_BLUE,
    },
});