import {Component, default as React} from "react";
import {Text, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {ProductGroup} from "@emreat/proto/backend/v1/product_groups_pb";
import SwipableButton from "../../common/buttons/SwipableButton";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {ProductLinkService} from "@emreat/proto/backend/v1/product_links_pb_service";
import {Service} from "../../Service";
import {
    DeleteProductLinkRequest,
    ListProductLinksRequest,
    ListProductLinksResponse
} from "@emreat/proto/backend/v1/product_links_pb";

interface Props {
    productId: string
    productGroup: ProductGroup
    last?: boolean
}

interface State {
    deleting: boolean
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false
        };
    }

    onPressDelete = async () => {
        this.setState({deleting: true});
        try {
            await this.deleteProductLink(this.props.productId, this.props.productGroup.getId());
            await Service.initMerchant();
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.setState({deleting: false});
        }
    };

    deleteProductLink = async (productId: string, productGroupId: string) => {
        let listReq = new ListProductLinksRequest();
        listReq.addProductGroupIds(productGroupId);
        listReq.addProductIds(productId);
        listReq.setLimit(1);
        let resp: ListProductLinksResponse = await GrpcClient.invokeWithAuth(ProductLinkService.ListProductLinks, listReq);

        let req = new DeleteProductLinkRequest();
        req.setId(resp.getProductLinksList()[0].getId());
        await GrpcClient.invokeWithAuth(ProductLinkService.DeleteProductLink, req);
    };

    render() {
        return (
            <SwipableButton
                deleting={this.state.deleting}
                onPressDelete={this.onPressDelete}>
                <View style={[
                    containerStyles.paddingRowMedium, !this.props.last ? containerStyles.borderBottom : null,
                    {flexDirection: 'row', alignItems: 'center', backgroundColor: colorStyles.primaryBackground},
                ]}>
                    <Text style={[textStyles.secondaryTitle, {textTransform: 'capitalize'}]}>
                        {this.props.productGroup.getTitle()}
                    </Text>
                </View>
            </SwipableButton>
        )
    }
};