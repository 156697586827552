import {getScreenSize, ScreenSize} from "./common";

export interface SettingsState {
    screenSize: ScreenSize
}

export type SettingsAction = {
    readonly type: "SET_SCREEN_SIZE",
    readonly screenSize: ScreenSize,
}

const initialState: SettingsState = {
    screenSize: getScreenSize(),
};

export function settings(state: SettingsState = initialState, action: SettingsAction): SettingsState {
    switch (action.type) {
        case "SET_SCREEN_SIZE":
            return {...state, screenSize: action.screenSize};
        default:
            return state;
    }
}