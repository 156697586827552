import React, {RefObject} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {Ionicons} from "@expo/vector-icons";
import CenterModal from "../modals/CenterModal";
import * as Linking from "expo-linking";
import {getStore} from "../../reducers/reducers";
import SubmitButton from "../buttons/SubmitButton";

export default () => {
    const ref: RefObject<CenterModal> = React.createRef();
    let phoneNumber = getStore.getState().merchant.stores[0].getPhoneNumber();
    return (
        <View>
            <TouchableOpacity onPress={() => ref.current!.open()}>
                <View style={[styles.container, containerStyles.borderBottom, containerStyles.paddingRowMedium]}>
                    <Ionicons name="md-warning" size={22} style={{color: colorStyles.RED, marginRight: 15}}/>
                    <View style={{flex: 1, justifyContent: 'center'}}>
                        <Text style={[textStyles.secondarySubTitle, {color: colorStyles.RED}]}>
                            If you or someone you're ordering for has a food allergy or
                            intolerance, tap here.
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>

            <CenterModal ref={ref}>
                <View>
                    <View style={[containerStyles.paddingRowMedium, {paddingLeft: 25, paddingRight: 25}]}>
                        <View style={{alignItems: 'center'}}>
                            <Text style={[textStyles.primaryTitle, {textAlign: 'center'}]}>Do you have a food allergy?</Text>
                            <Text style={[textStyles.secondarySubTitle, {marginTop: 15, textAlign: 'center'}]}>
                                If you have a food allergy or intolerance (or someone you're ordering for has),
                                phone the restaurant on

                                <TouchableOpacity onPress={() => Linking.openURL(`tel:${phoneNumber}`)} style={{height: 14}}>
                                    <Text style={[textStyles.secondarySubTitle, {color: colorStyles.BLUE}]}> {phoneNumber}</Text>
                                </TouchableOpacity>
                                .
                            </Text>
                            <Text
                                style={[textStyles.secondarySubTitle, {marginTop: 15, marginBottom: 15, textAlign: 'center'}]}>
                                Do not order if you cannot get the allergy information you need.
                            </Text>
                        </View>
                        <View style={{width: '100%'}}>
                            <SubmitButton
                                text="Close"
                                onPress={() => ref.current!.close()}
                                submitting={false}
                                disabled={false}
                                inActive={false}/>
                        </View>
                    </View>
                </View>
            </CenterModal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: colorStyles.primaryBackground,
    }
});
