import * as React from "react";
import {Component, RefObject} from "react";
import {Platform, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colorStyles, containerStyles, textStyles} from "../../Styles";
import {StackNavigationProp} from "@react-navigation/stack";
import {HomeNavigatorParamList, RootNavigatorParamList} from "../../Navigator";
import {CompositeNavigationProp} from "@react-navigation/native";
import {getStore, RootState} from "../../reducers/reducers";
import {connect} from "react-redux";
import {Token, TokenType} from "@emreat/proto/backend/v1/tokens_pb";
import {User} from "@emreat/proto/backend/v1/users_pb";
import CenterModal from "../../common/modals/CenterModal";
import {toastRef} from "../../common/views/ToastView";
import {GrpcClient} from "../../GrpcClient";
import {AuthenticationService} from "@emreat/proto/backend/v1/authentication_pb_service";
import {Empty} from "google-protobuf/google/protobuf/empty_pb";
import {Service} from "../../Service";
import {FontAwesome, Ionicons} from "@expo/vector-icons";
import SubmitButton from "../../common/buttons/SubmitButton";
import ScrollView from "../../common/views/ScrollView";
import Constants from "expo-constants";
import CancelButton from "../../common/buttons/CancelButton";
import SingleLayout from "../../common/layouts/SingleLayout";
import DividerView from "../../common/views/DividerView";
import MessageView from "../../common/views/MessageView";
import {CartDispatchMethod} from "@emreat/proto/backend/v1/carts_pb";
import {StoreDispatchMethod} from "@emreat/proto/backend/v1/stores_pb";

let mapStateToProps = (state: RootState) => ({
    token: state.auth.token,
    user: state.auth.user,
});

interface Props {
    navigation: CompositeNavigationProp<StackNavigationProp<HomeNavigatorParamList, 'User'>, StackNavigationProp<RootNavigatorParamList>>

    token: Token | null
    user: User | null
}

interface State {
    loggingOut: boolean
}

export default connect(mapStateToProps)(class extends Component<Props, State> {

    logoutModalRef: RefObject<CenterModal>;

    constructor(props: Props) {
        super(props);
        this.state = {
            loggingOut: false,
        };
        this.logoutModalRef = React.createRef();
    }

    componentDidMount(): void {
        this.navigationOptions();
    }

    navigationOptions = (): void => {
        this.props.navigation.setOptions({
            title: 'Account',
        })
    };

    onLogout = async () => {
        this.setState({loggingOut: true});
        try {
            await this.logout();
            this.logoutModalRef.current!.close();
            this.setState({loggingOut: false});
            if (Platform.OS == 'web') {
                this.logoutModalRef.current!.close();
            }
        } catch (e) {
            toastRef.current!.show(e.toString());
            toastRef.current!.show("Oops something went wrong!");
            this.logoutModalRef.current!.close();
            this.setState({loggingOut: false});
        }
    };

    logout = async () => {
        try {
            await GrpcClient.invokeWithAuth(AuthenticationService.Logout, new Empty());
        } catch (e) {
            if (!["ERROR_TOKEN_DOES_NOT_EXIST", "ERROR_UNAUTHENTICATED"].includes(e.toString())) {
                throw e
            }
        }
        await Service.initToken(null);
        await Service.initCart(null);
        getStore.dispatch({type: "CLEAR_CUSTOMER"});
    };

    render() {
        return (
            <SingleLayout
                navigation={this.props.navigation}
                title="Account"
                subTitle="Manage your preferences."
                dark>
                {this.renderContent()}
                {this.renderConfirmModal()}
            </SingleLayout>
        )
    };

    renderContent = () => {
        if (this.props.user) {
            return this.renderAccount()
        } else {
            return this.renderLogin()
        }
    };

    renderLogin = () => {
        return (
            <MessageView
                title={'Have an account?'}
                subTitle={'Login to manage your account.'}
                icon={'md-contact'}
                button={"Login"}
                onPress={() => this.props.navigation.navigate('Auth', {})}/>
        )
    };

    renderAccount = () => {
        return (
            <ScrollView>
                <View style={styles.iconContainer}>
                    <FontAwesome name="user-circle" size={65} color={colorStyles.LIGHT_TEXT}/>
                    <Text style={[textStyles.primaryTitle, {marginTop: 5}]}>
                        {`${this.props.user!.getFirstName()} ${this.props.user!.getLastName()}`}
                    </Text>
                    <Text style={[textStyles.secondarySubTitle, {marginTop: 5}]}>
                        {this.props.user!.getEmailAddress()}
                    </Text>
                </View>

                <DividerView/>

                {
                    Platform.OS != "web" && this.props.token && this.props.token.getType() == TokenType.TOKEN_TYPE_MERCHANT
                        ? this.renderButton('Dashboard', () => this.props.navigation.navigate('Dashboard', {}))
                        : null
                }

                {
                    Platform.OS == "web"
                        ? this.renderButton('Orders', () => this.props.navigation.navigate('Orders', {}))
                        : null
                }

                <View style={{marginTop: 20}}/>
                <DividerView/>

                {this.renderButton('Edit profile', () => this.props.navigation.navigate('User', {}))}

                {this.renderButton('Change password', () => this.props.navigation.navigate('Password', {}))}

                {
                    getStore.getState().merchant.stores[0].getDispatchMethodsList().map(e => e.getDispatchMethod()).includes(CartDispatchMethod.CART_DISPATCH_METHOD_DELIVERY)
                        ? this.renderButton('Address book', () => this.props.navigation.navigate('Locations', {}))
                        : null
                }

                <View style={{marginTop: 20}}/>
                <DividerView/>

                {this.renderButton('Terms of use', () => this.props.navigation.navigate("TermsOfUse", {}))}

                {this.renderButton('Privacy policy', () => this.props.navigation.navigate("PrivacyPolicy", {}))}

                <View style={{marginTop: 20}}/>
                <DividerView/>

                {this.renderButton('Log out', () => this.logoutModalRef.current!.open())}

                <Text style={[textStyles.secondarySubTitle, {margin: 20, textAlign: 'center'}]}>
                    Version v{Constants.manifest.version}
                </Text>
            </ScrollView>
        )
    };

    renderButton = (title: string, onPress: () => void) => {
        return (
            <View style={[containerStyles.paddingRowSmall, containerStyles.borderBottom, styles.buttonContainer]}>
                <TouchableOpacity onPress={onPress} style={{flexDirection: 'row'}}>
                    <View style={{flex: 1, justifyContent: 'center'}}>
                        <Text style={textStyles.secondaryTitle}>{title}</Text>
                    </View>
                    <Ionicons name="ios-arrow-forward" size={25} style={{color: colorStyles.RED, marginLeft: 10}}/>
                </TouchableOpacity>
            </View>
        )
    };

    renderConfirmModal = () => {
        return (
            <CenterModal ref={this.logoutModalRef}>
                <View>
                    <View style={[containerStyles.paddingRowMedium, {paddingLeft: 25, paddingRight: 25}]}>
                        <View style={{alignItems: 'center'}}>
                            <Text style={textStyles.primaryTitle}>Log out</Text>
                            <Text style={[textStyles.secondarySubTitle, {
                                marginTop: 10,
                                marginBottom: 20,
                                textAlign: 'center'
                            }]}>
                                Are you sure you want to log out?
                            </Text>
                        </View>
                        <View style={{width: '100%'}}>
                            <SubmitButton
                                text='Yes'
                                onPress={() => this.onLogout()}
                                submitting={this.state.loggingOut}
                                inActive={this.state.loggingOut}/>
                            <View style={{height: 15}}/>
                            <CancelButton text='No' onPress={() => this.logoutModalRef.current!.close()}/>
                        </View>
                    </View>
                </View>
            </CenterModal>
        )
    };
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorStyles.secondaryBackground,
    },
    loginContainer: {
        margin: 20,
        justifyContent: 'center',
        flex: 1,
    },
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 25,
        marginTop: 40,
    },
    buttonContainer: {
        backgroundColor: colorStyles.primaryBackground,
        justifyContent: 'center',
        textAlign: 'left',
    }
});
