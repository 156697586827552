import {TextInput, TextInputProps} from "react-native";
import Field from "./Field";
import React, {RefObject} from "react";

interface Props extends TextInputProps {
    forwardRef: RefObject<TextInput>
    error?: boolean
}

export default React.memo(function RequestResetField(props: Props) {
    return (
        <Field
            maxLength={6}
            clearTextOnFocus={true}
            placeholder="123456"
            keyboardType="phone-pad"
            autoCapitalize="none"
            autoCorrect={false}
            autoCompleteType="off"
            multiline={false}
            {...props}

            label="Password"
            error={props.error}
            showSecureTextEntry={true}/>
    )
});