import {Component, default as React, RefObject} from "react";
import {View} from "react-native";
import SideMenuView, {MenuItem} from "../../common/views/SideMenuView";
import {connect} from "react-redux";
import {RootState} from "../../reducers/reducers";
import {ScreenSize} from "../../reducers/common";
import Constants from "expo-constants";

let mapStateToProps = (state: RootState) => ({
    screenSize: state.settings.screenSize,
});

interface Props {
    screenSize: ScreenSize
    items: Array<MenuItem>
    itemRef: Map<string, RefObject<any>>
}

export default connect(mapStateToProps)(class extends Component<Props> {

    onPressProductGroup = (id: string) => {
        // @ts-ignore
        this.props.itemRef.get(id)!.current!.scrollIntoView({ behavior: 'smooth' , block: 'start', inline: 'nearest'})
    };

    render() {
        if (this.props.screenSize != 'LARGE' || !Constants.manifest.extra.showCategory) {
            return null
        }
        return (
            <View style={{flex: 2, marginRight: 25}}>
                {/*@ts-ignore*/}
                <View style={{top: 15, maxHeight: 'calc(100vh - 30px)', position: 'sticky'}}>
                    <SideMenuView items={this.props.items} itemRef={Array.from(this.props.itemRef.values())} onPress={this.onPressProductGroup}/>
                </View>
            </View>
        )
    };
})